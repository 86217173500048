import React, { useEffect, useState } from 'react';
import UserBankList from './UserBankList';
import { useForm, Controller } from "react-hook-form";
import UserBankService from '../../../Services/UserBankServices/UserBankServices';
import { Button, notification, Result, Space } from 'antd';
import DashBord from '../../HomeLayout/UserDashboard/index';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { EditUserBankAccount } from '../../../store/UserBankAccountList/UserBankAccountListaction';

import './UserBankList.scss';
const AddBankDetailPage = (props) => {
    const dispatch = useDispatch();

    // const location = useLocation()
    const bankaccdata = useSelector((state) => state.UserBankAccountReducer.bankaccountdata);
    console.log(bankaccdata)
    // const { from } = location.state
    // console.log(props.location)
    const {
        register,
        handleSubmit,
        formState: { errors }, reset
    } = useForm();
    const [showform, setShowform] = useState(false);
    const [ShowList, setShowlist] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [editButtonsubmit, seteditButtonsubmit] = useState(false);
    const [banktableid, setbanktableid] = useState();
    const [addaccountsuccess, setaddaccountsuccess] = useState(false)
    const [userState, setUserState] = useState({
        account_holder_name: bankaccdata ? bankaccdata.account_holder_name : "",
        account_no: bankaccdata ? bankaccdata.account_no : "",
        bank_name: bankaccdata ? bankaccdata.bank_name : "",
        ifsc_code: bankaccdata ? bankaccdata.ifsc_code : "",
        branchname: bankaccdata ? bankaccdata.branchname : "",
    });
    const [editformstatus, seteditformstatus] = useState(bankaccdata ? true : false)
    const openaddformbutton = () => {
        reset()
        setShowlist(false)
        setShowform(true)
    }
    const fetchbanklist = async () => {
        const callapi = await UserBankService.getbankaccountlist()
        console.log(callapi)
        if (callapi.state === false) {
            if (callapi.response.data.success === true) {
                if (callapi.response.data.result.length > 0) {
                    setShowlist(true)
                    setBankList(callapi.response.data.result)
                }
                else {
                    setShowlist(true)
                    // setShowform(true)
                    console.log('empty bank list')
                }
            }
        }
    }
    const onSubmit = async (data) => {
        console.log(data)
        if (editformstatus === false) {
            const apidata = {
                user_id: localStorage.getItem('loginid'),
                account_holder_name: data.account_holder_name,
                account_no: data.account_no,
                ifsc_code: data.ifsc_code,
                bank_name: data.bank_name,
                branchname: data.branchname
            }
            console.log(apidata)
            const callapi = await UserBankService.AddBankAccount(apidata)
            console.log(callapi)
            if (callapi.state === false) {
                if (callapi.response.data.success === true) {
                    setaddaccountsuccess(true)
                    reset()

                    notification['success']({
                        message: "Bank Account Added Successfully",
                        // description:
                        //   'Something Error! Please Try Again',
                    })
                    // fetchbanklist()
                    // setShowform(false)
                    window.scroll(0, 0)

                }
                else {
                    notification['error']({
                        message: "Something Error",

                    })
                    console.log('something wrong')
                }
            }
            else {
                if (callapi.response.data.success === false) {
                    if (callapi.response.data.error === "SequelizeUniqueConstraintError: Validation error") {
                        notification['error']({
                            message: "Enter Valid Account Number",

                        })
                    }
                }
                else {
                    notification['error']({
                        message: "Something Error",

                    })
                }


            }
        }
        else {
            const apibody = {
                account_holder_name: data.account_holder_name,
                account_no: data.account_no,
                ifsc_code: data.ifsc_code,
                bank_name: data.bank_name,
                id: bankaccdata.id,
                branchname: data.branchname
            }
            console.log(apibody)
            const callapi = await UserBankService.editbankdetail(apibody)
            console.log(callapi)
            if (callapi.state === false) {
                if (callapi.response.data.success === true) {
                    if (callapi.response.data.result.changedRows === 1) {
                        setaddaccountsuccess(true)
                        dispatch(EditUserBankAccount())
                        reset()
                        console.log('edit success')
                        notification['success']({
                            message: 'Bank Account Successfully Updated',
                            // description:
                            //   'Something Error! Please Try Again',
                        })
                        // seteditButtonsubmit(false)
                        // setShowform(false)
                        // setShowlist(true)
                        // fetchbanklist()
                    }
                    else {
                        reset()
                    }
                }
                else {
                    reset()
                }
            }
            else {
                if (callapi.response.data.success === false) {
                    if (callapi.response.data.error === "Error: Duplicate entry 'pnbe patna' for key 'bank_account_details.account_no'") {
                        console.log('enter valid account no')
                        notification['error']({
                            message: 'Enter Valid Account No!',
                            // description:
                            //   'Something Error! Please Try Again',
                        })
                    }
                    else {
                        console.log('Something Error')
                        notification['error']({
                            message: 'Enter Valid Account No!',
                            // description:
                            //   'Something Error! Please Try Again',
                        })
                    }
                }
            }

        }
    }
    useEffect(() => {
        window.scroll(0, 0)
        fetchbanklist()
    }, [])
    const backDetail = async () => {
        seteditButtonsubmit(false)
        setShowlist(true)
        setShowform(false)
    }
    const EditBankDetail = async (item) => {
        console.log(item)
        reset()
        setbanktableid(item.id)
        setUserState({
            account_holder_name: item.account_holder_name, account_no: item.account_no,
            bank_name: item.bank_name, ifsc_code: item.ifsc_code
        })
        setTimeout(() => {
            setShowlist(false)
            setShowform(true)
            seteditButtonsubmit(true)
        }, 1000)

    }
    const deletebankdetailbutton = async (item) => {
        console.log(item)
        const apicall = await UserBankService.deleteBank(item.id)
        console.log(apicall)
        if (apicall.state === false) {
            if (apicall.response.data.success === true) {
                fetchbanklist()
                if (apicall.response.data.result.affectedRows === 1) {
                    notification['success']({
                        message: 'Deleted Bank Detail!',
                        // description:
                        //   'Something Error! Please Try Again',
                    })
                    console.log('success')
                    fetchbanklist()
                }
                else {
                    console.log('error')
                    fetchbanklist()
                }
            }
            else {
                console.log('error')
                fetchbanklist()
            }
        }
        else {
            console.log('error')
            fetchbanklist()
        }
    }
    const showbanklistbutton = () => {
        fetchbanklist()
        reset()

        setShowform(false)
        setShowlist(true)
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-2 d-sm-none d-md-block d-lg-block'>
                        <DashBord />
                    </div>
                    <div className='col-md-10  mt-2'>
                        <div className='container'>
                            {editformstatus === true ?
                                <>
                                    {addaccountsuccess === false ?
                                        <>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                                        {/* <h2 className='text-white'>Edit Bank Account</h2> : */}
                                                        <h2 className='text-white'>{editformstatus === true ? "Edit Bank Account" : "Add Bank Account"}</h2>

                                                        <Link to='/banklist' className='btn btn-primary font-weight-bold' style={{ padding: '5px 10px', fontSize: '20px' }}>Bank Account List</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <form className=''
                                                        onSubmit={handleSubmit(onSubmit)}
                                                    >
                                                        <div class="row">
                                                            <div class="col">
                                                                <label for="inputEmail4" className='text-white'>Account Holder Name</label>
                                                                <input type="text" class="form-control" placeholder="First name" defaultValue={userState.account_holder_name}    {...register("account_holder_name", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.account_holder_name && "This Field Is required"}</small>

                                                            </div>
                                                            <div class="col">
                                                                <label className='text-white' for="inputEmail4">Bank Name</label>
                                                                <input type="text" class="form-control" placeholder="Last name" defaultValue={userState.bank_name}    {...register("bank_name", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.bank_name && "This Field Is required"}</small>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <label className='text-white' for="inputEmail4">Account Number</label>
                                                                <input type="text" class="form-control" placeholder="First name" defaultValue={userState.account_no}   {...register("account_no", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.account_no && "This Field Is required"}</small>
                                                            </div>
                                                            <div class="col">
                                                                <label className='text-white' for="inputEmail4">IFSC</label>
                                                                <input type="text" class="form-control text-dark" placeholder="Last name" defaultValue={userState.ifsc_code}   {...register("ifsc_code", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.ifsc_code && "This Field Is required"}</small>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-3">
                                                            <div class="col">
                                                                <label className='text-white' for="inputEmail4">Branch Name</label>
                                                                <input type="text" class="form-control text-dark" placeholder="Branch Name" defaultValue={userState.branchname}   {...register("branchname", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.branchname && "This Field Is required"}</small>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-3">
                                                            <div class="col text-left">
                                                                <button className='btn btn-primary font-weight-bold' style={{ padding: '5px 10px', fontSize: '20px' }}>
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Result
                                                    status="success"
                                                    title=" Bank Account Edit succesfully!"
                                                    // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                                                    extra={[
                                                        <Link to='/banklist' type="primary" class="ant-btn ant-btn-primary" key="console">
                                                            Bank Account List
                                                        </Link>,
                                                        // <Button key="buy">Buy Again</Button>,
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {addaccountsuccess === false ?
                                        <>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                                        {/* <h2 className='text-white'>Edit Bank Account</h2> : */}
                                                        <h2 className='text-white'>Add Bank Account</h2>

                                                        <Link to='/banklist' className='btn btn-primary font-weight-bold' style={{ padding: '5px 10px', fontSize: '20px' }}>Bank Account List</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <form className=''
                                                        onSubmit={handleSubmit(onSubmit)}
                                                    >
                                                        <div class="row">
                                                            <div class="col">
                                                                <label for="inputEmail4" className='text-white'>Account Holder Name</label>
                                                                <input type="text" class="form-control" placeholder="First name" defaultValue={userState.account_holder_name}    {...register("account_holder_name", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.account_holder_name && "This Field Is required"}</small>

                                                            </div>
                                                            <div class="col">
                                                                <label className='text-white' for="inputEmail4">Bank Name</label>
                                                                <input type="text" class="form-control" placeholder="Last name" defaultValue={userState.bank_name}    {...register("bank_name", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.bank_name && "This Field Is required"}</small>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <label className='text-white' for="inputEmail4">Account Number</label>
                                                                <input type="text" class="form-control" placeholder="First name" defaultValue={userState.account_no}   {...register("account_no", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.account_no && "This Field Is required"}</small>
                                                            </div>
                                                            <div class="col">
                                                                <label className='text-white' for="inputEmail4">IFSC</label>
                                                                <input type="text" class="form-control text-dark" placeholder="Last name" defaultValue={userState.ifsc_code}   {...register("ifsc_code", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.ifsc_code && "This Field Is required"}</small>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-3">
                                                            <div class="col-6">
                                                                <label className='text-white' for="inputEmail4">Branch Name</label>
                                                                <input type="text" class="form-control text-dark" placeholder="Enter Branch Name" defaultValue={userState.branchname}   {...register("branchname", {
                                                                    required: "Required",
                                                                })} />
                                                                <small className="form-text text-danger" style={{ marginLeft: '' }}>{errors.branchname && "This Field Is required"}</small>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-3">
                                                            <div class="col text-left">
                                                                <button className='btn btn-primary font-weight-bold' style={{ padding: '5px 10px', fontSize: '20px' }}>
                                                                    ADD
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Result
                                                    status="success"
                                                    title=" Bank Account added succesfully!"
                                                    // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                                                    extra={[
                                                        <Link to='/banklist' type="primary" class="ant-btn ant-btn-primary" key="console">
                                                            Bank Account List
                                                        </Link>,
                                                        // <Button key="buy">Buy Again</Button>,
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div></div></div>


        </>

    )
}

export default AddBankDetailPage;