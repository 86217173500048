import React, { Fragment, useEffect, useState } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import HeaderNavBar from "./components/HomeLayout/Header/HeaderNavBar";
import HeaderNav from "./components/HomeLayout/Header/HeaderNav";
import Protected from './protected';
import HomeScreenProtected from './HomeScreenProtected';
import ManageProfile from "./components/HomeLayout/manageProfile/ManageProfile";
import Footer from "./components/HomeLayout/Footer/Footer";
import TopHeader from "./components/HomeLayout/TopHeader/TopHeader";
import VideoPlayer from "./components/HomeLayout/Tab/VideoPlayer";
import HomeTab from "./components/HomeLayout/Tab/Home/index";
import SeriesPages from "./components/HomeLayout/Tab/Series/index";
import ShortVideoPages from "./components/HomeLayout/Tab/ShortFilms/shortVideoPage";
import StudentCornerPage from "./components/HomeLayout/Tab/StudentCorner/StudentCornerPage";
import UploadVideoPage from "./components/Pages/UploadVideoPage/UploadVideoPage";
import DashBord from "./components/HomeLayout/UserDashboard/index";
import Videoplay from "./components/Pages/UploadVideoPage/videoplay";
import TalentHuntHome from "./components/HomeLayout/Tab/TalentHuntTab/TalentHuntHome";
import UploadVideoForm from "./components/Pages/UploadVideoPage/uploadVideoForm";
import videoplayer from "./components/HomeLayout/Crousel/videoplayer";
import UploadVideoList from "./components/Pages/UploadVideoList/UploadVideoList";
import DashoardContent from "./components/HomeLayout/UserDashboard/DashoardContent";
import AdminVideoPlayer from "./components/resuable/adminVideoPlayer";
import TermConditionPage from "./components/Pages/Term&ConditionPage/Term&conditionPage";
import PrivacyPolicyPage from "./components/Pages/PrivacyPolicy/PrivacyPolicyPage";
import DisclaimerPage from "./components/Pages/Disclaimer/DisclaimerPage";
import EpisodeDetailPage from "./components/Pages/SeriesPage/EpisodeDetailPage/EpisodeDetailPage";
import EpisodeVideoPlayer from "./components/Pages/SeriesPage/EpisodeDetailPage/episodePlayer";
import MoviePages from "./components/HomeLayout/Tab/MoviesPage/index";
import VideoDetailPage from "./components/resuable/VideoDetailPage";
import TalentHuntVideoDetailPage from "./components/HomeLayout/Tab/TalentHuntTab/TalentHuntVideoDetailPage";
import HomeCrouselAdminVideoPlayer from "./components/resuable/HomeCrouselPlayer";
import HomeScreen from "./components/HomeLayout/HomeScreen/HomeScreen.js";
import SubscriptionPlanPage from "./components/HomeLayout/subscriptionPlanPage/subscriptionPlanPage";
import SignIn from "./components/LoginSignupPage/SignupPage/signin.js";
import AudiMusicListPage from "./components/Pages/AudioMusiclistPage";
import VideoMusicListPage from './components/Pages/VideoMusicListPage/index';
import { useLocation } from 'react-router-dom';
import AudioMusicdetailPage from "./components/Pages/AudioMusiclistPage/AudioMusicdetailPage";
import VideoMusicdetailPage from "./components/Pages/VideoMusicListPage/VideoMusicdetailPage";
import SubCategoryVideoListPage from './components/resuable/subCategoryVideoListPage/subCategoryVideoListPage';
import TalentHuntUploadVideoPlayer from './components/Pages/TalentHuntTab/UploadVideoPlayer';
import MovieDetailPage from './components/Pages/MovieDetailPage/MovieDetailPage';
import HomeCrouselMovieList from './components/HomeLayout/Tab/Home/HomeCrousel/HomeCrouselViewAllList/HomeCrouselMovieList';
import HomeShowCrouselViewList from './components/HomeLayout/Tab/Home/HomeShowTabCrousel/HomeShowCrouselViewAllList/HomeShowCrouselViewList';
import WishListPage from './components/Pages/WishListPage/wishListPage';
import AboutUs from './components/Pages/AboutUs/AboutUs';
import ContinueWatchingPage from "./components/Pages/ContinueWatchingPage/ContinueWatchingPage";
import StudentCornerDetailPage from './components/Pages/StudentCorner/StudentCornerDetailPage/StudentCornerDetailPage';
import TalentHuntDetailPage from './components/Pages/TalentHuntTab/TalentHuntDetailPage/TalentHuntDetailPage.js';
import TalentHuntVideoPlayer from './components/resuable/talentHuntVideoPlayer';
import SeriesSubCategoryListPage from './components/Pages/SeriesPage/SeriesSubCategoryListPage/SeriesSubCategoryListPage';
import ShortFilmsSubCategoryListPage from './components/Pages/shortFilmsTab/ShortFilmsSubCategoryListPage/ShortFilmsSubCategoryListPage';
import TalentHuntSubCategoryListPage from './components/Pages/TalentHuntTab/TalentHuntSubCategoryListPage/TalentHuntSubCategoryListPage';
import StudentCornerSubCategoryListPage from './components/Pages/StudentCorner/StudentCornerSubCategoryListPage/StudentCornerSubCategoryListPage';
import StudentCornerVideoPlayer from './components/resuable/studentCornerPlayer';
import Demopage from './components/resuable/demopage';
import Walltetable from './components/resuable/walltetable';
import AddBankDetailPage from './components/Pages/AddBankDetail/AddBankDetailPage';
import UserBankList from './components/Pages/AddBankDetail/UserBankList';
import SendMonetToBank from './components/Pages/SendMonetToBank/index';
import SendmoneyHistory from './components/Pages/SendMonetToBank/SendmoneyHistory';
import AuthServices from '../src/Services/authServices';
import RenewPage from './components/Pages/renewPage/renewPage';
import Mobileappplayer from './components/resuable/Mobileappplayer';
import PlayerProcted from './playerprotected';
import RefundPolicy from './components/Pages/RefundPolicy/RefundPolicy';
import SamplePlayer from './components/resuable/SamplePlayer';
import "./App.css";

function App() {
  const location = useLocation();
  // console.log(location.pathname)
  // const [location,setLocation]=useState(window.location.pathname)
  const headerrender = () => {
    if (window.location.pathname === '/' || window.location.pathname === '/signin' || window.location.pathname === '/signup/planform') return null;
    // return <HeaderNavBar />;
    return <HeaderNav />
  }
  const authcheck = async () => {
    const apicall = await AuthServices.packvalidation()
    console.log(apicall)
    if (apicall.state === false) {
      if (apicall.response.data.success === true) {
        if (apicall.response.data.user_status === 'old User') {
          console.log('old user')
          if (apicall.response.data.result.sub_exp_status === '0' && apicall.response.data.result.subscription_status === '1') {
            localStorage.setItem("user_type", apicall.response.data.result.type_property_id);
            localStorage.setItem("loginid", apicall.response.data.result.id);
            localStorage.setItem("user_token", apicall.response.data.token);
            const userdata = {
              user_id: apicall.response.data.result.id
            }
            console.log('plan is not expired')
            // window.location = "/HomePage";
            // notification['success']({
            //   message: 'Login Success!'
            // });
          }
          else if (apicall.response.data.result.sub_exp_status === '1' && apicall.response.data.result.subscription_status === '0') {
            localStorage.removeItem("user_type");
            localStorage.removeItem("loginid");
            localStorage.removeItem("user_token");
            localStorage.setItem("id", apicall.response.data.result.id);
            window.location = "/renew/planform";
          }
        }
        else if (apicall.response.data.user_status === 'new User') {
          // new user 
          localStorage.setItem("id", apicall.response.data.result.id);
          window.location = "/planform"
          console.log('new user')
        }
        else {
          console.log('something went wrong try again later')
        }
      }
      else {
        console.log('Something went wrong')
      }
    }
  }
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  useEffect(() => {
    authcheck()
    headerrender()
  }, [location])

  return (
    <Switch>
      <Route exact path="/mobile-player/:categoryid/:subcategory_id/:videoid" component={Mobileappplayer} />
      
    <Fragment>
      {/* <Switch>
        <PlayerProcted exact path="/mobile-player" component={Mobileappplayer} />
      </Switch> */}
      <Provider store={store}>
        {/* {window.location.pathname ==='/' || '/signin' || '/signup/planform' ? null :
        <HeaderNavBar />
  } */}
        {/* <Switch> */}
        {localStorage.getItem('loginid') ?
          <>
            {window.location.pathname === '/mobile-player/:categoryid/:subcategory_id/:videoid' ? "" :
              <>
                {headerrender()}
              </>
            }
          </>
          :
          ""
        }



        <HomeScreenProtected exact path="/" component={HomeScreen} />
        <Route exact path="/signin" component={SignIn} />
       

        {/* <Route exact path="/mobile-player/:categoryid/:subcategory_id/:videoid" component={Mobileappplayer} /> */}

        {/* <Protected
          exact
          path="/signup/planform"
          component={SubscriptionPlanPage}
        /> */}
        <Protected exact path="/HomePage" component={HomeTab} />
        <Protected exact path="/audio-play/:title/:id" component={AudioMusicdetailPage} />
        <Protected exact path="/video-play/:title/:id" component={VideoMusicdetailPage} />
        <Protected exact path="/Talenthunt-play" component={TalentHuntUploadVideoPlayer} />
        <Protected exact path="/watch-video/:name/:id" component={TalentHuntVideoPlayer} />


        <Route exact path="/earninghistory" component={Walltetable} />
        <Protected exact path='/accountdetail' component={AddBankDetailPage} />
        <Protected exact path='/withdrawpage' component={SendMonetToBank} />
        <Protected exact path='/withdrawhistory' component={SendmoneyHistory} />
        <Protected exact path='/banklist' component={UserBankList} />


        <Protected exact path="/music-audio" component={AudiMusicListPage} />
        <Protected exact path="/music-video" component={VideoMusicListPage} />
        <Protected exact path="/web-series" component={SeriesPages} />
        <Protected exact path="/web-series/:subcateogry_name/:subcategory_id" component={SeriesSubCategoryListPage} />
        <Protected exact path="/shortfilmpage" component={ShortVideoPages} />
        <Protected exact path="/studentcorner" component={StudentCornerPage} />
        <Protected exact path="/video" component={VideoPlayer} />
        <Protected exact path="/loginprofile" component={ManageProfile} />
        <Protected exact path="/uploadPage" component={UploadVideoPage} />
        <Protected exact path="/dashboard" component={DashBord} />
        <Protected exact path="/videoplay" component={Videoplay} />
        <Protected exact path="/uplodmovieList" component={UploadVideoList} />
        <Protected exact path="/favroiute-list" component={WishListPage} />
        <Protected exact path="/talenthuntlist" component={TalentHuntHome} />
        <Protected exact path="/uploadvideoform" component={UploadVideoForm} />
        <Protected exact path="/videoplayer" component={videoplayer} />
        <Protected exact path="/dashboardContent" component={DashoardContent} />
        <Protected exact path="/adminVideoPlayer" component={AdminVideoPlayer} />
        {/* <Protected exact path="/studentcorner/:subject/:id" component={StudentCornerVideoPlayer} /> */}
        <Route exact path="/terms-and-conditions" component={TermConditionPage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route exact path="/refund-policy" component={RefundPolicy} />
        <Route exact path="/disclaimer" component={DisclaimerPage} />
        <Route exact path="/AboutUs" component={AboutUs} />
        <Route exact path='/planform' component={Demopage} />
        <Route exact path='/renew/planform' component={RenewPage} />
        <Route exact path='/subscription-pricing' component={SubscriptionPlanPage} />


        <Protected exact path="/series/:seriesname/:seriesid" component={EpisodeDetailPage} />
        <Protected exact path="/episodeplayer" component={EpisodeVideoPlayer} />

        <Protected exact path="/Watch-movie" component={MoviePages} />
        <Protected exact path="/Watch-movie/:name/:id" component={SubCategoryVideoListPage} />
        <Protected exact path="/Watch-short-movie/:name/:id" component={ShortFilmsSubCategoryListPage} />

        <Protected exact path="/videoDetailPage" component={VideoDetailPage} />
        <Protected exact path='/shortfilms/:moviename/:id' component={MovieDetailPage} />

        <Protected exact path='/movies/:moviename/:id' component={MovieDetailPage} />
        <Protected exact path='/studentcorner/:videoname/:id' component={StudentCornerDetailPage} />
        {/* <Protected exact path='/talenthunt/:videoname/:id' component={TalentHuntDetailPage} /> */}
        {/* <Protected exact path='/list/:type' component={HomeCrouselMovieList} /> */}
        <Protected exact path="/talenthuntdetail/:videoname/:id" component={TalentHuntDetailPage} />
        <Protected exact path="/talenthuntvideo/:subcategory_name/:subcategory_id" component={TalentHuntSubCategoryListPage} />

        <Protected exact path='/list/:category_id/:type' component={HomeCrouselMovieList} />
        <Protected exact path='/webshow-list/:type' component={HomeShowCrouselViewList} />

        <Protected
          exact
          path="/studentCorner/list/:subcategory_name/:subcategory_id"
          component={StudentCornerSubCategoryListPage}
        />
        {/* <Protected
          exact
          path="/talenthuntlist/:subcategory_name/:subcategory_id"
          component={TalentHuntSubCategoryListPage}
        /> */}
        <Protected
          exact
          path="/talenthuntdetailpage"
          component={TalentHuntVideoDetailPage}
        />
        <Protected
          exact
          path="/Video-player"
          component={HomeCrouselAdminVideoPlayer}
        />
        <Protected
          exact
          path='/continue-watching'
          component={ContinueWatchingPage}
        />
        <Protected exact path="/watchlist" component={WishListPage} />

        {/* </Switch> */}
        {/* { localStorage.getItem('loginid') ?  */}
        {window.location.pathname === '/mobile-player/:categoryid/:subcategory_id/:videoid' ? "" :
              <>
                <Footer />
              </>
            }
        
        {/* :""} */}
      </Provider>
    </Fragment>
    </Switch>
  );
}

export default App;
