import React, { Fragment,useState,useEffect } from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../../../../store/config';
import MovieTabCrouselCard from '../../../../../Pages/MovieTab/MovieTabCrouselCard';
import MovieTabDetailPage from '../../../../../Pages/MovieTab/MovieTabDetailPage';
import SeriesTabCrouselCard from '../../../../../Pages/SeriesPage/SeriesTabCrouselCard';
import SeriesTabDetailPage from '../../../../../Pages/SeriesPage/SeriesTabDetailPage';
import './HomeShowCrouselViewList.scss';

const { Title } = Typography;

function HomeShowCrouselViewList() {
    const id = useParams();
    console.log(id)
    const [VideoList, setVideoList] = useState([])
    const moviedetailstyle = {
        // top: '-70%'
    }

    useEffect(() => {
        VideoLstapi()
        console.log('latest')
    }, [])
    const VideoLstapi = async () => {
        const data = {
            show_type:id.type==="Recently-Added" ? "Recently Added":id.type ,
            limit:'100'
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getshow_typeList`, data)
            .then(res => {
                if (res.data.msg === 'show List Find') {
                    console.log(res.data.result)
                    setVideoList(res.data.result)
                }
                else {
                    console.log(res.data)
                }
            })
    }
    return (
        <Fragment>
            <div className='container-fluid crousel_all_movie_list_container'>
                <div className='row'>
                    <div className='col-12 crousel_all_movie_list_col'>
                        <Title level={2} className='text-white'>{id.type} Web Shows</Title>
                        <div class="main">
                            <ul class="cards">
                                {VideoList && VideoList.map((item, index) => {
                                    return (
                                        <li className='newcrouseldiv card_list'>
                                            {/* <Link
                                                to={{
                                                    pathname: `/movie/${item.title}/${item.id}`

                                                }} style={{ textDecoration: 'none' }}
                                            > */}
                                                <SeriesTabCrouselCard item={item} />
                                                <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                            {/* </Link> */}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HomeShowCrouselViewList;
