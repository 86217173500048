
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { GetmovieContentType } from '../../store/action/TalentHuntVideoList';
 import '../../resuable/MovieDetail.css'

const SeriesTabDetailPage = (props) => {
    console.log(props.item)
    // console.log(window.innerWidth)
    useEffect(() => {
        const data = {
            video_id: props.item.id,
            subcategory_name: props.item.subcategory_name
        }
    }, [])
    return (
        <Fragment>
            <div className='mobilemoviedetailhover  container-fluid' style={{}}>
                <div className='row'>
                    
                    <div className='col-12 pl-4 pt-3'>
                        <h6 className='moviedetailtitle'>{props.item.season_name}
                        
                        </h6>
                        <span className='text-secondary'>Season {!props.item.seasonid ?"1 Comming Soon": props.item.seasonid.length}, episode {props.item.episodelength}</span>

                        {/* <div class="hover-buttons">
                            <Link
                                to={{
                                    pathname: '/adminVideoPlayer',
                                    state: {
                                        item: props.item,
                                    }
                                }}
                            >
                                <span className="btn btn-hover p-1" style={{ background: '#007bff', color: '#fff', padding: '', borderRadius: '0px' }}>
                                    <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                    Play Now
                                   </span>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='moviedetailhover container-fluid' style={{}}>
                <div className='row'>
                    
                    <div className='col-12 pl-4 pt-3'>
                        <h6 className='m-0 moviedetailtitle'>{props.item.title}
                        
                        </h6>
                        <span className='text-secondary'>Season {!props.item.seasonid ?"1 Comming Soon": props.item.seasonid.length} {props.item.episodelength}</span>
                        {/* <div class="hover-buttons">
                            <Link
                                // to={{
                                //     pathname: '/adminVideoPlayer',
                                //     state: {
                                //         item: props.item,
                                //     }
                                // }}
                            >
                                <span className="btn btn-hover p-1" style={{ background: '#007bff', color: '#fff', padding: '', borderRadius: '0px' }}>
                                    <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                    Play Now
                                   </span>
                            </Link>
                        </div> */}
                    </div>

                </div>
            </div>
        </Fragment>
    )
}
// const mapStateToProps = state => ({
//     getmovieContentType: state.UploadVideoListByCategoryReducer.getmovieContentType,
//     getmovieContentTypeLoder: state.UploadVideoListByCategoryReducer.getmovieContentTypeLoder,
// })

// const mapDispatchToProps = dispatch => ({
//     GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data)),

// })
export default SeriesTabDetailPage;
// connect(mapStateToProps, mapDispatchToProps)
// (MoviDetailPage)
