
import React, { Fragment } from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import { Link } from 'react-router-dom';
import './TalentHuntTabCrouselCard.scss';
// import './MovieTabCrouselCard.scss'

const TalentHuntTabCrouselCard = (props) => {
    const {item}=props
    console.log(props.item)
    return (
        <Fragment>
            <div className='itemimagetag'>
                {/* <Link
                    to={{
                        pathname: '/Talenthunt-play',
                        state: {
                            item: props.item,
                        }
                    }}
                > */}
                    <img className='itemimagetag talenthunt_crousel_image' style={{objectFit:'fill', borderRadius: '3px', boxShadow: "0 2px 5px 0 rgb(34 34 34 / 40%)" }}
                    src={`${item.image_url}`} 
                    //  src={`${LOCAL_SERVER}/${PANCHANG_SERVER}/fetchUplaodImage/${item.image}`}
                       type='file/png'
                        ></img>
                {/* </Link> */}
            </div>

        </Fragment>


    )
}
export default TalentHuntTabCrouselCard;
