import {GET_USER_SUBCATEGORY_LIST,GET_USER_MOVIE_LIST,GET_USER_MOVIE_LIST_CONTENT_TYPE} from '../constatnt';

const initState={
   UploadVideoListBysubcategory:'',
   UploadVideoListBySubCategoryLoder:false,
   GetMoviListofuserbysubcategory:'',
   getmovielistbysubvategoryloder:false,
   getmovieContentType:'',
   getmovieContentTypeLoder:''

}



const UploadVideoListByCategoryReducer=(state=initState,action)=>{
    
    switch(action.type){
       
        case GET_USER_SUBCATEGORY_LIST:
            console.log(action.data)
            console.log('CategoryUploadvideoList Reducer')
            return{
                ...state,
                UploadVideoListBysubcategory:action.data,
                UploadVideoListBySubCategoryLoder:true,
            }
            case GET_USER_MOVIE_LIST:
                console.log(action.data)
                console.log('movie list Reducer')
                return{
                    ...state,
                    GetMoviListofuserbysubcategory:action.data,
                    getmovielistbysubvategoryloder:true,
                }
                case GET_USER_MOVIE_LIST_CONTENT_TYPE:
                    console.log(action.data)
                    console.log('movie contentType list Reducer')
                    return{
                        ...state,
                        getmovieContentType:action.data,
                        getmovieContentTypeLoder:true,
                    }
           
        default:
        return state;
    }
}

 export default UploadVideoListByCategoryReducer;