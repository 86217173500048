import React, { Fragment } from 'react'
import './TalentHuntTabCrouselDetail.scss';
function TalentHuntTabCrouselDetail(props) {
const { item } = props

    return (
        <Fragment>
            <div className='container-fluid '>
                <div className='row'>
                    <div className='col-12 p-0'>
                        <div class="card-details card_detail_talenthunt" style={{ fontSize: '14px' }}>
                            <h3 class="text-overflow card-header pl-0 " style={{ color: '#fff', fontWeight: '400' }}>{item.title}</h3>
                            {/* <div class="text-overflow card-subheader text-white" style={{ fontSize: '14px' }}>
                                {item.year}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TalentHuntTabCrouselDetail;
