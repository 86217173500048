import React, {useState } from 'react';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';


function AudioMusicListValidation() {
    const [AudioMusicList,setAudioMusicList]=useState([])

    const fetchAudiMusicList=()=>{
        const data={
            subcategory_id:'13'
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/audio_music_list`,data)
        .then(res=>{
            if(res.data.msg==='list find success'){
                if(res.data.result.length>0){
                    console.log(res.data.result)
                    setAudioMusicList(res.data.result)
                }
                else{
                    console.log('list empty')
                }
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }
   

    return {fetchAudiMusicList,AudioMusicList}
}

export default AudioMusicListValidation
