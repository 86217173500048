import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import './cardlistPage.scss';

function SeriesCardListPage(props) {
    const {VideoList}=props
    console.log(props.VideoList)
    return (
        <Fragment>
            <div class="main_subcategory_video_card">
                <ul class="cards">
                    {VideoList.map((item, index) => {
                        return (
                            <li class="cards_item">
                                <Link
                                
                                to={{
                                    pathname: `/series/${item.title.replace(/ /g,"-")}/${item.id}` ,
                                    state: {
                                        show_id: item.id,
                                        seasonLength: !item.seasonid ? "Comming Soon":item.seasonid.length
                                    }
                                }} style={{ textDecoration: 'none',width:'100%' }}
                                >
                                    <div class="carddiv">
                                        <div class="card_image"><img src={`http://admin.kidzjoy.org/${item.image_path}`} /></div>
 
                                        <div class="card_content">
                                            <h2 class="card_title">{item.title}</h2>
                                            {/* <p class="card_text">{item.year}</p> */}
                                            {/* <button class="btn card_btn">Play Video</button> */}
                                        </div>
                                    </div>
                                </Link>
                            </li>

                        )
                    })}
                </ul>
            </div>
        </Fragment>
    )
}

export default SeriesCardListPage;
