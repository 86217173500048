import React, { useEffect } from 'react';
import { Fragment } from 'react';
import Withoutloginheader from '../../HomeLayout/Header/withoutLoginHeader';

const PrivacyPolicyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }, [])
    return (
        <Fragment>
            {localStorage.getItem('loginid') ? "" :
                <Withoutloginheader />
            }            <div className='container'>
                <div className='row'>
                    <div className='col-12 text-white text-center mt-3' style={{ fontWeight: '700', fontSize: '25px', margin: '15px 0px' }}>
                        Privacy Policy
                    </div>
                    <div className='col-12 text-white mt-2'>
                        This privacy policy sets out how Kidsflixx company and its affiliated entities use and protect any information that you give us when you use this service.
                        We are committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this service, then you can be assured that it will only be used in accordance with this privacy statement. We may change this policy from time to time by updating this page.

                    </div>
                    <div className='col-12 text-white mt-2' style={{ fontWeight: '700' }}>
                        What we collect
                    </div>
                    <div className='col-12 text-white mt-2'>
                        We may collect the following information:
                    </div>
                    <div className='col-12 text-white mt-2'>
                        <ol>
                            <li className='mt-3'>
                                • Name.
                            </li>
                            <li className='mt-3'>
                                •	Contact information including email address.
                            </li>
                            <li className='mt-3'>
                                •	Demographic information such as zip code, postal code, preferences, and interests.
                            </li>
                            <li className='mt-3'>
                                •	Other information relevant to customer surveys and/or offers.
                            </li>

                        </ol>
                    </div>
                    <div className='col-12 text-white mt-2' style={{ fontWeight: '700' }}>
                        What we do with the information we gather
                    </div>
                    <div className='col-12 text-white mt-2'>
                        We require this information to provide you with a better service, and in particular for the following reasons:
                    </div>
                    <div className='col-12 text-white mt-2'>
                        <ol>
                            <li className='mt-3'>
                                •	Internal record keeping.
                            </li>
                            <li className='mt-3'>
                                •	We may use the information to improve our products and services.
                            </li>
                            <li className='mt-3'>
                                •	We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address and contact number, which you have provided.
                            </li>
                            <li className='mt-3'>
                                •	From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, or mail. We may use the information to customize the service according to your interests.
                            </li>
                        </ol>
                    </div>
                    <div className='col-12 text-white mt-2' style={{ fontWeight: '700' }}>
                        Security
                    </div>
                    <div className='col-12 text-white mt-2'>
                        We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
                    </div>
                    <div className='col-12 text-white mt-2' style={{ fontWeight: '700' }}>
                        How we use cookies
                    </div>
                    <div className='col-12 text-white mt-2'>
                        A cookie is a small file which asks permission to be placed on your computer's hard drive or your device. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
                    </div>
                    <div className='col-12 text-white mt-2'>
                        We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our service in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
                    </div>
                    <div className='col-12 text-white mt-2'>
                        Overall, cookies help us provide you with a better service, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
                    </div>
                    <div className='col-12 text-white mt-2'>
                        You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the service.
                    </div>
                    <div className='col-12 text-white mt-2' style={{ fontWeight: '700' }}>
                        Links to other services
                    </div>
                    <div className='col-12 text-white mt-2'>
                        Our service may contain links to other services of interest. However, once you have used these links to leave our site, you should note that we do not have any control over those linked service. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to each service visited.
                    </div>
                    <div className='col-12 text-white mt-2' style={{ fontWeight: '700' }}>
                        Controlling your personal information
                    </div>
                    <div className='col-12 text-white mt-2'>
                        You may choose to restrict the collection or use of your personal information in the following ways:
                    </div>
                    <div className='col-12 text-white mt-2'>
                        <ol>
                            <li className='mt-3'>
                                •	Whenever you are asked to fill in a form on the service, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.
                            </li>
                            <li className='mt-3'>
                                •	If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at info@kidsflixx.com
                            </li>
                        </ol>
                    </div>
                    <div className='col-12 text-white mt-2'>
                        We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting.
                    </div>
                    <div className='col-12 text-white mt-2'>
                        If you believe that any information we are holding on you is incorrect or incomplete, please email us at info@kidsflixx.com. We will correct any information found to be incorrect.
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default PrivacyPolicyPage;