import React, { useEffect, useState, Fragment } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import ItemsCarousel from 'react-items-carousel';
import SeriesTabCrouselCard from './SeriesTabCrouselCard';
import SeriesTabDetailPage from './SeriesTabDetailPage';
import { connect } from 'react-redux';
import { GetSeriesListByCategory } from '../../../store/action/SeriesTabAction';
import { Link } from 'react-router-dom';
import {

} from '../../../store/action/MovieTabAction';
import '../../HomeLayout/Crousel/Crousel.css';

const SeriesMultiItemListCrousel = (props) => {
    console.log(props.movieListLoder)
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8]);

    const subcategory = props.subcategory_id
    const [videoList, SetVideoList] = useState({
    })
    const [status, setStatus] = useState(false)

    const moviedetailstyle = {
        top: '-200%'
    }
    const breakPoints = [

        { width: 2, itemsToShow: 2 },
        { width: 550, itemsToShow: 2 },
        { width: 850, itemsToShow: 4 },
        { width: 1150, itemsToShow: 4 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
    ]
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ?
            <button className='btn rec-arrow-left' onClick={onClick} disabled={isEdge}>
                <i class="fa fa-chevron-left "></i></button>
            :
            <button className='btn rec-arrow-right' onClick={onClick} disabled={isEdge}>
                <i class="fa fa-chevron-right"></i></button>

        return (
            pointer
        )
    }

    useEffect(() => {
        props.GetSeriesListByCategoryHandler()
    }, [])

    return (
        <Fragment>

            {props.movieListLoder === true ?
                <>
                    <div className='crouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={6}
                            gutter={20}
                            leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                            rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                            chevronWidth={chevronWidth}
                        >
                            {props.MovieList.filter((el) => { return el.subcategory_id === props.subcategory_id }).map((item, index) => {
                                return (
                                    <>
                                        <Link
                                            to={{
                                                pathname: `/series/${item.title.replace(/ /g,"-")}/${item.id}` ,
                                                state: {
                                                    show_id: item.id,
                                                    seasonLength: !item.seasonid ? "Comming Soon":item.seasonid.length
                                                }
                                            }}
                                        >
                                            <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                                <SeriesTabCrouselCard item={item} />
                                                <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                            </div>
                                        </Link>
                                    </>

                                )
                            })}
                        </ItemsCarousel>
                    </div>
                    <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={3}
                            gutter={10}
                            leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                            rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                            chevronWidth={chevronWidth}
                        >
                            {props.MovieList.filter((el) => { return el.subcategory_id === props.subcategory_id }).map((item, index) => {
                                return (

                                    <>
                                        <Link
                                            to={{
                                                pathname: '/watch-series',
                                                state: {
                                                    show_id: item.id,
                                                    seasonLength: !item.seasonid ? "comming Soon":item.seasonid.length
                                                }
                                            }}
                                        >
                                            <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                                <SeriesTabCrouselCard item={item} />
                                                <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                            </div>
                                        </Link>
                                    </>

                                )
                            })}
                        </ItemsCarousel>
                    </div>
                </>
                : "Loading "}
        </Fragment>
    )
}
const mapStateToProps = state => ({
    // GetMoviListofuserbysubcategory: state.UploadVideoListByCategoryReducer.GetMoviListofuserbysubcategory,
    // getmovielistbysubvategoryloder: state.UploadVideoListByCategoryReducer.getmovielistbysubvategoryloder,

    // getmovieContentType: state.UploadVideoListByCategoryReducer.getmovieContentType,
    // getmovieContentTypeLoder: state.UploadVideoListByCategoryReducer.getmovieContentTypeLoder,
})
const mapDispatchToProps = dispatch => ({
    GetSeriesListByCategoryHandler: data => dispatch(GetSeriesListByCategory(data)),

    // GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data))

})

export default connect(mapStateToProps, mapDispatchToProps)(SeriesMultiItemListCrousel);
