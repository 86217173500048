import React, { useEffect, useState, Fragment } from 'react';
import MovieTabCrouselCard from './MovieTabCrouselCard';
import Carousel, { consts } from 'react-elastic-carousel';
import ItemsCarousel from 'react-items-carousel';
import MovieTabDetailPage from './MovieTabDetailPage';
import { connect } from 'react-redux';
import { GetmovieListByCategory } from '../../../store/action/MovieTabAction';
import {Link} from 'react-router-dom';
import '../../HomeLayout/Crousel/Crousel.css';
import './MovieMultiListCrousel.scss';

const MovieMultiItemListCrousel = (props) => {
    const { moviList } = props
    console.log(props.moviList)

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 15;
    const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8]);

    const subcategory = props.subcategory_id
    const [videoList, SetVideoList] = useState({
    })
    const [status, setStatus] = useState(false)

    const moviedetailstyle = {
        top: '-200%'
    }
    const breakPoints = [

        { width: 2, itemsToShow: 2 },
        { width: 550, itemsToShow: 2 },
        { width: 850, itemsToShow: 4 },
        { width: 1150, itemsToShow: 4 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
    ]
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ?
            <button className='btn rec-arrow-left' onClick={onClick} disabled={isEdge}>
                <i class="fa fa-chevron-left "></i></button>
            :
            <button className='btn rec-arrow-right' onClick={onClick} disabled={isEdge}>
                <i class="fa fa-chevron-right"></i></button>

        return (
            pointer
        )
    }



    return (
        <Fragment>
            <>
                <div className='crouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={6}
                        gutter={20}
                        leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                        rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                        // outsideChevron
                        chevronWidth={chevronWidth}
                    >
                        {moviList.map((item, index) => {
                            return (
                                <Link to={{
                                    pathname: `/movies/${item.title.replace(/ /g, "-").toLowerCase()}/${item.id}`
                                }}>                             <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                        <MovieTabCrouselCard item={item} />
                                        <MovieTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                    </div>
                                </Link>

                            )
                        })}
                        {/* {moviList.map((item,index)=> {
                                return (
                                    <div className='' style={{ borderRadius:'3px',height: "", background: '' }}>
                                        <MovieTabCrouselCard item={item} />
                                        <MovieTabDetailPage item={item} />
                                    </div>
                                )
                            })} */}
                    </ItemsCarousel>
                </div>
                <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={3}
                        gutter={10}
                        leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                        rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                        chevronWidth={chevronWidth}
                    >
                        {moviList.map((item, index) => {
                            return (
                                <Link to={{
                                    pathname: `/movie/${item.title}/${item.id}`
                                }}>                             <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                        <MovieTabCrouselCard item={item} />
                                        <MovieTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                    </div>
                                </Link>

                            )
                        })}
                    </ItemsCarousel>
                </div>
            </>

        </Fragment>
    )
}
const mapStateToProps = state => ({
    // GetMoviListofuserbysubcategory: state.UploadVideoListByCategoryReducer.GetMoviListofuserbysubcategory,
    // getmovielistbysubvategoryloder: state.UploadVideoListByCategoryReducer.getmovielistbysubvategoryloder,

    // getmovieContentType: state.UploadVideoListByCategoryReducer.getmovieContentType,
    // getmovieContentTypeLoder: state.UploadVideoListByCategoryReducer.getmovieContentTypeLoder,
})
const mapDispatchToProps = dispatch => ({
    // GetmovieListByCategoryHandler: data => dispatch(GetmovieListByCategory(data)),

    // GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data))

})

export default connect(mapStateToProps, mapDispatchToProps)(MovieMultiItemListCrousel);
