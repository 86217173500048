import React, { Fragment,useEffect,useState} from 'react';
 import axios from 'axios';
import { Breadcrumb, Typography, Divider,notification } from 'antd';
import { HomeOutlined, UserOutlined,AppstoreAddOutlined,ShareAltOutlined } from '@ant-design/icons';
import {LOCAL_SERVER,PANCHANG_SERVER} from '../../../../store/config';
import { useHistory, useParams } from 'react-router';
import {Link} from 'react-router-dom';
import {ADMIN_PATH} from '../../../../store/config';
import ShareLinkPage from '../../../resuable/shareLinkPage';
import './StudentCornerDetailPage.scss';
const { Title, Paragraph, Text } = Typography;

function StudentCornerDetailPage() {
    const history = useHistory()
    const movie_id= useParams();
    const [movieDeatil,setMovieDetail]=useState(null);
    const [contentTypeList,setContentTypeList]=useState([])
    const [wishListStatus,setwishListStatus]=useState('0');
    const homebreadcampbutton = () => {
        history.push('/HomePage')
    }
    const movieVreadcapm = () => {
        history.push('/studentcorner')
    }
    const fetchwishListStatus=(id,cat_id)=>{
        const data={
            "user_id":localStorage.getItem('loginid'),
            "video_id":id,
            "category_id":cat_id    
        }
        console.log(data)
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findwishlist_video_status`,data)
        .then(res=>{
            if(res.data.msg==='Find  Success'){
                if(res.data.result.status==='0'){
                    setwishListStatus('0')
                }
                else{
                    setwishListStatus('1') 
                }
            }
        })
        .catch(err=>{
            console.log(err)
        })
    
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        fetchMovieDetail()
    },[])
    const fetchMovieDetail=async()=>{
        await axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findStudeCornerDetailByid/${movie_id.id}`)
         .then(res=>{
             console.log(res)
             if(res.data.msg==="Data Find Success"){
                setMovieDetail(res.data.detail[0])
                setContentTypeList(res.data.contenttypeList)
                fetchwishListStatus(res.data.detail[0].id,res.data.detail[0].category_id)
             }
             else{
                 console.log('Data Not Found')
             }

         })
         .catch(err=>{
             console.log(err)
         })
     } 
     const removewatchList=()=>{
        const data={
            "user_id":localStorage.getItem('loginid'),
            "category_id":movieDeatil.category_id,
            "video_id":movieDeatil.id,
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/delete_wishlist`,data)
        .then(res=>{
            if(res.data.msg=="Delete  Success"){
                setwishListStatus('0')
                notification['success']({
                    message: 'Video removed from watch list'
                });
            }
            else{
                notification['error']({
                    message: 'Something Error Try Again!'
                });
            }
        })
        .catch(err=>{
            console.log(err)
            notification['error']({
                message: 'Something Error Try Again!'
            });
        })
    
    
    }
    const addwatchList=()=>{
        const data={
            "user_id":localStorage.getItem('loginid'),
            "category_id":movieDeatil.category_id,
            "video_id":movieDeatil.id,
            "subcategory_id":movieDeatil.subcategory_id,
            "status":"1"   
            }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/add_wishlist`,data)
        .then(res=>{
            if(res.data.msg==='Crate watch List'){
                setwishListStatus('1') 
                notification['success']({
                    message: 'Video added to watch list'
                });
            }
            else{
                notification['error']({
                    message: 'Something Error Try Again!'
                });
                    }
        })
        .catch(err=>{
            console.log(err)
        })
    }
    return (
        <Fragment>
            <div className='container-fluid movie_deatil_page_container'>
            {movieDeatil &&

                <div className='row flex-column-reverse flex-lg-row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 movie_detail_col'>
                        <div className='breadcamp_div'>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={homebreadcampbutton}>
                                    <HomeOutlined />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item onClick={movieVreadcapm}>
                                    <span>Student Corner</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item className='text-success'>{movieDeatil.subject}</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Title className='movie_name' level={2}>{movieDeatil.subject}</Title>
                       
                        <ul className='d-flex movie_play_button_ul'>
                            <li>
                         <Link
                         to={{
                            pathname: '/adminVideoPlayer',
                            state: {
                                item: movieDeatil,
                                categoryname:"Student Corner",
                                language:movieDeatil.class,
                                contenttype:movieDeatil.subject
                            }
                        }} className='btn playbutton'
                       >Play Movie</Link>
                            </li>
                            {wishListStatus==='0' ?
                              <li onClick={addwatchList}><Text className='text-success add_watch'><i class="fas fa-heart"></i> Add Watch list</Text></li>
                            :
                              <li onClick={removewatchList}><Text className='text-danger remove_watch'><i class="fas fa-trash-alt"></i> Remove from watch list</Text></li>

                            }
                             <li style={{cursor:'pointer'}}>
                            <ShareLinkPage />
                            </li>
                            {/* <li style={{cursor:'pointer'}}><Text className='text-white share_me'><i class="fa fa-share-alt" aria-hidden="true"></i> Share</Text></li> */}
                        </ul>
                        <Text className='text language_text' level={5}>Institution:<Text className='language_text_value'>{movieDeatil.institution}</Text></Text><br></br>
                        <Text className='text language_text' level={5}>Class:<Text className='language_text_value'>{movieDeatil.class}</Text></Text><br></br>
                        <Text className='text language_text' level={5}>Teacher Name:<Text className='language_text_value'>{movieDeatil.teacher_name}</Text></Text><br></br>
                        <Text className='text language_text' level={5}>Subject:<Text className='language_text_value'>{movieDeatil.subject}</Text></Text><br></br>
                        <Text className='text language_text' level={5}>Chapter:<Text className='language_text_value'>{movieDeatil.chapter}</Text></Text><br></br>
                        <Text className='text language_text' level={5}>Topic:<Text className='language_text_value'>{movieDeatil.topic}</Text></Text><br></br>
                        {/* <Text className='text Genre_text' level={2}>Genre: <Text className='language_text'>Darama</Text></Text><br></br> */}
                        <Text className='text year_text' level={2}>Relase Date: <Text className='language_text'>{movieDeatil.release_date}</Text></Text><br></br>
                        {/* <div className='movie_desc_div'>
                            <Paragraph className='text-white'>
                             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.
                            </Paragraph>
                        </div> */}
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 mt-0 movie_image_col'>
                        <div className='movie_pic'>
                        <img className='movie_image' src={`${ADMIN_PATH}${movieDeatil.image_path}`} alt='image' width='100%' height='100%' />
                        </div>
                        
                    </div>
                </div>
            }
            </div>
        </Fragment>
    )
}

export default StudentCornerDetailPage;
