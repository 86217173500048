import api from "../../api/index";


const WishListServices = {
    MovieWishList: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("getwishlist", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    TalentHuntWishList: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("get_talentHunt_wishlist", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    StudentCornerWishList: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("get_studentCorner_wishlist", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    SeriesWishList: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("get_series_wishlist", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    WishListStatus: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("findwishlist_video_status", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
}

export default WishListServices;
