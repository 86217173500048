import React,{useState} from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import axios from 'axios';


  const   ManageProfileValidation=()=> {
    const [updatesuccess,setupdatesuccess]=useState(false);
    const [cancelstatus,setCancelstatus]=useState(false);
    const [Userpromocode,setUserpromocode]=useState('')

    const [state,setState]=useState({
        username:'',
         emailId:'',
        password:'',
        mobile:'',
        profileimage:''
    })
    const GetUserDetail=()=>{
        console.log('function')
        const user_id=localStorage.getItem('loginid')
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/finduserdetail/${user_id}`)
        .then(res=>{
            console.log(res)
        if(res.data.msg==='find success'){
            console.log(res.data.result[0])
            setUserpromocode(res.data.result[0].promoter_code)
            setState({
                username:res.data.result[0].name,
                emailId:res.data.result[0].email,
                mobile:res.data.result[0].mobileno
            })
        }
        })
        .catch(err=>{
            console.log(err)
        })

    }
    const handleChangeinput = (e) => {
        console.log(e.target.value)
        setState({
            ...state,
            // ,filenamepath:[e.target.value],videofilename:e.target.value,
            [e.target.name]: e.target.value
        })
    }
    const saveuserDetail=()=>{
        // var formdata = new FormData()
        // formdata.append('name', state.username);
        // formdata.append('email', state.emailId);
        // formdata.append('mobileno', state.mobile);
        // formdata.append('profileimage', state.profileimage[0]);

        const userdata={
            name:state.username,
            email:state.emailId,
            mobileno:state.mobile,
        }
        console.log(state.profileimage)

        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/updateUserDetail`,userdata)
        .then(res=>{
            console.log(res)
            if(res.data.msg==='success'){
                console.log('updated success')
                setCancelstatus(false)
                setupdatesuccess(true)
            }
        })

    }
    const seeprofileButton=()=>{
        setupdatesuccess(false)
    }
    return {state,handleChangeinput,saveuserDetail,GetUserDetail,updatesuccess,seeprofileButton,setupdatesuccess,
        cancelstatus,setCancelstatus,Userpromocode}
}
export default ManageProfileValidation;