import React, { Fragment, useEffect, useState } from 'react';
import {LOCAL_SERVER,PANCHANG_SERVER} from '../../../../store/config';
import BannerCrousel from '../../../resuable/bannerCrousel';
import axios from 'axios';
import Loder from '../../../resuable/Loder';
import MovieHomeCrousel from './HomeCrousel/MovieHomeCrousel';
import ShowHomeCrousel from './HomeShowTabCrousel/seriesHomeCrousel';
import StudentCornerHomeCrousel from './HomeCrousel/studentHomeCrouselList';
import CategoryTabCrousel from './categoryTabCrousel/index';
import ShowTypeTabCrousel from './HomeShowTabCrousel'
import HeaderNavBar from "../../Header/HeaderNavBar";
import SuggestForYouCrousel from './HomeCrousel/SuggestForYouCrousel';
import UpcomingMovieCrousel from './HomeCrousel/UpcomingMovieCrousel';
import LatestVideoCrousel from './HomeCrousel/LatestVideoCrousel';
import TrendingCrousel from './HomeCrousel/TrendingCrousel';
import KidzjoyoriginalCrousel from './HomeCrousel/kidzjoyoriginalCrousel';
import HomeCrouselTypeList from './HomeCrousel/HomeCrouselTypeList';
function HomeTab() {
    const [loder, setLoder] = useState(false);
  const [bannerVideo, setBannerVideo] = useState();
  useEffect(() => {
    axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/homePageBannerList`).then(res => {
      console.log(res)
      setBannerVideo(res.data.result)
      setLoder(true)
    })
    .catch(err=>{
    //  console.log(err)
      setBannerVideo('Something Error')
    })
  }, [])
    return (
        <Fragment>
            {loder === true ?
        <>
          <BannerCrousel bannerVideo={bannerVideo} />
          {/* <KidzjoyoriginalCrousel />
          <LatestVideoCrousel />
          <UpcomingMovieCrousel />
          <TrendingCrousel />
          <ShowTypeTabCrousel /> */}
          <HomeCrouselTypeList />
          <MovieHomeCrousel />
          <ShowHomeCrousel /> 
          <StudentCornerHomeCrousel />
        </>
        :
        <Loder />
      }
        </Fragment>
    )
}

export default HomeTab;
