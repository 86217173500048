import React, { Fragment, useState, useEffect } from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../../store/config';
import SeriesTabCrouselCard from '../SeriesTabCrouselCard';
import SeriesTabDetailPage from '../SeriesTabDetailPage';
// import './HomeCrouselMovieList.scss';
const { Title } = Typography;

function SeriesSubCategoryListPage(props) {
    const id = useParams();
    console.log(id)
    const [VideoList, setVideoList] = useState([])
    const moviedetailstyle = {
        // top: '-70%'
    }

    useEffect(() => {
        VideoLstapi()
        console.log('latest')
    }, [])
    const VideoLstapi = async () => {
        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/showListwithDetail/${id.subcategory_id}`)
            .then(res => {
                if (res.data.msg === 'show List Find') {
                    console.log(res.data.result)
                    setVideoList(res.data.result)
                }
                else {
                    console.log(res.data)
                }
            })
    }
    return (
        <Fragment>
            <div className='container-fluid crousel_all_movie_list_container'>
                <div className='row'>
                    <div className='col-12 crousel_all_movie_list_col'>
                        <Title level={2}></Title>
                        <div class="main">
                            <ul class="cards">
                                {VideoList && VideoList.map((item, index) => {
                                    return (
                                        <li className='card_list my-5'>
                                            <Link
                                                to={{
                                                    pathname: `/series/${item.title.replace(/ /g,"-")}/${item.id}`,
                                                    state: {
                                                        show_id: item.id,
                                                        seasonLength: !item.seasonid ? "Comming Soon" : item.seasonid.length
                                                    }
                                                }}
                                            >
                                                <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                                    <SeriesTabCrouselCard item={item} />
                                                    <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SeriesSubCategoryListPage;

