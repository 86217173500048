import React, { Fragment, useState, useEffect } from 'react';
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_green.css";
import Select from 'react-select';
import ManageProfileValidation from '../../Validation/manageProfileValidation';


import 'flatpickr/dist/themes/dark.css';
import './manageProfile.css'
const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#1a1a1a', color: 'white' }),
}
const ManageProfile = () => {
    const { state, handleChangeinput, saveuserDetail, GetUserDetail, updatesuccess, seeprofileButton,setupdatesuccess,cancelstatus,setCancelstatus,Userpromocode } = ManageProfileValidation();
    console.log(state)
    useEffect(() => {
        GetUserDetail()
    }, [])
    const cancelupdateprofile=()=>{
        setupdatesuccess(true)
        setCancelstatus(true)
    }
    return (
        <div>
            <Fragment>
            <section className="m-profile manage-p p-lg-0 p-md-5 section-m-profile" style={{ backgroundColor: 'black', boxShadow: '0px 0 20px 0 rgb(0 0 0 / 50%)' }}>
            <div className="container h-100 mt-5">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-lg-10">
                                <div className="sign-user_card shadow-lg    p-4 " style={{ backgroundColor: '#1a1a1a' }}>
                                    <div className="row">
                                        <div className="col-lg-10 device-margin text-white">
                                            <div className="profile-from">
                                                <h4 className="mb-3 font-weight-bold text-white">Your Promoter Code: <span>{Userpromocode}</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
                <section className="m-profile manage-p p-md-5 section-m-profile" style={{ backgroundColor: 'black', boxShadow: '0px 0 20px 0 rgb(0 0 0 / 50%)' }}>
                    <div className="container h-100 mt-5">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-lg-10">
                                <div className="sign-user_card shadow-lg    p-4 " style={{ backgroundColor: '#1a1a1a' }}>
                                    <div className="row">
                                        <div className="col-lg-2 mb-5 text-center">
                                            <div className="upload_profile d-inline-block">
                                                <img src="https://www.w3schools.com/howto/img_avatar.png" width='125px' height='125px' className="profile-pic rounded-circle img-fluid" alt="user" />
                                                {/* <div className="p-image">
                                                    <i className="ri-pencil-line upload-button"></i>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-10 device-margin text-white">
                                            <div className="profile-from">
                                                <h4 className="mb-3 font-weight-bold text-white">Manage Profile</h4>
                                                {updatesuccess === false ?
                                                    <form className="mt-4" action="index.html">
                                                        {/* <div className="form-group">
                                                        <label>Profile Image</label>
                                                        <input type="file" className="form-control mb-0  text-white" name='profileimage' value={state.profileimage} style={{ background: '#1a1a1a' }}
                                                            placeholder="Choose Your Profile Image." autocomplete="off" onChange={handleChangeinput} />
                                                    </div> */}
                                                        <div className="form-group">
                                                            <label>Name</label>

                                                            <input type="text" className="form-control mb-0  text-white" name='username' value={state.username} style={{ background: '#1a1a1a' }}
                                                                placeholder="Enter Your Name." autocomplete="off" onChange={handleChangeinput} />

                                                        </div>
                                                        <div className="form-group">
                                                            <label>Email</label>

                                                            <input type="text" className="form-control mb-0  text-white" name='emailId' value={state.emailId} style={{ background: '#1a1a1a' }}
                                                                placeholder="Enter Your Email" readOnly autocomplete="off" onChange={handleChangeinput} />

                                                        </div>
                                                        <div className="form-group">
                                                            <label>Mobile No.</label>
                                                            <input type="text" className="form-control mb-0  text-white" name='mobile' value={state.mobile} style={{ background: '#1a1a1a' }}
                                                                placeholder="Enter Your Mobile No." readOnly autocomplete="off" onChange={handleChangeinput} />
                                                        </div>



                                                        <div className="d-flex align-items-center my-5">
                                                            <a class="ant-btn ant-btn-primary" style={{ background: '#007bff', color: 'white' }} onClick={saveuserDetail}>Save</a>
                                                            <a  class="btn-link font-weight-bold fz-14 ml-3"onClick={cancelupdateprofile} >Cancel</a>
                                                        </div>
                                                    </form>
                                                    :
                                                    <>
                                                        <div className="d-flex">
                                                            <h5 className='text-success fz-16'>{cancelstatus===false ? 'Your profile has been updated successfully':""}</h5>
                                                        </div>
                                                        <div className="d-flex mt-4">
                                                            <a href="#" class="ant-btn ant-btn-primary" style={{ background: '#007bff', color: 'white' }} onClick={seeprofileButton}>See Your Profile</a>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        </div>
    )
}

export default ManageProfile;
