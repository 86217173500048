import api from "../api/index";


var CategoryVideoList={
    getCategoryVideoList: function (category_id) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .get(`categoryvideoList/${category_id}`,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    }
    
}

export default CategoryVideoList;
