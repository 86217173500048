import React, { Fragment, useEffect, useState } from 'react';
import { Icon, Col, Card, Row } from 'antd';
import MusicCardList from './MusicCardList';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import AudioMusicListValidation from './AudioMusicListValidation';
import BannerCrousel from '../../resuable/bannerCrousel';
import './AudioMusicPage.scss';

function AudiMusicListPage() {
    const { fetchAudiMusicList, AudioMusicList } = AudioMusicListValidation();
    const [bannerVideo, setBannerVideo] = useState([]);
    const { Meta } = Card;
    const array = ["1", "2", "3", "4", "5"]
    useEffect(() => {
        const id = '13'
        const catdata = {
            category_id: '6',
            subcategory_id:"16"
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getmusicbannerList`, catdata).then(res => {
            if (res.data.msg === "list find success") {
                console.log(res)
                setBannerVideo(res.data.result)
            }
        })

        fetchAudiMusicList(id)
    }, [])

    return (
        <Fragment>
             <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                    {bannerVideo.length > 0 ?
                            <BannerCrousel bannerVideo={bannerVideo} />
                             : 
                            ""
                             }
                    </div>
                </div>
            </div>
            <div className='container-fluid audiomusiclistPage_container'>
                {/* <div className='row header_row'>
                    <div className='col-12 header_col'>
                        {bannerVideo.length > 0 ?
                            <BannerCrousel bannerVideo={bannerVideo} />
                        : ""}
                    </div>
                </div> */}
                {/* <div className='row  header_info_row'>
                    <div className='col-12 header_info_col'>
                        <h2 >Browse All Music</h2>
                        <p>
                            Our entire collection of music videos and video songs for you, all in one place. Filter by language, genre, actor and more.
                        </p>
                    </div>
                </div> */}
                <div className='row musiccardlist_row mt-5 pt-5'>
                    {AudioMusicList > 0 ? "" :
                        <MusicCardList AudioMusicList={AudioMusicList} />
                    }

                </div>
            </div>
            {/* <div className='container-fluid audiomusiclistPage_container'>
                <div className='row header_row'>
                    <div className='col-12 header_col'>

                    </div>
                </div>
                <div className='row  header_info_row'>
                    <div className='col-12 header_info_col'>
                        <h2 >Browse All Music</h2>
                        <p>
                            Our entire collection of music videos and video songs for you, all in one place. Filter by language, genre, actor and more.
                        </p>
                    </div>
                </div>
                <div className='row musiccardlist_row'>
                    {AudioMusicList > 0 ? "" :
                        <MusicCardList AudioMusicList={AudioMusicList} />
                    }

                </div>
            </div> */}
        </Fragment>
    )
}

export default AudiMusicListPage;