import React,{Fragment} from 'react';

function ContinueWatchingPage() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <h3 className='text-white text-center'>Continue Watching</h3>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ContinueWatchingPage
