import React, { useEffect } from "react";
import { Fragment } from "react";
import Withoutloginheader from '../../HomeLayout/Header/withoutLoginHeader';



const DisclaimerPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }, []);
    return (
        <Fragment>
            {localStorage.getItem('loginid') ? "" :
                <Withoutloginheader />
            }   
                     <div className='container'>
                <div className='row'>
                    <div className='col-12 text-white text-center mt-3' style={{ fontWeight: '700', fontSize: '25px', margin: '15px 0px' }}>
                        DISCLAIMER
                    </div>
                    <div className='col-12 text-white mt-3'>
                        The information contained in this website is for general information & entertainment purposes only. The information is provided by The <b>Kidsflixx</b> company with reference to its Website/OTT Channel <b>KIDZJOY</b> and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                    </div>
                    <div className='col-12 text-white mt-3'>
                        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arise out of, or in connection with, the use of this website.
                    </div>
                    <div className='col-12 text-white mt-3'>
                        Through this Website/OTT Channel <b>KIDZJOY</b> you may be able to link to other websites which are not under the control of <b>Kidsflixx.</b> We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
                    </div>
                    <div className='col-12 text-white mt-3'>
                        Every effort is made to keep the website up and running smoothly. However, Kidsflixx takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                    </div>
                    <div className='col-12 text-white  mt-3' style={{ fontWeight: '700' }}>
                        Kidsflixx Head office is located at –
                    </div>
                    <div className='col-12 text-white'>
                        Sector – 24, Rohini, Delhi 110085
                    </div>
                    <div className='col-12 text-white mt-4'>
                        <ol>
                            <li>
                                Email:- Info@kidsflixx.com
                            </li>
                            <li>
                                Website:www.kidsflixx.com
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default DisclaimerPage;