import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import kidzoylogo from "../../assets/HeaderLogo/KidzjoyTextLogo.png";
import SignUpValidation from "../../Validation/SignUpValidation";

import "./signin.scss";

export default function SignIn(props) {
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    otpUi,
    verifyotpbutton,
    otpstate,
    verifyotphandlechange,
    otperror,
    otpverfiyui,
  } = SignUpValidation(props.SignUpUserHandler);
  return (
    <Fragment>
      <div className="container-fluid signin_container">
        <div className="row subscription_page_row">
          <div className="col-12 subscription_page_col">
            <ul className="subscription_page_header_ul">
              <Link to="/" className="subscription_page_header_li">
                <img className="kidjoylogo_image" src={kidzoylogo} alt="" />
              </Link>
              <li className="subscription_page_header_li">
                {/* <button className="signout_button" onClick={logoutbutton}>
                  Sign Out
                </button> */}
              </li>
            </ul>
          </div>
        </div>
        <div className="row signin_form_row">
          <div className="col-12 signin_form_column">
            <form className="sign_form">
              {otpverfiyui === false ? (
                <div className="sign_form_div">
                  <div className="sign_form_heading">
                    <h1 className="sign_form_heading_text">Create Account</h1>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="form-floating mb-3 ">
                      {/* <label for="floatingInput">Name</label> */}
                      <input
                        type="email "
                        className="form-control "
                        id="floatingInput"
                        placeholder="Enter Name"
                        name="name"
                        {...register("name", {
                          required: "Required",
                        })}
                      />
                    </div>
                    <div className="form-floating mb-3 ">
                      {/* <label for="floatingInput">Email address</label> */}
                      <input
                        type="email "
                        className="form-control "
                        id="floatingInput"
                        placeholder="name@example.com"
                        name="email"
                        {...register("email", {
                          required: "Required",
                        })}
                      />
                    </div>

                    <div
                      className="form-group mb-3"
                      style={{ display: "flex" }}
                    >
                      <select
                        className="select-control btn-default mr-2"
                        name="countrycode"
                        {...register("countrycode", {
                          required: "Required",
                        })}
                      >
                        <option value="91">+91</option>
                        <option value="1">+1</option>
                        <option value="1">+1</option>
                      </select>
                      <input
                        type="text"
                        className="form-control shadow-sm "
                        name="mobileno"
                        placeholder="Mobile No"
                        {...register("mobileno", {
                          required: "Required",
                        })}
                      />
                    </div>

                    <div class="form-floating  mb-3">
                      {/* <label for="floatingPassword">Password</label> */}
                      <input
                        type="password"
                        className="form-control "
                        id="floatingPassword"
                        name="password"
                        placeholder="Password"
                        {...register("password", {
                          required: "Required",
                        })}
                      />
                    </div>
                    <div className="form-floating  mb-3">
                      {/* <label for="floatingPassword">Confirm Password</label> */}
                      <input
                        type="password"
                        className="form-control "
                        id="floatingPassword"
                        name="cnfpassowrd"
                        placeholder="Confirm Password"
                        {...register("cnfpassowrd", {
                          required: "Required",
                        })}
                      />
                    </div>
                  </form>

                  <div className="form-floating text-center mt-3">
                    <button
                      className="btn btn-primary pl-4 pr-4 signupsubmitbutton"
                      onClick={handleSubmit(onSubmit)}
                    >
                      SignUp
                    </button>
                  </div>
                  {/* <div class="form-floating  mt-3 text-center">
                        <button className='btn bg-none p-0 text-primary' onClick={props.loginformbutton}>You have alredy Account ?</button>
                    </div> */}
                </div>
              ) : (
                <div className="sign_form_div">
                  <div>
                    <form>
                      <div className="sign_form_heading">
                        <h1 className="sign_form_heading_text">
                          Create Account
                        </h1>
                      </div>
                      <small
                        className="form-text  text-success  mb-2 "
                        style={{ fontSize: "1.6rem" }}
                      >
                        OTP Send Your Email
                      </small>
                      <div className="form-floating mb-3 ">
                        <input
                          type="email "
                          className="form-control "
                          id="floatingInput"
                          placeholder="Enter OTP"
                          name="otp"
                          value={otpstate.otp}
                          onChange={verifyotphandlechange}
                        />
                      </div>
                      <small
                        className="form-text text-danger  mb-2 "
                        style={{}}
                      >
                        {otperror}
                      </small>
                    </form>
                    <div className="form-floating text-center mt-3">
                      <button
                        className="btn btn-primary pl-4 pr-4 signupsubmitbutton"
                        onClick={verifyotpbutton}
                      >
                        Verify Your OTP
                      </button>
                    </div>
                  </div>
                  {/* } */}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
