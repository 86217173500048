import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Breadcrumb, Typography, Divider, notification } from 'antd';
import { HomeOutlined, UserOutlined, AppstoreAddOutlined, ShareAltOutlined } from '@ant-design/icons';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ADMIN_PATH } from '../../../store/config';
import ReadMorecomponent from '../../resuable/ReadMorecomponent';
import ShareLinkPage from '../../resuable/shareLinkPage';
import parse from 'html-react-parser';
import './MovieDetailPage.scss';
const { Title, Paragraph, Text } = Typography;

function MovieDetailPage() {
    const history = useHistory()
    const movie_id = useParams()
    console.log(movie_id)
    const [movieDeatil, setMovieDetail] = useState(null);
    const [wishListStatus, setwishListStatus] = useState('0');
    const [categoryname,setcategoryname]=useState('');
    const [categoryid,setcategoryid]=useState()
    const homebreadcampbutton = () => {
        history.push('/HomePage')
    }
    const movieVreadcapm = () => {
       if(categoryid==="3"){
        history.push('/Watch-movie')
       }
       else if(categoryid==="5"){
        history.push('/shortfilmpage')
       }
    }
    const fetchwishListStatus = (id, cat_id) => {
        const data = {
            "user_id": localStorage.getItem('loginid'),
            "video_id": id,
            "category_id": cat_id
        }
        console.log(data)
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findwishlist_video_status`, data)
            .then(res => {
                if (res.data.msg === 'Find  Success') {
                    if (res.data.result.status === '0') {
                        setwishListStatus('0')
                    }
                    else {
                        setwishListStatus('1')
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })

    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchMovieDetail()
    }, [])
    const fetchMovieDetail = async () => {
        await axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findmoviedetail/${movie_id.id}`)
            .then(res => {
                console.log(res)
                console.log(res.data.detail[0].year)
                const categoryid=res.data.detail[0].category_id
                const data=res.data.categoryList.filter(el=>{
                    return `${el.id}`===categoryid
                })
                console.log(data)
                setcategoryid(res.data.detail[0].category_id)
                setcategoryname(data[0].name)
                setMovieDetail(res.data)
                fetchwishListStatus(res.data.detail[0].id, res.data.detail[0].category_id)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const removewatchList = () => {
        const data = {
            "user_id": localStorage.getItem('loginid'),
            "category_id": movieDeatil.detail[0].category_id,
            "video_id": movieDeatil.detail[0].id,
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/delete_wishlist`, data)
            .then(res => {
                if (res.data.msg == "Delete  Success") {
                    setwishListStatus('0')
                    notification['success']({
                        message: 'Video removed from watch list'
                    });
                }
                else {
                    notification['error']({
                        message: 'Something Error Try Again!'
                    });
                }
            })
            .catch(err => {
                console.log(err)
                notification['error']({
                    message: 'Something Error Try Again!'
                });
            })


    }
    const addwatchList = () => {
        const data = {
            "user_id": localStorage.getItem('loginid'),
            "video_id": movieDeatil.detail[0].id,
            "category_id": movieDeatil.detail[0].category_id,
            "subcategory_id": movieDeatil.detail[0].subcategory_id,
            "status": "1"
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/add_wishlist`, data)
            .then(res => {
                if (res.data.msg === 'Crate watch List') {
                    setwishListStatus('1')
                    notification['success']({
                        message: 'Video added to watch list'
                    });
                }
                else {
                    notification['error']({
                        message: 'Something Error Try Again!'
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    return (
        <Fragment>
            <div className='container-fluid movie_deatil_page_container'>
                {movieDeatil &&
                    <div className='row flex-column-reverse flex-lg-row'>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 movie_detail_col'>
                            <div className='breadcamp_div'>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={homebreadcampbutton}>
                                        <HomeOutlined />
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={movieVreadcapm} style={{cursor:'pointer'}}>
                                        <span className='text-danger'>{categoryname}</span>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className='text-success'>{movieDeatil.detail[0].title}</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <Title className='movie_name' level={2}>{movieDeatil.detail[0].title} </Title>

                            <ul className='d-flex movie_play_button_ul'>
                                <li>
                                    <Link
                                        to={{
                                            pathname: '/adminVideoPlayer',
                                            state: {
                                                item: movieDeatil.detail[0],
                                                categoryname:categoryname,
                                                language:movieDeatil.language,
                                                contenttype:movieDeatil.contenttype
                                            }
                                        }} className='btn playbutton'
                                    >Play Movie</Link>
                                </li>
                                {wishListStatus === '0' ?
                                    <li onClick={addwatchList}><Text className='text-success add_watch'><i class="fas fa-heart"></i> Add Watch list</Text></li>
                                    :
                                    <li onClick={removewatchList}><Text className='text-danger remove_watch'><i class="fas fa-trash-alt"></i> Remove from watch list</Text></li>

                                }
                                {/* <li style={{cursor:'pointer'}}><Text className='text-white share_me'><i class="fa fa-share-alt" aria-hidden="true"></i> </Text></li> */}
                                <li style={{ cursor: 'pointer' }}>
                                    <ShareLinkPage />
                                </li>
                            </ul>
                            <Text className='text language_text' level={5}>Language: <Text className='language_text_value'>{movieDeatil.language[0]}</Text></Text><br></br>
                            <Text className='text Genre_text' level={2}>Genre: <Text className='language_text'>{movieDeatil.contenttype[0]}</Text></Text><br></br>
                            <Text className='text year_text' level={2}>Year: <Text className='language_text'>{movieDeatil.detail[0].year}</Text></Text><br></br>
                            <div className='movie_desc_div'>
                                <Paragraph className='text-white'>
                                    <ReadMorecomponent text={movieDeatil.detail[0].description} />
                                </Paragraph>
                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 mt-0 movie_image_col'>
                            <div className='movie_pic'>
                                <img className='movie_image' src={`${ADMIN_PATH}${movieDeatil.detail[0].image_path}`} alt='image' width='100%' height='100%' />
                            </div>

                        </div>
                    </div>
                }
            </div>
        </Fragment>
    )
}

export default MovieDetailPage
