import React, { useState, useEffect } from 'react';
import './PaginatedItems.scss';

function Pagination(props) {
    const { showperPage, onPaginationchange, total } = props
    const [counter, setCounter] = useState(1)
    useEffect(() => {
        console.log('object')
        const value = showperPage * counter
        console.log("start value:", value - showperPage)
        console.log("value:", value)
        onPaginationchange(value - showperPage, value)
    }, [counter])
    const onButtonClick = (type) => {
        if (type === 'next') {
            if (Math.ceil(total / showperPage) === counter) {
                setCounter(counter)
            }
            else {
                setCounter(counter + 1)
            }
        }
        else if (type === 'prev') {
            if (counter === 1) {
                setCounter(1)
            }
            else {
                setCounter(counter - 1)
            }
        }
    }
    return (
        <div className='d-flex pagination_div justify-content-center w-100'>
            {counter === 1 ?
                <button disabled className='btn btn-primary'
                    onClick={() => { onButtonClick('prev') }}
                >Prev</button>
                :
                <button className='btn btn-primary'
                    onClick={() => { onButtonClick('prev') }}
                >Prev</button>
            }
            {Math.ceil(total / showperPage) === counter ?

                <button disabled className='btn btn-primary'
                    onClick={() => { onButtonClick('next') }}
                >Next</button>
                :
                <button className='btn btn-primary'
                    onClick={() => { onButtonClick('next') }}
                >Next</button>
            }

        </div>
    )
}

export default Pagination