import api from "../api/index";


var commentServices={
    addUserComment: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("Create_User_comment", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    }
    
    ,getCommentList: function (id,body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .get(`getCommentlist/${body}/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    EditComment: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post('updatecomment',body)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    DeleteComment: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post('deletecomment',body)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    
}

export default commentServices;
