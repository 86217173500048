import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import {rootReducer} from './reducer/index';
import { devToolsEnhancer } from 'redux-devtools-extension';


const initialState ={};

export const store = createStore(
    rootReducer,initialState,
compose(applyMiddleware(promiseMiddleware ,thunk),
//    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
)