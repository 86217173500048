import React, { Fragment, useEffect, useState } from "react";
import kidzoylogo from "../../assets/HeaderLogo/KidzjoyTextLogo.png";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import SuccessPage from './SuccessPage';
import { Modal, Button, Typography } from 'antd';


import "./subscriptionPlanPage.scss";

export default function SubscriptionPlanPage() {
  const [planList, setPlanList] = useState();
  const [status, setstatus] = useState(false);
  const [paymentSuccessAlert, setPaymentSuccessAlert] = useState(false)
  const [start_date, setStart_date] = useState();
  const [end_date, setend_date] = useState();
  const planarray = [{ name: "Promoter", price: 500, days: 365 }, { name: "Normal User", price: 250, days: 365 }, { name: "School", price: 400, days: 365 }, { name: "Student", price: 200, days: 365 }]
  const logoutbutton = () => {
    localStorage.removeItem("loginid");
    window.location = "/";
  };
  const homebutton = () => {

  }
  //   useEffect(()=>{
  //     axios.get('https://api.kidzjoy.org/api/get_subscriptionPlanList')
  //     .then(res=>{
  //       if(res.data.msg==='list find success'){
  //         setPlanList(res.data.result)
  //         setstatus(true)
  //       }
  //       else{
  //         setPlanList('Empty List')
  //       }
  //       console.log(res)
  //     })
  //     .catch(err=>{
  //       console.log(err)
  //     })

  //   },[])
  //   const purchaseButton=(e,item)=>{
  //     console.log(item)
  //     var today = moment().format('l');

  //  var next=moment().add(30, 'days').calendar();  

  //     const data={
  //       user_id:localStorage.getItem('loginid'),
  //       subscrition_id:item.id,
  //       start_date:today,
  //       end_date:next
  //     }
  //     console.log(data)
  //     axios.post('https://api.kidzjoy.org/api/CreateMemberShipUser',data)

  //     .then(res=>{
  //       console.log(res)
  //       if(res.data.msg==='membership User Create'){
  //         console.log('create membership chart')
  //         setPaymentSuccessAlert(true)
  //         setStart_date(data.start_date)
  //         setend_date(data.end_date)
  //       }
  //     })
  //   }
  return (
    <Fragment>
      <div className="container-fluid subscription_page_container">
        <div className="row subscription_page_row">
          <div className="col-12 subscription_page_col">
            <img className="kidzoylogo" src={kidzoylogo} alt="logo" />
            <Link to='/' type="primary" className='signup_button' >
              Home
            </Link>
          </div>
        </div>
        {/* {paymentSuccessAlert===false ? */}
        <>
          {/* <div className="row plan_desc_row">
            <div className="col-12 plan_desc_column">
              <ul className="plan_desc_ul">
                <li className="plan_desc_li">
                  <h3 className="desc_heading text-white">
                    <i class="fas fa-check checkmark_icon"></i>
                    Choose the plan that’s right for you
                  </h3>
                </li>
                <li className="plan_desc_li">
                <h4 className="desc_subheading text-white">
                  <i class="fas fa-check checkmark_icon"></i>
                  Watch all you want. Ad-free.
                </h4>
              </li>
                <li className="plan_desc_li">
                  <h4 className="desc_subheading text-white">
                    <i class="fas fa-check checkmark_icon"></i>
                    Recommendations just for you.
                  </h4>
                </li>
                <li className="plan_desc_li">
                <h4 className="desc_subheading text-white">
                  <i class="fas fa-check checkmark_icon"></i>
                  Change or cancel your plan anytime.
                </h4>
              </li>
              </ul>
            </div>
          </div> */}
          <div className="row plan_row">
            <div className="col-12 plan_col">
              <div className="price_plan_heading_div">
                <h1 className="plandiv_heading text-white">Pricing Plan</h1>
              </div>
              <div className="plan_section">
                <table class="table table-borderless table-dark ">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      {planarray.map((item, index) => {
                        return <th scope="col">
                          <button className="plan_section_li">
                            <div className="rectange_div_section">
                              <div className="rectangle_div"></div>
                            </div>
                            <div className="rectangle_div_1 text-white">{item.name}</div>
                            <div className="price_div">
                              <h1 className="price_heading text-white">{item.price} INR</h1>
                            </div>
                          </button>
                        </th>
                      })}


                    </tr>
                  </thead>
                  <tbody>
                    <tr className="result_td_data">
                      <th className="th_type_heading" scope="row">
                        Validity
                      </th>
                      {planarray.map((item, index) => {
                        return (
                          <td className="desc_about_text">{item.days} Days</td>

                        )
                      })}

                    </tr>
                    <tr className="result_td_data">
                      <th className="th_type_heading" scope="row">
                        Discount
                      </th>
                      <td className="desc_about_text">100 INR</td>
                      <td className="desc_about_text">50 INR</td>
                      <td className="desc_about_text">100 INR</td>
                      <td className="desc_about_text">50 INR</td>
                    </tr>
                    {/* <tr className="result_td_data">
                      <th className="th_type_heading" scope="row">
                        Description
                      </th>
                      <td className="desc_about_text">365 Days Validity</td>
                      <td className="desc_about_text">365 Days Validity</td>
                      <td className="desc_about_text">365 Days Validity</td>
                      <td className="desc_about_text">365 Days Validity</td>
                    </tr> */}
                    {/* <tr className="result_td_data">
                    <th scope="row"></th>
                    {planList.map((item,index)=>{
                      return (
                        <td>
                        <button className="purchase_button" onClick={(e)=>purchaseButton(e,item)} >Purchase</button>
                      </td>
                      )
                    })}
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
        {/* :
          <SuccessPage   start_date ={start_date} end_date={end_date}
          />
          } */}

      </div>

    </Fragment>
  );
}
