import React, { useState } from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import axios from 'axios';
import { Button, notification, Space } from 'antd';

const ForgetPasswordValidation = (setForgetPasswordUi,setForgetPasswordUI) => {
    const [state, setState] = useState({
        email: '',

    })
    const [otpstate, setOtpstate] = useState({
        otp: ''
    });
    const [Resetpwd,setResetpwd]=useState(false)
    const [createpassword, setcreatepassword] = useState({
        password: '',
        cnfpassword:""
    })
    const [userEmail, setuserEmail] = useState({
        email: ''
    })
    const [userOTP, setuserOTP] = useState({
        otp: ''
    })
    const [createPasswordsucess, setcreatePasswordsucess] = useState(false)
    const [inputotpui, setinputotpui] = useState('none')
    const [forgetpasswordUi, setforgetpasswordUi] = useState('')
    const [verifyOtpUi, setVerifyOtpUi] = useState(false);
    const [emailID,setEmailID]=useState()

    const handlechange = (e) => {
        console.log(e.target.value)
        setState({
            ...state, [e.target.name]: e.target.value
        })
    }
    const verifyotphandlechange = (otp) => {
     //   console.log(e.target.value)
        setOtpstate({
           otp:otp
        })
    }
    const createPasswordHandlechange = (e) => {
        setcreatepassword({
            ...createpassword,
            [e.target.name]: e.target.value
        })
    }
    const cancelVerifyotpUiButton = () => {
        setForgetPasswordUi('none')
    }
    const otpSendButton = (data, e) => {
        // setVerifyOtpUi(true)
        // e.preventDefault();
        console.log(data)
        console.log('state')
        const userdata = {
            account: data.email,
        }
        console.log(userdata)
        setuserEmail({
            email: data.email,
        })
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findaccount`, userdata)
            .then(res => {
                if (res.data.msg === 'Detail Found Success') {
                    console.log(res)
                    setEmailID(res.data.result[0].email)
                    const otpdata={
                        email:res.data.result[0].email,
                        mobileno:res.data.result[0].mobileno
                      }
                    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/sendotpemailforgetPassword`, otpdata)
                    .then(response=>{
                        console.log(response)
                        if(response.data.mobile_otp==='otp send success' || response.data.email_msg==='sent msg'){
                            notification['success']({
                           message: 'OTP has been sent to your registered mobile number and email'
                         });
                         console.log(response);
                       }
                       else{
                           console.log(response)
                           notification['error']({
                           message: 'OTP Send Faild!',
                           description:
                             'Something Error! Please Try Again',
                         });
                       }
                    })
                     //setforgetpasswordUi('none')                    
                     setVerifyOtpUi(true)                }
                else{
                    notification['error']({
                        message: 'Email or MobileNo Not Found!'
                      });
                   console.log('Not Found')
                }
            })
            .catch(err => {
                console.log(err)
                notification['error']({
                    message: 'Something Error Try Again!',
                    description:
                      'Something Error! Please Try Again',
                  });
            })
    }
    const verifyotpbutton = (e) => {
        e.preventDefault();
        // setuserOTP({
        //     otp:otpstate.otp
        // })
      //  console.log(userEmail,otpstate)
        const userdata = {
            email: emailID,
            otp: otpstate.otp
        }
        console.log(userdata)
        const data = {
            email: userEmail.email,
            otp: otpstate.otp,
            password: createpassword.password
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findotp`, userdata)
            .then(res => {
                console.log(res)
                if (res.data.msg === 'otp  find') {
                   // console.log(res)
                    setforgetpasswordUi('none')
                    setinputotpui('')
                   // console.log(data)
                     
                }
                else{
                    notification['error']({
                        message: 'Invalid OTP',
                        description:
                          'Something Error! Please Try Again',
                      });
                }
            })
            .catch(err=>{
                console.log(err)
            })


    }
    const createpasswordButton = (e) => {
        e.preventDefault();
        console.log(createpassword)
        if(createpassword.password===createpassword.cnfpassword){
        const updatedata={
            account:userEmail.email,
            password:createpassword.password
        }
        console.log(updatedata)
        console.log(updatedata)
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/updateUserPassword`, updatedata)
        .then(result=>{
            if(result.data.msg==='Update Success'){
                console.log(result)
                notification['success']({
                    message: 'Password has been reset successfully',
                    // description:
                    //   'Something Error! Please Try Again',
                  });
                  setForgetPasswordUI(false)
            }
            else{
                console.log(result)
                notification['error']({
                    message: 'Something Error Try Again!',
                    description:
                      'Something Error! Please Try Again',
                  });
            }
        }) 
    }
    else{
        setResetpwd(true)
        console.log('Not matched')
    } 
    }

    return {
        state, handlechange, otpSendButton, verifyOtpUi, otpstate, verifyotphandlechange, verifyotpbutton,
        forgetpasswordUi, inputotpui, createpassword, createPasswordHandlechange, cancelVerifyotpUiButton,
        createpasswordButton, createPasswordsucess,Resetpwd
    }
}
export default ForgetPasswordValidation;