import React, { Fragment, useState, useEffect } from 'react';
import { Comment, Avatar, Form, Button, Tooltip, List, Input } from 'antd';

import moment from 'moment';
import commentServices from '../../../Services/CommentService';
import CommentList from './CommentList';
import './commentPage.scss';

const { TextArea } = Input;

const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Comment
            </Button>
        </Form.Item>
    </>
);

function CommentPage(props) {
    const [state, setState] = useState({
        // comments: [],
        submitting: false,
        value: ''
    });
    const [comments, setcomments] = useState([])
    const FetchCommentList = async () => {
        const apidata = {
            // content_id:props.content_id,
            category_id: props.category_id
        }
        const data = await commentServices.getCommentList(props.content_id, props.category_id)
        console.log(data)
        if (data.state === false) {
            if (data.response.data.msg === "List Find Success") {
                setcomments(data.response.data.result)
                // setState({
                //     ...state,
                //     comments: data.response.data.result,
                // });
            }
            else {
                console.log('something Error')
            }
        }
    }
    useEffect(() => {
        FetchCommentList()
    }, [])
    const addcomment = async (body) => {
        const data = await commentServices.addUserComment(body);
        console.log(data)
        FetchCommentList()
        setTimeout(() => {
            setState({
                ...state,
                submitting: false,
                value: '',

            });
        }, 1000);
    }
    const handleSubmit = () => {
        console.log(state)
        if (state.value === '') {
            console.log('empty')
        }
        else {
            const data = {
                user_id: localStorage.getItem('loginid'),
                comment_text: state.value,
                content_id: props.content_id,
                category_id: props.category_id
            }
            setState({
                // comments:state.comments,
                submitting: true,
            })

            addcomment(data)
        }
    };

    const handleChange = e => {
        setState({
            ...state,
            value: e.target.value,
        });
    };
    return (
        <Fragment>
            <div className="container">
                <div className='row'>
                    <div className='main_comment'>
                        {/* {state.comments.length > 0 && <CommentList comments={state.comments} />} */}
                        <Comment
                            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />}
                            content={
                                <Editor
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    submitting={state.submitting}
                                    value={state.value}
                                />
                            }
                        />
                        <CommentList comments={comments} category_id={props.category_id} FetchCommentList={FetchCommentList} />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CommentPage;
