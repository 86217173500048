import React, { Fragment, useEffect, useState } from 'react';
import imagetrendinglabel from '../../components/assets/trending/trending-label.png'
// import { GetSubCategoryListByUserId } from '../../../store/action/TalentHuntVideoList';
import Loder from './Loder';
 import MovieSubCategoryListCrousel from '../Pages/MovieTab/MovieSubCategoryListCrousel';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import { GetMovieListSubCategory } from '../../store/action/MovieTabAction';
import { connect } from 'react-redux';
import Plyr from 'plyr-react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton, FacebookIcon, WhatsappIcon
} from "react-share";
import 'plyr-react/dist/plyr.css';
// import './videoplayer.css';

const HomeCrouselAdminVideoPlayer = (props) => {
    console.log(props)
    const item = props.location.state.item;
    const [loder, setLoder] = useState(false)
    //  const contenttype = props.location.state.contenttype
     console.log(item)

    // useEffect(() => {
    //     //  window.location.reload();
    //     window.scrollTo(0, 0);
    //     window.onbeforeunload = function () {
    //         window.scrollTo(0, 0);
    //     }
    //     const data = {
    //         category_id: item.category_id
    //     }
    //     props.GetSubCategoryListByUserIdHandler(data)
    //     setTimeout(() => {
    //         setLoder(true)

    //     }, 2000)
    // }, [])
    useEffect(()=>{
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        // const data = {
        //     //   user_id:localStorage.getItem('loginid'),
        //     category_id: item.category_id
        // }
        // props.GetMovieListSubCategoryHandler(data)
    },[])
    return (
        <Fragment>
            {/* {loder === false ?
                <div className='container' style={{ padding: '15%' }}>
                    <div className='row'>
                        <div className='col-12' >
                            <Loder />
                        </div>
                    </div>
                </div>
                : */}
                <div className='container-fluid mt-3' >
                    <div className='row '>
                        <div className='col-12' >
                            <Plyr
                                source={{
                                    type: "video",
                                    sources: [
                                        {
                                            src: `http://admin.kidzjoy.org${item.video_path}`
                                        }
                                    ],
                                    quality:[
                                        { default: 576, options: [ 4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240, "default" ] }
                                    ]
                                }}
                            />

                        </div>
                    </div>
                    <div className='row videoplayerrowdiv'>
                        <div className='col-12 p-0'>
                            <div className=" text-uppercase mt-0">
                                <h4 className="trending-text big-title text-uppercase mt-0">{item.title}</h4>
                                <ul className="p-0 list-inline d-flex align-items-center movie-content listtextspecification">
                                </ul>
                                <div className="d-flex align-items-center text-white text-detail movietimingtext">
                                    <span className=" mr-2"><i className="fas fa-circle" style={{ background: "greenyellow", color: 'greenyellow', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.category_name}</span>
                                    <span className=" mr-2"><i className="fas fa-circle" style={{ background: "greenyellow", color: 'greenyellow', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.subcategory_name}</span>
                                </div>
                                <div className="d-flex align-items-center series mb-4 mt-4">
                                    <a href="#"><img src={imagetrendinglabel} className="img-fluid" alt="" /></a>
                                    <span className="text-gold ml-3" style={{ color: '#E6C75F', fontWeight: '600' }}>#2 in Series Today</span>
                                </div>
                                <p className="trending-dec w-100 mb-0 text-white" style={{ fontWeight: '600' }}>
                                    {item.videodesc}.</p>
                            </div>
                            <div className="block-social-info mt-3">
                                <ul className="list-inline p-0 m-0 music-play-lists d-flex">
                                    <li className='mr-2 '><span className='socialsymbel'><i className="ri-add-line "></i></span></li>
                                    <li className='mr-2'><span className='socialsymbel'><i className="ri-heart-fill "></i></span></li>
                                    <li className='mr-2'><span className='socialsymbel'><i className="ri-share-fill "></i></span></li>

                                </ul>
                            </div>
                           

                        </div>
                    </div>
                    {/* <div className='row borderdivvideoplayer' style={{ border: '', height: '1px' }}></div> */}
                    {/* <MovieSubCategoryListCrousel subcategoryList={props.movieListSubCategory} category_id={item.category_id} /> */}
                </div>
            
        </Fragment >
    )
}

const mapStateToProps = state => ({
    // movieListSubCategory: state.MovieTabReducer.getmovieListSubCategory,
    // movieListSubCategoryLoder: state.MovieTabReducer.getmovieListSubCategoryLoder,
  })
  
  const mapDispatchToProps = dispatch => ({
    // GetMovieListSubCategoryHandler: data => dispatch(GetMovieListSubCategory(data)),
  
  })

export default connect(mapStateToProps, mapDispatchToProps)(HomeCrouselAdminVideoPlayer)