import React, { Fragment, useEffect, useState } from 'react';
import sachinmovie from '../../../assets/HomeSlider/sachin-banner.jpeg';
import akkadbakkad from '../../../assets/video/akkad bakkad movie.mp4';

import slider1 from '../../../assets/HomeSlider/IMG-20180206-WA0022.jpg'
import slider2 from '../../../assets/HomeSlider/slider2.jpg'
import slider3 from '../../../assets/HomeSlider/slider3.jpg'
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';
import sachinMovie from '../../../assets/video/sample-video.mp4'
import { Link } from 'react-router-dom';
import { GetShortMovieListSubCategory } from '../../../../store/action/shortFilms';
import ShortMovieSubCategoryListCrousel from '../../../Pages/shortFilmsTab/ShortMovieSubCategoryListCrousel';
import BannerCrousel from '../../../resuable/bannerCrousel';
import Loder from '../../../resuable/Loder';
import { LOCAL_SERVER,PANCHANG_SERVER } from '../../../../store/config';

import axios from 'axios';



const ShortVideoPages = (props) => {
    window.scrollTo(0, 0);
    const [loder, setLoder] = useState(false);
    const [bannerVideo, setBannerVideo] = useState();
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(35% - 15px)',
        right: '40px',
        width: 30,
        height: 30,
        cursor: 'pointer',
        backgroundColor: 'none',
        border: 'none',
        background: 'none'
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        const catdata={
            category_id:'5'
          }
          axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getBannerList`,catdata).then(res=>{ 
              console.log(res)
            if(res.data.msg==="list find success"){
                console.log(res)
                setBannerVideo(res.data.result)
              }
        
              setLoder(true)
            })
            .catch(err=>{
                console.log(err)
            })
        const data = {
            category_id: 1
        }
        props.GetShortmovieListByCategoryHandler(data)
    }, [])
    return (
        <Fragment>
            {loder === true ?
                <>
                    <BannerCrousel bannerVideo={bannerVideo} /> :
                    <ShortMovieSubCategoryListCrousel subcategoryList={props.getShortmovieListSubCategory} />
                </>
                : 
                <Loder />
                }
           

        </Fragment>
    )
}
const mapStateToProps = state => ({
    getShortmovieListSubCategory: state.ShortMovieTabReducer.getShortmovieListSubCategory,
    getShortmovieListSubCategoryLoder: state.ShortMovieTabReducer.getShortmovieListSubCategoryLoder,
})

const mapDispatchToProps = dispatch => ({
    GetShortmovieListByCategoryHandler: data => dispatch(GetShortMovieListSubCategory(data)),

})
export default connect(mapStateToProps, mapDispatchToProps)(ShortVideoPages);
