import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import { DemoBlock } from 'demos'
import { Tabs } from 'antd-mobile';
import { useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom";

import './MobilemenuBar.scss';

// import { browserHistory } from 'react-router'


// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box p={3}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.any.isRequired,
//     value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: 'black',
//     },

// }));

export default function MobileViewMenuBar() {
    // const classes = useStyles();
    const location = useLocation();
    let history = useHistory();


    const [value, setValue] = React.useState(0);
    const [login, setLogin] = React.useState(localStorage.getItem('loginid'));


    const handleChange = (event, newValue) => {
        // this.props.history.push(value);

        setValue(newValue);
    };
        const handleTabClick =(key) => {
        //    window.location=`/${key}`
         history.push(`/${key}`)  
        } 
        
      

    return (
      
        <Tabs activeKey={location.pathname.slice(1)} onChange={handleTabClick}>
          <Tabs.TabPane title='Home' key='homepage'></Tabs.TabPane>

            
          <Tabs.TabPane title='Movies' key='Watch-movie'>
            
          </Tabs.TabPane>
          <Tabs.TabPane title='Series' key='web-series'>
            
          </Tabs.TabPane>
          <Tabs.TabPane title='Short Films' key='shortfilmpage'>
            
          </Tabs.TabPane>
          <Tabs.TabPane title="Student's Corner" key='studentcorner'>
            
          </Tabs.TabPane>
          <Tabs.TabPane title="Talent Hunt" key='talenthuntlist'>
            
            </Tabs.TabPane>
        </Tabs>
    );
}

