import React,{useState} from 'react';
import Loader from "react-loader";


export default function Loder() {
    const [loaded, setLoaded] = useState(false);

    return (
        <div className='container-fluid' style={{height:'350px'}}>
            <div className="App">
      <Loader
        loaded={loaded}
        lines={10}
        length={10}
        width={5}
        radius={10}
        corners={1}
        rotate={0}
        direction={1}
        color="yellowgreen"
        speed={1}
        trail={60}
        shadow={false}
        hwaccel={false}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="50%"
        scale={1.0}
        loadedClassName="loadedContent"
      />
    </div> 
        </div>
    )
}
