import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SeriesMultiItemListCrousel from './SeriesMultiListCrousel';
import {Link} from 'react-router-dom';


const SeriesSubCategoryListCrousel = (props) => {
    console.log(props.subcategoryList)
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const videoList = props.subcategoryList;
    console.log(videoList)

    useEffect(() => {

    }, [])

    return (
        <Fragment>
            {videoList === 'Error' || !videoList || videoList === 'Empty List' ? "Data Empty" : videoList.map((item, index) => {
                return (
                    <>
                        {/* <div className='container-fluid  mt-2 mb-3 crouselheader' style={{ paddingLeft: '40px', paddingRight: '' }}>
                            <h4 className='m-0 crouselheaderheader' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px' }}>{item.name}</h4>
                        </div> */}
                        <div className='container-fluid   mb-3 crouselheader d-flex' style={{marginTop:'60px',justifyContent:'space-between' }}>
                        <h4 className='m-0 crouselheaderheader' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px' }}>{item.subcategory_name}</h4>
                            <Link to={`/web-series/${item.subcategory_name}/${item.subcategory_id}`}  className='btn btn-primary'>View All</Link>
                        </div>

                        <SeriesMultiItemListCrousel
                            subcategory_id={item.subcategory_id} category_name={props.category_id}
                            MovieList={props.getSeriesDetailList}
                            movieListLoder={props.getSeriesDetailListLoder}
                        />
                    </>

                )
            })}

        </Fragment>
    )
}
const mapStateToProps = state => ({
    getSeriesDetailList: state.SeriesTabReducer.getSeriesDetailList,
    getSeriesDetailListLoder: state.SeriesTabReducer.getSeriesDetailListLoder,
})

const mapDispatchToProps = dispatch => ({
    // GetmovieListByUserIdHandler: data => dispatch(GetmovieListByUserId(data)),
    //  GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data))

})
export default connect(mapStateToProps, mapDispatchToProps)(SeriesSubCategoryListCrousel);
