import React, { Fragment, useEffect, useState } from 'react';
import imagetrendinglabel from '../../components/assets/trending/trending-label.png'
// import { GetSubCategoryListByUserId } from '../../../store/action/TalentHuntVideoList';
import Loder from './Loder';
import MovieSubCategoryListCrousel from '../Pages/MovieTab/MovieSubCategoryListCrousel';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import { GetMovieListSubCategory } from '../../store/action/MovieTabAction';
import WatchListPage from './watchListPage';
import { connect } from 'react-redux';
import xmlfile from '../assets/file.xml'
import addfile from '../assets/add.xml';
import Fluidplayers from './fluidplayer';

import ReactPlayer from 'react-player'
import PlyrComponent from 'plyr-react';
import CommentPage from '../Pages/CommentPage/CommentPage';
import LikedislikePage from './LikedislipkePage/LikedislikePage';
import { useParams,useHistory } from 'react-router';
import CategoryVideoListServices from '../../Services/categoryVideoListServices';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton, FacebookIcon, WhatsappIcon
} from "react-share";
import CategoryVideoListPage from './CategoryVideoList/CategoryVideoList';
import AdvertiseServices from '../../Services/AdvertisementService/Adservices';
import fluidPlayer from 'fluid-player';
import 'plyr-react/dist/plyr.css';
import "fluid-player/src/css/fluidplayer.css";
import './adminVideoPlayer.scss';
import './talentHuntPlayer.scss';


// "proxy": "https://api.kidzjoy.org/",

const TalentHuntVideoPlayer = (props) => {
    const history=useHistory()
    const params = useParams();
    console.log(params)
    const item = props.location.state.item;
    console.log(item)

    const [loder, setLoder] = useState(false);
    const [self, setSelf] = useState(React.createRef());
    const [CategoryVideoList, setCategoryVideoList] = useState([]);
    const [categoryType, setCategoryType] = useState('')
    const [at_began, setAtbegan] = useState({ id: '', type: "", path: "", time: '' });
    const [at_pause, setAtpause] = useState({ id: '', type: "", path: "", time: '' });
    const [at_time, setattime] = useState({ id: '', type: "", path: "", time: '' });
    const [addstatus, setaddstatus] = useState(false);
    const [startPLayer, setStartPlayer] = useState(false);
    const [add, setadd] = useState(false);
    const user = localStorage.getItem('loginid')
    // const [player,setPlayer]=useState(null)
    const contenttype = props.location.state.contenttype
    //const player=null
    const fetchCategoryvideolist = async () => {
        const category_id = item.category_id
        const fetchdata = await CategoryVideoListServices.getCategoryVideoList(category_id)
        if (fetchdata.state === false) {
            console.log(fetchdata)
            setCategoryVideoList(fetchdata.response.data.result)
            setCategoryType(fetchdata.response.data.type)
        }
    }
    const addvideoadCounter = async () => {
        const data = {
            "ad_id": '',
            "video_id": item.id,
            "user_id": user,
            "category_id": item.category_id,
            "subcategory_id": item.subcategory_id

        }
        const fetchdata = await AdvertiseServices.addVideoadCounter(data);
        console.log(fetchdata)
    }
    const trigger = async () => {
        const apidata = {
            video_id: item.id,
            category_id: item.category_id,
            sub_category_id: item.subcategory_id
            // video_id: "135",
            // category_id: "1",
            // sub_category_id: "3"
        }
        const fetchdata = await AdvertiseServices.getVideoadlist(apidata)
        console.log(fetchdata)
        if (fetchdata.state === false) {
            //  console.log(fetchdata)
            if (fetchdata.response.data.success === true) {
                // console.log(fetchdata)
                const relt = fetchdata.response.data.result
                // if (relt.at_begin.type !== undefined) {
                // console.log(fetchdata)
                // if (relt.at_begin.type !== "") {
                setAtbegan({ id: relt.at_begin.type ? relt.at_begin.id : '', type: relt.at_begin.type, path: relt.at_begin.file_path, time: relt.at_begin.at_particular_time })
                setAtpause({ id: relt.at_pause.type ? relt.at_pause.id : '', type: relt.at_pause.type ? relt.at_pause.type : "", path: relt.at_pause.file_path ? relt.at_pause.file_path : "", time: relt.at_begin.at_particular_time })
                setattime({ id: relt.at_particlar.type ? relt.at_particlar.id : '', type: relt.at_particlar.type ? relt.at_particlar.type : "", path: relt.at_particlar.file_path ? relt.at_particlar.file_path : "", time: relt.at_begin.at_particular_time })
                setaddstatus(true)
                const ad = []
                relt.at_begin.map((item, index) => {
                    if (item) {
                        ad.push(
                            {
                                adid:item.id,
                               filepath:`http://admin.kidzjoy.org${item.file_path}`,
                                roll: 'preRoll',
                                // vastTag:`${xmlfile}`,
                                vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                // adText: 'Advertising supports us directly'
                            }
                        )
                    }
                })
                relt.at_particlar.map((item, index) => {
                    if (item) {
                        ad.push(
                            {
                                adid:item.id,
                               filepath:`http://admin.kidzjoy.org${item.file_path}`,
                                roll: 'midRoll',
                                //  vastTag:`${xmlfile}`,
                                vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                timer: parseInt(item.at_particular_time)
                            }
                        )
                    }

                })
                relt.at_pause.map((item, index) => {
                    if (item) {
                        ad.push(

                            {
                                adid:item.id,
                               filepath:`http://admin.kidzjoy.org${item.file_path}`,
                                "roll": "onPauseRoll",
                                //  vastTag:`${addfile}`,
                                vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                "adText": "",
                            }
                        )
                    }

                })
                console.log(ad)
                ad.push({
                    roll: "preRoll",
            vastTag: "http://localhost:8080/api/getxmlfile/thsyu"
                })
                setadd(ad)
                setStartPlayer(true)
            }
        }
    }
    const apicall = () => {
        console.log(at_began)
    }
    const categoryLickfunc=()=>{
        history.push('/talenthuntlist')
    }
    useEffect(() => {
        trigger()
        fetchCategoryvideolist()
    }, [])
    return (
        <Fragment>
            {startPLayer === true ?
                <div className='container-fluid'>
                    <div className='row '>
                        <div className='col-12 mx-0' >
                            <div className='admin_kidzjoy_player'>
                            <Fluidplayers detail={item}  name='user' image={item.image_url} add={add} item={item.video_url} />
                            </div>
                        </div>
                    </div>
                    <div className='row videoplayerrowdiv mt-5'>
                        <div className='col-12 p-0'>
                            <nav aria-label="bg-none" className='mt-5' style={{ color: '#fff' }}>
                                <ul className="d-flex p-0 space_lines" style={{ listStyle: 'none' }}>
                                    <li  style={{cursor:'pointer'}}>Home
                                        <i className="fas fa-angle-double-right"></i>
                                    </li>
                                    <li  style={{cursor:'pointer'}} onClick={categoryLickfunc}>Talent Hunt
                                        <i className="fas fa-angle-double-right"></i>
                                    </li>
                                    <li className="" aria-current="page" style={{cursor:'pointer'}}>{item.title}</li>
                                </ul>
                            </nav>
                            <div className=" text-uppercase mt-0">
                                <h4 className="trending-text big-title text-uppercase mt-0" style={{ float: 'left' }}>{item.title}</h4>
                                <br></br>
                                <div className="block-social-info">
                                    {item.category_id === '2' || item.category_id === '1' ?
                                        <ul className="list-inline p-0 m-0 music-play-lists d-flex justify-content-end">

                                            <LikedislikePage detail={item} />
                                            {/* < WatchListPage item={item} /> */}
                                            {/* <li className='mr-3 '><span className='socialsymbel'><i className="ri-add-line "></i></span></li> */}

                                            {/* <li className='mr-3'><span className='socialsymbel'><i className="ri-heart-fill "></i></span></li> */}
                                            {/* <li><span className='socialsymbel'><i className="ri-share-fill "></i></span></li> */}
                                        </ul>
                                        : ""}

                                </div>
                                <ul className="p-0 list-inline d-flex align-items-center movie-content listtextspecification">


                                </ul>
                                {/* <div className="d-flex align-items-center text-white text-detail movietimingtext">
                                    <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.category_name} Movie</span>
                                    <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.subcategory_name} Hindi</span>
                                </div> */}
                                {/* <div className="d-flex align-items-center series mb-4 mt-4">
                                    <a href="#"><img src={imagetrendinglabel} className="img-fluid" alt="" /></a>
                                    <span className="text-gold ml-3" style={{ color: '#E6C75F', fontWeight: '600' }}>#2 in Series Today</span>
                                </div> */}
                                <p className="trending-dec w-100 mb-0 text-white mt-4" style={{ fontWeight: '400', textTransform: 'capitalize', fontSize: '15px' }}>
                                    {item.desc}
                                </p>
                                {/* <p className='w-100 mb-0 mt-3' style={{ color: '#00dcd4', fontSize: '16px', fontWeight: '500' }}>SEE MORE</p> */}
                            </div>


                        </div>
                    </div>
                    <div className='row videoplayerrowdiv mt-5'>
                        <CommentPage content_id={params.id} category_id={item.category_id} />
                    </div>
                    <div className='row videoplayerrowdiv mt-5'>
                        <h2 className=" big-title text-uppercase mt-0">RECOMMENDED</h2>
                    </div>
                    <div className='row'>
                        <CategoryVideoListPage categoryType={categoryType} VideoList={CategoryVideoList} />
                    </div>
                    {/* <MovieSubCategoryListCrousel subcategoryList={props.movieListSubCategory} category_id={item.category_id} /> */}
                </div>
                : ""}

        </Fragment >
    )
}

const mapStateToProps = state => ({
    movieListSubCategory: state.MovieTabReducer.getmovieListSubCategory,
    movieListSubCategoryLoder: state.MovieTabReducer.getmovieListSubCategoryLoder,
})

const mapDispatchToProps = dispatch => ({
    GetMovieListSubCategoryHandler: data => dispatch(GetMovieListSubCategory(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TalentHuntVideoPlayer)