import React,{Fragment} from 'react'
import './StudentCornerDetailPage.scss';

function StudentCornerDetailPage(props) {
    const {item}=props

    return (
        <Fragment>
                <div className='container-fluid '>
                <div className='row'>
                    <div className='col-12 p-0'>
                        <div class="card-details student_corner_carddetail" >
                            <h3 class="text-overflow card-header pl-0 " style={{color:'#fff',fontWeight:'400'}}>{item.institution.substring(0, 20) + "..."}</h3>
                                <div class="text-overflow card-subheader text-white" >
                                    {item.teacher_name.substring(0, 20) + "..."}
                                </div>
                        </div>
                    </div>
                </div>
            </div> 
        </Fragment>
    )
}

export default StudentCornerDetailPage
