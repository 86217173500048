import React, { Fragment, useRef, useEffect, useState } from "react";
import HomeScreenimage from "../../assets/HomeScreenimage/homescreen.jpg";
import kidzoylogo from "../../assets/HeaderLogo/KidzjoyTextLogo.png";
import LoginSignUpPage from "../../../components/LoginSignupPage/Index.js";
import Loader from "react-loader";
import Login from "../../../components/LoginSignupPage/LoginPage/Login.js";
import { Input, Space } from 'antd';
import { Link } from 'react-router-dom';
import SignUpPage from '../../LoginSignupPage/SignupPage/SignUpPage';
import Signup from '../../LoginSignupPage/SignupPage/signup';
import { Modal, Button, Typography } from 'antd';
import ListGroup from 'react-bootstrap/ListGroup';
import { useSelector, useDispatch } from 'react-redux';
import { SaveUserType } from '../../../store/Auth/AuthAction';
import AuthServices from '../../../Services/authServices';
import PlyrComponent from 'plyr-react';

import axios from 'axios';
import "./HomeScreen.scss";

const { Title, Text } = Typography;
export default function HomeScreen() {
  const ref = useRef()
  const dispatch = useDispatch()
  const usertype = useSelector((state) => state.AuthRudeser.user_type);
  const usertypeid = useSelector((state) => state.AuthRudeser.user_type_id);

  const { Search } = Input;
  const [buttonLoder, setbuttonLoder] = useState(false);
  const [showSigninform, setshowSigninform] = useState(false);
  const [showSignIn, setshowSignIn] = useState(false);
  const [showSignup, setshowSignup] = useState(false);
  const [UserEmail, setUserEmail] = useState('');
  const [SearchEmail, setSearchEmail] = useState('');
  const [emailerror, setemailerror] = useState('');
  const [opensignupform, setopensignupform] = useState(false);
  const [schoolsignupshow, setSchoolsignupshow] = useState(false);
  const [studentsignupshow, setStudentsignupshow] = useState(false);
  const [normalusersignupshow, setNormalusersignupshow] = useState(false);
  const [promotersignupshow, setPromotersignupshow] = useState(false);
  const [usertypeList, setUserTypeList] = useState([])
  const [modal2Visible, setmodal2Visible] = useState(false);
  const [promovideoLink,setPromovideoLink]=useState()
  const videomodelCancel = () => {
    // console.log(ref.current.plyr)
    ref.current.plyr.pause();
    setModal2Visible(false)
    signupbuttonClick(true)
  }
  const setModal2Visible = (modal2Visible) => {
    setmodal2Visible(modal2Visible)
  }
  const videomodelonbutton=()=>{
    setModal2Visible(true) 
    signupbuttonClick(false)
  }
  const fetchlist = async () => {
    const callapi = await AuthServices.getusertypelist()
    console.log(callapi)
    if (callapi.state === false) {
      if (callapi.response.data.success === true) {
        setUserTypeList(callapi.response.data.result)
        setPromovideoLink(callapi.response.data.videolonk)

      }
    }
  }
  useEffect(() => {
    fetchlist()
  }, [])

  const onSearch = value => {
    if (value === '') {
      setemailerror('Please Enter Email Id')
    }
    else {
      setemailerror('')
      console.log(value)
      const data = {
        email: value
      }
      axios.post('https://api.kidzjoy.org/api/find_user_email', data)
        .then(res => {
          if (res.data.msg === 'Valid User') {
            setUserEmail(value)
            console.log('valid user')
            setshowSignIn(true)
          }
          else {
            setUserEmail(value)
            setshowSignup(true)
            console.log('unvalid user')
          }
        })
    }
  };
  const getstartedButtonClick = () => {
    console.log(true);
    window.location = "/signin";
  };
  const signupbuttonClick = (modal2Visible) => {
    setshowSignup(modal2Visible)
  };
  const signinbuttonclick = (modal2Visible) => {
    setshowSignIn(modal2Visible)
  };
  const loginuserbutton = (type, id) => {
    console.log(type, id)
    setopensignupform(true)
    const usertypedata = {
      type: type,
      type_id: id
    }
    dispatch(SaveUserType(usertypedata))

    if (id === '4') {
      setSchoolsignupshow(true)
    }
    else if (id === '3') {
      setStudentsignupshow(true)
    }
    else if (id === '1') {
      setNormalusersignupshow(true)
    }
    else if (id === '2') {
      setPromotersignupshow(true)
    }
  }
  const backuserlist = () => {
    setopensignupform(false)
    setSchoolsignupshow(false)
    setStudentsignupshow(false)
    setNormalusersignupshow(false)
    setPromotersignupshow(false)
  }
  return (
    <Fragment>
      <div className="container-fluid home_screen_container">
        <div className="row home_screen_row">
          <div className="col-12 home_screen_column">
            <img
              className="home_screen_background_image"
              src={HomeScreenimage}
              alt="Snow"
            />

            <>
              <div className="language_select_div">
                {/* <Link to='/subscription-pricing' type="primary" className='ant-btn ant-btn-primary signup_button'>
                  Registration Fee
                </Link> */}
                <Button type="primary" className='signup_button' onClick={() => signupbuttonClick(true)}>
                  Sign Up
                </Button>
                <Button type="primary" className='signin_button' onClick={() => signinbuttonclick(true)}>
                  Sign In
                </Button>
              </div>
              <div className="screen_detaildiv">
                <div className="text-center">
                  <img className="kidzoylogo" src={kidzoylogo} alt="logo" />
                </div>
                {/* <Title level={1} className='text-white text-center'>
                Unlimited movies, TV shows and more.
                </Title>
                <Title level={3} className='text-white text-center'>
                Watch anywhere. Cancel anytime.
                </Title>
                <Title level={4} className='text-white text-center'>
                Ready to watch? Enter your email to create or restart your
                  membership.
                 </Title> */}

                <div className="detail_text mt-5">
                  ENTERTAINMENT, EDUCATION AND MORALITY
                </div>
                <div className="sub_detail_text_">
                  Everything on one platform
                </div>
                <div className="memebership_text">
                  Ready to watch? Enter your email to create or restart your
                  membership.
                </div>
                <div className="email_input_div">
                  <form
                    class="form-inline"
                    style={{ justifyContent: "center" }}
                  >
                    <div class="serch_input_form_group" >
                      <Search
                        placeholder="Enter Your Email"
                        enterButton="Get Started >"
                        size="middle"
                        //  name='SearchEmail'
                        // value={SearchEmail}

                        onSearch={onSearch}
                      />
                      <Text className='text-danger'>{emailerror}</Text>
                    </div>
                  </form>
                </div>
              </div>
            </>
          </div>
        </div>
        <Modal
          destroyOnClose={true}
          centered
          visible={showSignIn}
          onOk={() => signinbuttonclick(false)}
          onCancel={() => signinbuttonclick(false)}
        >
          {/* <ListGroup>
            <ListGroup.Item>No style</ListGroup.Item>
            <ListGroup.Item variant="primary">Normal User</ListGroup.Item>
            <ListGroup.Item variant="secondary">Secondary</ListGroup.Item>
            <ListGroup.Item variant="success">Success</ListGroup.Item>
            <ListGroup.Item variant="danger">Danger</ListGroup.Item>

          </ListGroup> */}
          <Login UserEmail={UserEmail} />
        </Modal>
        <Modal className='signupmodel'
          destroyOnClose={true}
          centered
          visible={showSignup}
          onOk={() => signupbuttonClick(false)}
          onCancel={() => signupbuttonClick(false)}
        >{opensignupform === false ?
          <>
            <h2 className='text-white text-center create_account_header_text'>Create Account as</h2>
            <ListGroup className='userloginlistgroup'>
              {usertypeList.map((item, index) => {

                return (
                  <>
                    <ListGroup.Item key={index} variant="primary" onClick={() => loginuserbutton(item.type_name, item.property_id)}>{item.type_name} ( FEES RS.{item.packageprice} )</ListGroup.Item>
                    {item.property_id === "2" ?
                      <p className="text-white">CLICK TO KNOW  <span className="text-danger"style={{cursor:'pointer'}} onClick={videomodelonbutton}>THE BENEFITS OF JOINING AS PROMOTER</span>
                      </p>
                      : ""}
                  </>
                )
              })}
              {/* <ListGroup.Item variant="primary" onClick={()=>loginuserbutton('normal user')}>Normal User</ListGroup.Item>
            <ListGroup.Item variant="secondary" onClick={()=>loginuserbutton('promo user')}>Promoter User</ListGroup.Item>
            <ListGroup.Item variant="success" onClick={()=>loginuserbutton('school')}>School</ListGroup.Item>
            <ListGroup.Item variant="danger" onClick={()=>loginuserbutton('student')}>Student</ListGroup.Item> */}

            </ListGroup>
          </>
          :
          <Signup UserEmail={UserEmail} normalusersignupshow={normalusersignupshow} schoolsignupshow={schoolsignupshow} studentsignupshow={studentsignupshow}
            promotersignupshow={promotersignupshow}
            backuserlist={backuserlist} />
          }
        </Modal>
      </div>
      <Modal className="model_pro_palyer"
        centered
        // title="Vertically centered modal dialog"
        visible={modal2Visible}
        onOk={() => setModal2Visible(false)}
        onCancel={videomodelCancel}
        footer={[
          <button className='btn btn-danger mr-2' onClick={videomodelCancel}>
            Cancel
          </button>,

          <Link
            key="link"
            to="/uploadPage"
            className='btn btn-primary'
          //   loading={loading}
          //   onClick={this.handleOk}
          >
            Upload Video
          </Link>,
        ]}


      >
        <PlyrComponent ref={ref}
          source={{
            type: "video",
            sources: [
              {
                src: `${promovideoLink}`,
                provider: "youtube"
              }
            ]
            // quality: [
            //   { default: 576, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240, "default"] }
            // ]
          }}
        />
      </Modal>
    </Fragment>
  );
}
