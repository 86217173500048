import React, { Fragment, useState, useEffect } from 'react';
import { Tabs, Button, Divider, Checkbox, Menu, Dropdown, Drawer, Modal, Popover } from 'antd';
import { Link } from 'react-router-dom';
import watchlist from '../../assets/HeaderLogo/watchList.png';
import musicimage from '../../assets/HeaderLogo/music.png';
import uploadvideopng from '../../assets/menu/Videodownload.png';
import { SearchOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import logo from '../../assets/HeaderLogo/Kidzjoy.png'
import Textlogo from '../../assets/HeaderLogo/KidzjoyTextLogo.png';
import MobileViewMenuBar from './MobileViewMenuBar';
import audiomusic from '../../assets/HeaderLogo/audiomusic.png';
import videomusic from '../../assets/HeaderLogo/videomusic.png';
import AutoSearchInput from '../../resuable/AutoSearchInput';
import { useSelector, useDispatch } from 'react-redux';
import { UserWalletAmountAction } from '../../../store/UserWallet/UserWalletAction';
import axios from 'axios';
import api from "../../../api/index";
import './HeaderNav.scss';

const { TabPane } = Tabs;
function HeaderNav() {
  const dispatch = useDispatch()
  const walletamount = useSelector((state) => state.UserWalletRudeser.userWalletamount);
  const pathname = window.location.pathname;
  const [visible, setVisible] = useState(false);
  const [musicmodelVisible, setmusicmodelVisible] = useState(false)
  const [movieList, setmovieList] = useState();
  const [mobileviewnavbar, setMobileviewnavbar] = useState('none');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const [serchInputVisible, setserchInputVisible] = useState(false);
  const user_types = localStorage.getItem('user_type')

  const inputsearchhide = () => {
    setserchInputVisible(false)
  };

  const inputsearchhandleVisibleChange = visible => {
    setserchInputVisible(true)
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const fetchwalletamount = () => {
    const userid = localStorage.getItem('loginid')
    api.get(`finduserwallet/${userid}`)
      // axios.get(`http://localhost:8080/api/finduserwallet/${userid}`)
      .then(res => {
        console.log(res)
        if (res.status === 200) {
          dispatch(UserWalletAmountAction(res.data.result.wallet_amount))
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    if(user_types==="2" || user_types==="4"){
      fetchwalletamount()
    }
    
  }, [])

  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setmusicmodelVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setmusicmodelVisible(false);
  };


  const toggleButton = () => {
    if (mobileviewnavbar === 'none') {
      setMobileviewnavbar('block')
    }
    else {
      setMobileviewnavbar('none')

    }
  }
  const logoutbutton = () => {
    // setMobileviewnavbar('none')
    localStorage.removeItem('loginid');
    localStorage.removeItem('user_token');

    setVisible(false)
    window.location = '/'
  }
  const menu = (
    <Menu className="profile_show">
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/loginprofile'>
          Manage Profile
        </Link>
      </Menu.Item>

      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/dashboardContent'>
          Dashboard
        </Link>
      </Menu.Item>
      {user_types==="1" || user_types==="3" ?"":
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/earninghistory'>
          My Bonus History
        </Link>
      </Menu.Item>
}
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/watchlist'>
          My Watch list
        </Link>
      </Menu.Item>
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/uploadPage'>
          Upload Your Video
        </Link>
      </Menu.Item>
      <Menu.Item icon={<i className="ri-file-user-line"></i>}>
        <Link to='/uplodmovieList'>
          Manage Your Video
        </Link>
      </Menu.Item>
      <Menu.Item icon={<i className="ri-logout-circle-line"></i>}>
        <Link onClick={logoutbutton}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setmusicmodelVisible(true);
    setVisible(false);
  };
  const onClose = () => {
    setVisible(false);

  };



  const OperationsSlot = {
    left:
      <Tabs defaultActiveKey="1" >
        <TabPane tab={<Link to='/HomePage' className="textlogobutton" >
          <img src={Textlogo} width='170px' height='100%' />
        </Link>} key="1">
        </TabPane>
      </Tabs>
  };


  const options = ['left', 'right'];

  return (
    <Fragment>
      <div className='container-fluid header_nav_container'>
        <div className='row header_nav_row'>
          <div className='col-12 header_nav_col'>
            <Tabs className='header_show' defaultActiveKey="1" size={`large`} style={{ height: '100%' }} tabBarExtraContent={OperationsSlot}>
              <TabPane tab={<Link className={pathname === '/HomePage' ? `activelink` : `unactive`} to='/HomePage'>Home</Link>} key="1">
              </TabPane>
              <TabPane tab={<Link className={pathname === '/Watch-movie' ? `activelink` : `unactive`} to='/Watch-movie'>Movies</Link>} key="2">
              </TabPane>
              <TabPane tab={<Link className={pathname === '/web-series' ? `activelink` : `unactive`} to='/web-series'>Series</Link>} key="3">
              </TabPane>
              <TabPane tab={<Link className={pathname === '/shortfilmpage' ? `activelink` : `unactive`} to='/shortfilmpage'>Short Films</Link>} key="4">
              </TabPane>
              <TabPane tab={<Link className={pathname === '/talenthuntlist' ? `activelink` : `unactive`} to='/talenthuntlist'>Talent Hunt</Link>} key="5">
              </TabPane>
              <TabPane tab={<Link className={pathname === '/studentcorner' ? `activelink` : `unactive`} to='/studentcorner'>Student's Corner</Link>} key="6">
              </TabPane>
              {/* <TabPane tab={<img src={watchlist} width='35px' height='35px' />} size={`small`} icon={<img src={watchlist} width='40px' height='40px' />} size={`small`} key="7">
              </TabPane> */}
              <TabPane tab={<Link className='' to='/watchlist'><i className="fab fa-gratipay" data-toggle="tooltip" data-placement="top" title="My Watchlist" style={{ fontSize: '40px', color: '#00dcd4' }}></i></Link>} key="7">
              </TabPane>
              <TabPane tab={<Button type="primary" data-toggle="tooltip" data-placement="top" title="Music" onClick={showModal} icon={<img src={musicimage} width='35px' height='30px' />} size={`small`} ></Button>} key="8">
              </TabPane>
              <TabPane tab={<Link to='/uploadPage' size={`small`} >
                <img data-toggle="tooltip" data-placement="top" title="Upload Your Video" style={{ borderRadius: '' }} src={uploadvideopng} width='35px' height='35px' />
              </Link>} key="9">
              </TabPane>
              {user_types === "1" || user_types === "3" ? "" :
                <TabPane tab={<Link size={`small`} >
                  {/* <img data-toggle="tooltip" data-placement="top" title="Upload Your Video" style={{ borderRadius: '' }} src={uploadvideopng} width='35px' height='35px' /> */}
                  <button type="button" class="btn btn-secondary wallet_header_button" data-toggle="tooltip" data-placement="top" title="Wallet">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-wallet" viewBox="0 0 16 16" style={{ color: 'red' }}>
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                    </svg> <span className='ml-2' style={{ fontSize: '20px' }}>{walletamount}</span>
                  </button>
                </Link>} key="9">
                </TabPane>
              }
              <TabPane tab={<Dropdown overlay={menu} placement="bottomCenter" key='11'>
                <img src="https://www.w3schools.com/howto/img_avatar.png" className='rounded-circle avatar' width='40px' height='40px' alt="Avatar" />
              </Dropdown>} >
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      <div className='scrollnavbarmobileview '>
        <Tabs defaultActiveKey="1" className='mobile_view_header'>

          <TabPane tab={<Button type="primary" size={`large`} onClick={showDrawer}>
            {/* <MenuUnfoldOutlined /> */}
            <i class="fas fa-bars" style={{ fontSize: '28px' }}></i>
          </Button>} key="1">
          </TabPane>
          <TabPane tab={<Link to='/HomePage' className="textlogobutton" >
            <img src={Textlogo} width='140px' height='100%' />
          </Link>} key="2">
          </TabPane>
          <TabPane
          >
          </TabPane>
        </Tabs>
        < MobileViewMenuBar />
      </div>
      <Drawer className='side_nav_drawer'
        title={<img src={logo} width='100px' height='80px' />}
        placement={`left`}
        closable={false}
        onClose={onClose}
        visible={visible}
      // key={placement}
      >

        <Tabs tabPosition={`left`} className='side_navbr_tabs'>
          {user_types==="1" || user_types==="3" ? "":
          <TabPane className='wallet_header_button_sidebar_tabpane' tab={<Link size={`small`} >
            {/* <img data-toggle="tooltip" data-placement="top" title="Upload Your Video" style={{ borderRadius: '' }} src={uploadvideopng} width='35px' height='35px' /> */}
            <button type="button" class="btn btn-secondary wallet_header_button_sidebar" data-toggle="tooltip" data-placement="top" title="Wallet">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-wallet" viewBox="0 0 16 16" style={{ color: 'red' }}>
                <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
              </svg> <span className='ml-2' style={{ fontSize: '20px' }}>{walletamount}</span>
            </button>
          </Link>} key="2">
          </TabPane>
          }
          <TabPane tab={<Link className='text-white' to='/loginprofile' onClick={() => { setVisible(false) }} size={`small`}>
            {/* <img src={uploadvideopng} width='30px' height='30px' />&nbsp;&nbsp;&nbsp; */}
            Manage Profile
          </Link>
          } key="3">
          </TabPane>
          <TabPane tab={<span>
            <img src={watchlist} width='30px' height='30px' />&nbsp;&nbsp;&nbsp;
            watchList
          </span>
          } key="1">
          </TabPane>
          {/* <TabPane tab={<Button type="primary" onClick={showModal} icon={<img src={musicimage} width='35px' height='35px' />} size={`small`} ></Button>} key="8">
            </TabPane> */}
          <TabPane tab={<span onClick={showModal}>
            <img src={musicimage} width='30px' height='30px' />&nbsp;&nbsp;&nbsp;
            Music
          </span>
          } key="2">
          </TabPane>

          <TabPane tab={<Link className='text-white' to='/uploadPage' onClick={() => { setVisible(false) }} size={`small`}>
            <img src={uploadvideopng} width='30px' height='30px' />&nbsp;&nbsp;&nbsp;
            Upload Video
          </Link>
          } key="3">
          </TabPane>
          <TabPane tab={<Link className='text-white' to='/uplodmovieList' onClick={() => { setVisible(false) }} size={`small`}>
            {/* <img src={uploadvideopng} width='30px' height='30px' />&nbsp;&nbsp;&nbsp; */}
            Manage Your Video
          </Link>
          } key="3">
          </TabPane>
          <TabPane tab={<span className='text-white' onClick={logoutbutton} size={`small`}>
            {/* <img src={uploadvideopng} width='30px' height='30px' />&nbsp;&nbsp;&nbsp; */}
            Logout
          </span>
          } key="3">
          </TabPane>
        </Tabs>

      </Drawer>
      <Modal
        title="Choose Your Category"
        visible={musicmodelVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <ul className='' style={{ display: 'flex', justifyContent: 'space-around' }}>
          <li className='d-block text-center'>
            <Link to='/music-audio' onClick={handleCancel}>
              <img className='audiomusicimage' src={audiomusic} alt='' /><br></br>
              <span>Audio Music</span>
            </Link>

          </li>
          <li className='d-block text-center'>
            <Link to='/music-video' onClick={handleCancel}>
              <img className='audiomusicimage' src={videomusic} alt='' /><br></br>
              <span>Video Music</span>
            </Link>

          </li>

        </ul>
      </Modal>
    </Fragment>
  )
}

export default HeaderNav;
