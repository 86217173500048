import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
 import ShortMovieMultiItemListCrousel from './ShortFilmsMultiListCrousel';



const ShortMovieSubCategoryListCrousel = (props) => {
    console.log(props.subcategoryList)
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const videoList = props.subcategoryList;
    console.log(videoList)

    return (
        <Fragment>
             {videoList.length>0 ?  videoList.map((item, index) => {
                return (
                    
                    <>
                    {item.movielist.length>0 ? 
                    <>
                        <div className='container-fluid   mb-3 crouselheader d-flex' style={{marginTop:'60px', justifyContent:'space-between' }}>
                            <h4 className='m-0 crouselheaderheader text-uppercase' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px' }}>{item.name}</h4>
                            <Link to={`/Watch-short-movie/${item.name}/${item.id}`} className='btn btn-primary'>View All</Link>
                        </div>
                        <ShortMovieMultiItemListCrousel moviList={item.movielist}
                      />

                     </>
                     :"Loading..."
                        }
                    </>
                    
                )
            })
        :"Loading...."
        }
        </Fragment>
    )
}
const mapStateToProps = state => ({
    getShortMovieDetailList: state.ShortMovieTabReducer.getShortMovieDetailList,
    getShortMovieDetailListLoder: state.ShortMovieTabReducer.getShortMovieDetailListLoder,

})

const mapDispatchToProps = dispatch => ({
    // GetmovieListByUserIdHandler: data => dispatch(GetmovieListByUserId(data)),
    //  GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data))

})
export default connect(mapStateToProps, mapDispatchToProps)(ShortMovieSubCategoryListCrousel);
