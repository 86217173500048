// Login SignUp constants 
export const FIND_LOGIN_USER = 'FIND_LOGIN_USER';

//signup user 
export const CREATE_USER_ACCOUNT = 'CREATE_USER_ACCOUNT';
 
// category upload video list     
export const GET_UPLOAD_VIDEO_BY_CATEGORY = 'GET_UPLOAD_VIDEO_BY_CATEGORY';


export const GET_USER_SUBCATEGORY_LIST = 'GET_USER_SUBCATEGORY_LIST';
export const GET_USER_MOVIE_LIST = 'GET_USER_MOVIE_LIST';
export const GET_USER_MOVIE_LIST_CONTENT_TYPE = 'GET_USER_MOVIE_LIST_CONTENT_TYPE';


// Movie Tab Constants
export const GET_MOVIE_LIST = 'GET_MOVIE_LIST';
export const GET_MOVIE_DETAIL_LIST = 'GET_MOVIE_DETAIL_LIST';

// Series tab  
export const GET_SERIES_LIST = 'GET_SERIES_LIST';
export const GET_SERIES_DETAIL_LIST = 'GET_SERIES_DETAIL_LIST';
export const GET_SHOW_DETAIL_BY_SHOWID = 'GET_SHOW_DETAIL_BY_SHOWID';

// Short Films  
export const GET_SHORT_MOVIE_LIST = 'GET_SHORT_MOVIE_LIST';
export const GET_SHORT_MOVIE_DETAIL_LIST = 'GET_SHORT_MOVIE_DETAIL_LIST';


// Student Corner 

export const GET_STUDENTCORNER_MOVIE_LIST = 'GET_STUDENTCORNER_MOVIE_LIST';
export const GET_STUDENTCORNER_MOVIE_DETAIL_LIST = 'GET_STUDENTCORNER_MOVIE_DETAIL_LIST';

// watchList 
export const LOCAL_WATCHLIST_LENGTH = 'LOCAL_WATCHLIST_LENGTH';
