import React, { Fragment, useEffect,useState } from 'react';
// import notifyimg1 from '../../assets/notify/thumb-1.jpg'
// import notifyimg2 from '../../assets/notify/thumb-2.jpg'
// import notifyimg3 from '../../assets/notify/thumb-3.jpg'
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import sachinMovie from '../../../assets/video/sample-video.mp4'
import { GetSeriesListSubCategory } from '../../../../store/action/SeriesTabAction';
import SeriesSubCategoryListCrousel from '../../../Pages/SeriesPage/SeriesSubCategoryListCrousel';
import MovieSubCategoryListCrousel from '../../../Pages/MovieTab/MovieSubCategoryListCrousel';
import BannerCrousel from '../../../resuable/bannerCrousel';
import axios from 'axios';
import Loder from '../../../resuable/Loder';
import SeriesBannerCrousel from './seriesBannerCrousel';



const SeriesPages = (props) => {
  window.scrollTo(0, 0);

  const [loder, setLoder] = useState(false);
  const [bannerVideo, setBannerVideo] = useState();
  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(35% - 15px)',
    right: '40px',
    width: 30,
    height: 30,
    cursor: 'pointer',
    backgroundColor: 'none',
    border: 'none',
    background: 'none'
  };

useEffect(() => {
  window.scrollTo(0, 0);
  const catdata={
    category_id:'4'
  }
  axios.post('https://api.kidzjoy.org/api/getBannerList',catdata).then(res=>{ 
    if(res.data.msg==="list find success"){
        console.log(res)
        setBannerVideo(res.data.result)

      }

      setLoder(true)
    })
    .catch(err=>{
        console.log(err)
    })

    props.GetSeriesListSubCategoryHandler()
}, [])
  return (
    <Fragment>
       {loder === true ?
        <>
          <BannerCrousel bannerVideo={bannerVideo} />
          <SeriesSubCategoryListCrousel subcategoryList={props.getSeriesListSubCategory} />
        </>
        : 
          <Loder />
        }
    </Fragment>
  )
}
const mapStateToProps = state => ({
    getSeriesListSubCategory: state.SeriesTabReducer.getSeriesListSubCategory,
    getSeriesListSubCategoryLoder: state.SeriesTabReducer.getSeriesListSubCategoryLoder,
})

const mapDispatchToProps = dispatch => ({
    GetSeriesListSubCategoryHandler: data => dispatch(GetSeriesListSubCategory(data)),

})
export default connect(mapStateToProps, mapDispatchToProps)(SeriesPages);
