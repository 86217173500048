
import React, { Fragment, useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { GetmovieContentType } from '../../store/action/TalentHuntVideoList';
import '../../resuable/MovieDetail.css';
import axios from 'axios';

const MovieTabDetailPage = (props) => {
    const {item}=props
    const Xmas = new Date(item.year);
const year = Xmas.getYear(); // returns 95
       return (
        <Fragment>
            <>
            <div className='container-fluid '>
                <div className='row'>
                    <div className='col-12 p-0'>  
                        <div class="card-details" style={{fontSize:'14px'}}>
                            <h3 class="text-overflow card-header pl-0 " style={{color:'#fff',fontWeight:'400',fontSize:'15px'}}>{item.title.substring(0, 15) + "..."}</h3>
                                <div class="text-overflow card-subheader text-white" style={{fontSize:'14px'}}>
                                    {/* {year} */}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        </Fragment>
    )
}
export default MovieTabDetailPage

