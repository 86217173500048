import { GET_USER_SUBCATEGORY_LIST, GET_USER_MOVIE_LIST, GET_USER_MOVIE_LIST_CONTENT_TYPE } from '../constatnt';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../config';


export const GetSubCategoryListByUserId = (data) => async (dispatch, getState) => {
    axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/gettalenthuntvideolistwithdetail`, data)
        .then(res => {
            console.log(res)
            if (res.data.msg === 'list find success') {
                console.log(res)
                dispatch({
                    type: GET_USER_SUBCATEGORY_LIST,
                    data: res.data.subcategorylist
                })
            }
            if (res.data.msg === "list Empty") {
                dispatch({
                    type: GET_USER_SUBCATEGORY_LIST,
                    data: 'Empty List'
                })
            }
            if (res.data.msg === 'Error' || !res.data.msg) {
                dispatch({
                    type: GET_USER_SUBCATEGORY_LIST,
                    data: "Error"
                })
            }


        })
}

export const GetmovieListByUserId = (data) => async (dispatch, getState) => {
    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getmovielistbysubcategoryanduserid`, data)
        .then(res => {
            console.log(res)
            if (res.data.msg === 'List Success') {
                console.log(res)
                // window.location.reload();
                dispatch({
                    type: GET_USER_MOVIE_LIST,
                    data: res.data.data
                })
            }
            if (res.data.msg === "list Empty") {
                dispatch({
                    type: GET_USER_MOVIE_LIST,
                    data: 'Empty List'
                })
            }
            if (res.data.msg === 'Error' || !res.data.msg) {
                dispatch({
                    type: GET_USER_MOVIE_LIST,
                    data: "Error"
                })
            }


        })
}
export const GetmovieContentType = (data) => async (dispatch, getState) => {
    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getmoviecontenttypelistbyuseridandsubcatid`, data)
        .then(res => {
            console.log(res)
            if (res.data.msg === 'List Success') {
                console.log(res)
                // window.location.reload();
                dispatch({
                    type: GET_USER_MOVIE_LIST_CONTENT_TYPE,
                    data: res.data.data
                })
            }
            if (res.data.msg === "list Empty") {
                dispatch({
                    type: GET_USER_MOVIE_LIST_CONTENT_TYPE,
                    data: 'Empty List'
                })
            }
            if (res.data.msg === 'Error' || !res.data.msg) {
                dispatch({
                    type: GET_USER_MOVIE_LIST_CONTENT_TYPE,
                    data: "Error"
                })
            }


        })
} 
