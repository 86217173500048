
import React, { useEffect, useState, Fragment } from 'react';
import StudentCornerTabCrouselCard from './StudentCornerTabCrouselCard';
import StudentCornerDetailPage from './StudentCornerDetailPage';
import Carousel, { consts } from 'react-elastic-carousel';
import ItemsCarousel from 'react-items-carousel';
import {Link} from 'react-router-dom';
import '../../HomeLayout/Crousel/Crousel.css';

const StudentCornerMultiListCrousel = (props) => {
    const { moviList } = props;

    console.log(props.moviList)
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 15;
    return (
        <Fragment>
            <>
                <div className='crouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={6}
                        gutter={20}
                        leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                        rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                        // outsideChevron
                        chevronWidth={chevronWidth}
                    >
                        {moviList.map((item, index) => {
                            return (
                                <Link to={{
                                    pathname: `/studentcorner/${item.subject.replace(/ /g,"-").toLowerCase()}/${item.id}`
                                }}>
                                    <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                        <StudentCornerTabCrouselCard item={item} />
                                        <StudentCornerDetailPage item={item} />
                                    </div>
                                </Link>

                            )
                        })}
                    </ItemsCarousel>
                </div>
                <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={3}
                        gutter={10}
                        leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                        rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                        chevronWidth={chevronWidth}
                    >
                        {moviList.map((item, index) => {
                            return (
                                <Link to={{
                                    pathname: `/studentcorner/${item.subject.replace(/ /g,"-").toLowerCase()}/${item.id}`
                                }}>
                                    <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                        <StudentCornerTabCrouselCard item={item} />
                                        <StudentCornerDetailPage item={item} />
                                    </div>
                                </Link>
                            )
                        })}
                    </ItemsCarousel>
                </div>
            </>
        </Fragment>
    )
}


export default StudentCornerMultiListCrousel;
