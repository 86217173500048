import React, { useState, useEffect } from 'react'
import UserBankService from '../../../Services/UserBankServices/UserBankServices';
import DashBord from '../../HomeLayout/UserDashboard/index';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import { EditUserBankAccount } from '../../../store/UserBankAccountList/UserBankAccountListaction';
import { useSelector, useDispatch } from 'react-redux';
import './UserBankList.scss'


function UserBankList(props) {
   const dispatch = useDispatch();
   const bankaccdata = useSelector((state) => state.UserBankAccountReducer.bankaccountdata);
   console.log(bankaccdata)

   const [bankList, setBankList] = useState([]);
   const [successList, setSussceslist] = useState(false)
   //  const { bankList, EditBankDetail, deletebankdetailbutton } = props
   const fetchbanklist = async () => {
      const callapi = await UserBankService.getbankaccountlist()
      console.log(callapi)
      if (callapi.state === false) {
         if (callapi.response.data.success === true) {
            if (callapi.response.data.result.length > 0) {
               setBankList(callapi.response.data.result)
               setSussceslist(true)
            }
            else {
               setSussceslist(false)
               console.log('empty bank list')
            }
         }
         else {
            setSussceslist(false)
         }
      }
      else {
         setSussceslist(false)
      }
   }
   const deletebankdetailbutton = async (item) => {
      console.log(item)
      // dispatch(EditUserBankAccount(item))
      const apicall = await UserBankService.deleteBank(item.id)
      console.log(apicall)
      if (apicall.state === false) {
         if (apicall.response.data.success === true) {
            fetchbanklist()
            if (apicall.response.data.result.affectedRows === 1) {
               notification['success']({
                  message: 'Bank Account Successfully Deleted',
                  // description:
                  //   'Something Error! Please Try Again',
               })
               console.log('success')
               fetchbanklist()
            }
            else {
               console.log('error')
               fetchbanklist()
            }
         }
         else {
            console.log('error')
            fetchbanklist()
         }
      }
      else {
         console.log('error')
         fetchbanklist()
      }
   }
   const EditBankDetail = async (item) => {
      console.log(item)
      item['status'] = 'edit'
      dispatch(EditUserBankAccount(item))
   }
   const addaccountLink = () => {
      dispatch(EditUserBankAccount())
   }
   useEffect(() => {
      window.scroll(0, 0)
      fetchbanklist()
   }, [])
   return (
      <>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-md-2 d-sm-none d-md-block d-lg-block'>
                  <DashBord />
               </div>
               <div className='col-md-10  mt-2'>
                  <div className='container'>


                     <>
                        <div className='row mb-4'>
                           <div className='col-12  font-weight-bold d-flex' style={{ justifyContent: 'space-between' }}>
                              <h2 style={{ display: 'flex', alignItems: 'center' }} className='text-white'>Bank List</h2>
                              <Link to='/accountdetail' onClick={addaccountLink} className='btn btn-primary font-weight-bold' style={{ padding: '5px 10px', fontSize: '20px' }}>Add Bank Account</Link>
                           </div>
                        </div>
                        <div className='row banklist_div_row'>
                           <div className='col-12'>
                              <div className='container'>
                                 <div className='row'>
                                    <div className='msg_main m-0'>
                                       <div className='card_msg mt-5'>
                                          <div className='kidz_table'>
                                             <div className='head_tbl br_white'>
                                                <div className='s_no_one'>
                                                   <p>S.No</p>
                                                </div>
                                                <div className='s_no_one'>
                                                   <p>Name</p>
                                                </div>
                                                <div className='s_no_one'>
                                                   <p>B.Name</p>
                                                </div>
                                                <div className='s_no_one'>
                                                   <p>Acc No</p>
                                                </div>
                                                <div className='s_no_one'>
                                                   <p>IFSC</p>
                                                </div>
                                                <div className='s_no_one'>
                                                   <p>Branch Name</p>
                                                </div>
                                                <div className='s_no_one'>
                                                   <p>Edit</p>
                                                </div>
                                                <div className='s_no_one'>
                                                   <p>Delete</p>
                                                </div>
                                             </div>
                                             {successList === true ?
                                                <>
                                                   {bankList.map((item, index) => {
                                                      return (
                                                         <div key={index} className='head_tbl'>
                                                            <div className='s_no_two'>
                                                               <p>{index + 1}</p>
                                                            </div>
                                                            <div className='s_no_two'>
                                                               <p>
                                                                  {item.account_holder_name}
                                                               </p>
                                                            </div>
                                                            <div className='s_no_two'>
                                                               <p>{item.bank_name}</p>
                                                            </div>
                                                            <div className='s_no_two'>
                                                               <p>{item.account_no}
                                                               </p>
                                                            </div>
                                                            <div className='s_no_two'>
                                                               <p>{item.ifsc_code}
                                                               </p>
                                                            </div>
                                                            <div className='s_no_two'>
                                                               <p>{item.branchname}
                                                               </p>
                                                            </div>
                                                            <div className='s_no_two'>
                                                               <p className='text-center'>
                                                                  <Link
                                                                     to={{ pathname: "/accountdetail", state: "Raju" }}
                                                                  ><i class="fas fa-edit"
                                                                     onClick={() => EditBankDetail(item)}
                                                                     style={{ color: '#43db00', fontSize: '30px' }}></i>
                                                                  </Link>
                                                               </p>
                                                            </div>
                                                            <div className='s_no_two'>
                                                               <p className='text-center'>
                                                                  <i
                                                                     onClick={() => deletebankdetailbutton(item)}
                                                                     class="ri-delete-bin-line" style={{ color: '#ff0000', fontSize: '30px' }}></i>
                                                               </p>
                                                            </div>

                                                         </div>
                                                      )
                                                   })}
                                                </>
                                                : <h3 className='text-white text-center p-3'>Empty Data</h3>

                                             }


                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>                                        </div>

                        </div>

                     </>


                  </div>
               </div></div></div>

      </>

   )
}

export default UserBankList