import React, { Fragment, useState, useEffect } from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../../../../store/config';
import MovieTabCrouselCard from '../../../../../Pages/MovieTab/MovieTabCrouselCard';
import MovieTabDetailPage from '../../../../../Pages/MovieTab/MovieTabDetailPage';
import SeriesTabCrouselCard from '../../../../../Pages/SeriesPage/SeriesTabCrouselCard';
import SeriesTabDetailPage from '../../../../../Pages/SeriesPage/SeriesTabDetailPage';
import './HomeCrouselMovieList.scss';
const { Title } = Typography;

function HomeCrouselMovieList(props) {
    const id = useParams();
    console.log(id)
    const [VideoList, setVideoList] = useState([]);
    const [videotypcatname, setvideotypecatname] = useState()
    const moviedetailstyle = {
        // top: '-70%'
    }

    useEffect(() => {
        VideoLstapi()
        console.log('latest')
    }, [])
    const VideoLstapi = async () => {
        const data = {
            category_id: id.category_id,
            videotype: id.type.replace(/-/g, " ")
            // videotype:id.type==="Recently-Added" ? "Recently Added":id.type ,
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/fetchhomeCrouselvideotype_view_allList`, data)
            .then(res => {
                if (res.data.msg === 'list find success') {
                    console.log(res.data.result)
                    setvideotypecatname(res.data.type)
                    setVideoList(res.data.result)
                }
                else {
                    console.log(res.data)
                }
            })


        // if(id.type==='Kidzjoy-original'){
        //     axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getkidzjoyoriginalallmovieList`)
        //     .then(res => {
        //         if (res.data.msg === "list find success") {
        //             console.log(res.data.result)
        //             setVideoList(res.data.result)
        //         }
        //         else {
        //             console.log(res.data)
        //         }
        //     })
        // }

        // else{
        // const data = {
        //     videotype:id.type==="Recently-Added" ? "Recently Added":id.type ,
        // }
        // axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/gethomevideotypeListbyvideo_type`, data)
        //     .then(res => {
        //         if (res.data.msg === 'list find success') {
        //             console.log(res.data.result)
        //             setVideoList(res.data.result)
        //         }
        //         else {
        //             console.log(res.data)
        //         }
        //     })
        // }    
    }
    return (
        <Fragment>
            {videotypcatname === 'movies' ?
                <div className='container-fluid crousel_all_movie_list_container'>
                    <div className='row'>
                        <div className='col-12 crousel_all_movie_list_col'>
                            <Title level={2}></Title>
                            <div class="main">
                                <ul class="cards">
                                    {VideoList && VideoList.map((item, index) => {
                                        return (
                                            <li className='card_list my-5'>
                                                <Link
                                                    to={{
                                                        pathname: `/movies/${item.title.replace(/ /g,"-")}/${item.id}`

                                                    }} style={{ textDecoration: 'none' }}
                                                >
                                                    <MovieTabCrouselCard item={item} />
                                                    <MovieTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='container-fluid crousel_all_movie_list_container'>
                    <div className='row'>
                        <div className='col-12 crousel_all_movie_list_col'>
                            <Title level={2} className='text-white'>{id.type} Web Shows</Title>
                            <div class="main">
                                <ul class="cards">
                                    {VideoList && VideoList.map((item, index) => {
                                        return (
                                            <li className='card_list my-5'>
                                            <Link
                                            to={{
                                                pathname: `/series/${item.title.replace(/ /g,"-")}/${item.id}` ,
                                                state: {
                                                    show_id: item.id,
                                                    seasonLength: !item.seasonid ? "Comming Soon" : item.seasonid.length
                                                }
                                            }}
                                        >
                                            <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                                <SeriesTabCrouselCard item={item} />
                                                <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                            </div>
                                        </Link>
                                        </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </Fragment>
    )
}

export default HomeCrouselMovieList
