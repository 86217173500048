import React, { Fragment, useEffect, useState } from 'react';
import sachinMovie from '../../../assets/video/sample-video.mp4'
import { GetMovieListSubCategory, GetmovieListBySubCategory } from '../../../../store/action/MovieTabAction';
import { connect } from 'react-redux';
import MovieSubCategoryListCrousel from '../../../Pages/MovieTab/MovieSubCategoryListCrousel';
import BannerCrousel from '../../../resuable/bannerCrousel';
import axios from 'axios';
import Loder from '../../../resuable/Loder';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../../store/config';
import './MoviePage.css'





const MoviePages = (props) => {
  window.scrollTo(0, 0);
  const [loder, setLoder] = useState(false);
  const [bannerVideo, setBannerVideo] = useState();

  var slideIndex = 1;


  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(35% - 15px)',
    right: '40px',
    width: 30,
    height: 30,
    cursor: 'pointer',
    backgroundColor: 'none',
    border: 'none',
    background: 'none'
  };
  const [videoDisplay, setVideodisplay] = useState('d-none');
  const [videoname, setVideoname] = useState(sachinMovie);


  const videoplaypausebutton = () => {
    var video = document.getElementById("moviebannervideo");
    var btn = document.getElementById("myBtn");
    video.muted = !video.muted;

    // if (video.paused) {
    //   video.play();
    //   btn.innerHTML = "Pause";
    // } else {
    //   video.pause();
    //   btn.innerHTML = "Play";
    // }

  }
  const onClickHandlr = (onClickHandler) => {

    var video = document.getElementById("moviebannervideo");
    // setVideoname('')
    onClickHandler()
    // setVideodisplay('d-none')
    video.currentTime = 0
    setTimeout(() => {
      // setVideodisplay('')
      // setVideoname(sachinmovie)


    }, 3000)


  }




  useEffect(() => {
    window.scrollTo(0, 0);
    const catdata = {
      category_id: '3'
    }
    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getBannerList`, catdata).then(res => {
      if (res.data.msg === "list find success") {
        console.log(res)
        setBannerVideo(res.data.result)

      }

      setLoder(true)
    })
    // showDivs(slideIndex);
    const data = {
      category_id: 1
    }
    props.getMovieListSubCategoryListHandle(data)
    setTimeout(() => {
      setVideodisplay('')
    }, 3000)

  }, [])

  return (
    <Fragment>
      {loder === true ?
        <>
          <BannerCrousel bannerVideo={bannerVideo} />
          <MovieSubCategoryListCrousel subcategoryList={props.getMovieDetailList} />
        </>
        :
        <Loder />

      }

    </Fragment>
  )
}
const mapStateToProps = state => ({
  // movieListSubCategory: state.MovieTabReducer.getmovieListSubCategory,
  // movieListSubCategoryLoder: state.MovieTabReducer.getmovieListSubCategoryLoder,

  getMovieDetailList: state.MovieTabReducer.getMovieDetailList,
  getMovieDetailListLoder: state.MovieTabReducer.getMovieDetailListLoder
})

const mapDispatchToProps = dispatch => ({
  GetMovieListSubCategoryHandler: data => dispatch(GetMovieListSubCategory(data)),
  getMovieListSubCategoryListHandle: data => dispatch(GetmovieListBySubCategory(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(MoviePages);
