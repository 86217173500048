import React, { Fragment } from 'react';
import MovieTabCrouselCard from '../../Pages/MovieTab/MovieTabCrouselCard';
import MovieTabDetailPage from '../../Pages/MovieTab/MovieTabDetailPage';
import SeriesTabCrouselCard from '../../Pages/SeriesPage/SeriesTabCrouselCard';
import SeriesTabDetailPage from '../../Pages/SeriesPage/SeriesTabDetailPage';
import StudentCornerTabCrouselCard from '../../Pages/StudentCorner/StudentCornerTabCrouselCard';
import StudentCornerDetailPage from '../../Pages/StudentCorner/StudentCornerDetailPage';
import TalentHuntTabCrouselCard from '../../Pages/TalentHuntTab/TalentHuntTabCrouselCard';
import TalentHuntTabCrouselDetail from '../../Pages/TalentHuntTab/TalentHuntTabCrouselDetail';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
const { Title } = Typography;

function CategoryVideoListPage(props) {
    const moviedetailstyle = {
        top: '-200%'
    }
    console.log(props.VideoList)
    return (
        <Fragment>
            <div className='container-fluid crousel_all_movie_list_container'>
                <div className='row'>
                    <div className='col-12 crousel_all_movie_list_col'>
                        {/* <Title level={2}></Title> */}
                        <div class="main">
                            <ul class="cards">
                                {props.VideoList && props.VideoList.map((item, index) => {
                                    return (
                                        <>
                                            {props.categoryType === 'student corner' ?
                                                <li className='card_list my-5'>
                                                    <Link to={{
                                                        pathname: `/studentcorner/${item.subject}/${item.id}`
                                                    }}>
                                                        <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                                            <StudentCornerTabCrouselCard item={item} />
                                                            <StudentCornerDetailPage item={item} />
                                                        </div>
                                                    </Link>
                                                </li>
                                                : ""}
                                            {props.categoryType === 'movies' ?
                                                <li className='card_list my-5'>
                                                    <Link to={{
                                                        pathname: `/movies/${item.title.replace(/ /g, "-")}/${item.id}`
                                                    }}>
                                                        <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                                            <MovieTabCrouselCard item={item} />
                                                            <MovieTabDetailPage item={item} />
                                                        </div>
                                                    </Link>
                                                </li>
                                                : ""}
                                            {props.categoryType === 'talent hunt' ?
                                                <li className='card_list my-5'>
                                                    <Link to={{
                                                        pathname: `/talenthuntdetail/${item.title.replace(/ /g, "-")}/${item.id}`
                                                    }}>
                                                        <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                                            <TalentHuntTabCrouselCard item={item} />
                                                            <TalentHuntTabCrouselDetail item={item} />
                                                        </div>
                                                    </Link>
                                                </li>
                                                : ""}
                                            {props.categoryType === 'web show' ?
                                                <li className='card_list my-5'>
                                                    <Link
                                                        to={{
                                                            pathname: `/series/${item.title.replace(/ /g, "-")}/${item.id}`,
                                                            state: {
                                                                show_id: item.id,
                                                                seasonLength: !item.seasonid ? "Comming Soon" : item.seasonid.length
                                                            }
                                                        }}
                                                    >
                                                        <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                                            <SeriesTabCrouselCard item={item} />
                                                            <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                                        </div>
                                                    </Link>
                                                </li>
                                                : ""}

                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CategoryVideoListPage;
