import React, { useEffect, useState, Fragment } from 'react';
import AuthServices from '../../Services/authServices';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { Button, notification, Space } from 'antd';
import { useHistory } from 'react-router';
import PaymentGetWayServices from '../../Services/Paymentgetwayservice/paymentetwayservices';
import "./demopage.scss"

function Demopage() {
  const history=useHistory()
  const fetchid=localStorage.getItem('id')
  if(!fetchid){
    window.location='/'
  }
  const [userdetail, setUserDetail] = useState([]);
  const [packlist, setPackList] = useState([]);
 // const [commisiondetail, setCommisionDetail] = useState([]);
  const [totalamount, setTotalamount] = useState(0);
  const [buyPackSuccess, setBuyPackSuccess] = useState(false);
  const [UserDiscountDetail,setUserDiscountDetail]=useState([])
  const [promouserid,setpromouserid]=useState('')
  const [subscriptiondate, setsubscriptiondate] = useState(
    { stardate: "", enddate: "" }
  )
 // const [commisionAmount,setCommissionAmount]=useState(0);
  const [Userdiscount,SetUserDiscount]=useState(0)
  const findCommisionDetail = async (id, type_id, price) => {
    const apicall = await AuthServices.FindCommisionDetail(id, type_id);
    console.log(apicall)
    if (apicall.state === false) {
      console.log(price)
      if (apicall.response.data.success === true) {
        if(apicall.response.data.userpromodetail.length>0){
          setpromouserid(apicall.response.data.userpromodetail[0].promocode_userid)
        }
        // setCommisionDetail(apicall.response.data.commisiondetail)
        setUserDiscountDetail(apicall.response.data.userdiscount)
        if (apicall.response.data.userdiscount.length > 0) {
          if(apicall.response.data.userdiscount[0].commision_type===1){
            setTotalamount(parseFloat(price) - parseFloat(apicall.response.data.userdiscount[0].amount))
            SetUserDiscount(apicall.response.data.userdiscount[0].amount)
          }
          else if(apicall.response.data.userdiscount[0].commision_type===2){
           const  percentageamount=parseFloat(price) * apicall.response.data.userdiscount[0].amount/100
           setTotalamount(parseFloat(price)-percentageamount)
           SetUserDiscount(percentageamount)
          }
        }
        else {
          setTotalamount(parseFloat(price))
        }
      }
    }
  }
  const fetchuserdetail = async () => {
    const userid = localStorage.getItem('id')
    const apicall = await AuthServices.FindUserDetail(userid);
    console.log(apicall)
    if (apicall.state === false) {
      if (apicall.response.data.success === true) {
        findCommisionDetail(userid, apicall.response.data.result[0].type_property_id, apicall.response.data.planlist[0].sales_price)
        setPackList(apicall.response.data.planlist)
        setUserDetail(apicall.response.data.result)
      }
    }
  }
  useEffect(() => {
    if(!fetchid){
      window.location.replace("/");
    }
    else{
      fetchuserdetail()
    }
  }, [])
  const BuypackButton = async (data) => {
    var today = moment().format('l');

    var next = moment().add(parseInt(packlist[0].days), 'days').calendar();
    setsubscriptiondate({ stardate: today, enddate: next })
    // const data = {
    //   user_id: localStorage.getItem('id'),
    //   // amount: commisionAmount,
    //   subscrition_id: packlist[0].id,
    //   start_date: today,
    //   promocode_user_id:promouserid,
    //   end_date: next,
    //   total_amount:packlist[0].sales_price,
    //   discountType:UserDiscountDetail.length>0 ? UserDiscountDetail[0].user_type: "" ,
    //   discountValue:Userdiscount,
    //   netAmount:totalamount,
    // }
    // console.log(data)
    const apicall = await AuthServices.BuyPack(data)
    console.log(apicall)
    if(apicall.state===false){
      if(apicall.response.data.success===true){
        console.log('success msg')
        setBuyPackSuccess(true)
        notification['success']({
          message: 'Payment is successful',
          description:'Your Plan Is Active.'
        });
      }
    }
    else{
      console.log('something error')
      notification['error']({
        message: 'Something Error!',
      });
    }
    
  }
  const buybuttonclick=async()=>{
    var today = moment().format('l');

    var next = moment().add(parseInt(packlist[0].days), 'days').calendar();
    const data = {
      user_id: localStorage.getItem('id'),
      // amount: commisionAmount,
      subscrition_id: packlist[0].id,
      start_date: today,
      promocode_user_id:promouserid,
      end_date: next,
      total_amount:packlist[0].sales_price,
      discountType:UserDiscountDetail.length>0 ? UserDiscountDetail[0].user_type: "" ,
      discountValue:Userdiscount,
      netAmount:totalamount,
    }
    console.log(data)
    const apicall=await PaymentGetWayServices.PayService(userdetail,BuypackButton,totalamount,data)
    console.log(apicall)
    setsubscriptiondate({ stardate: today, enddate: next })
  }
  const LoginHomepageButton=async()=>{
    const data={
      email:userdetail[0].email
    }
      const apicall=await AuthServices.loginwithoutpassword(data)
      console.log(apicall)
      if(apicall.state===false){
        if(apicall.response.data.success===true){
          notification['success']({
            message: 'Payemnt Success!',
            description:'Your Plan Is Active.'
          })
          localStorage.removeItem('id')
          localStorage.setItem("loginid", apicall.response.data.result[0].id);
          localStorage.setItem("user_token", apicall.response.data.token);
          window.location='/HomePage'
        }
      }
      else{
        console.log('something error')
        notification['error']({
          message: 'Something Error!',
        });
        window.location='/'
      }

  }
  const signoutbuypage=()=>{
    localStorage.removeItem('id')
    history.push('/')
  }
  return (
    <Fragment>
      {userdetail.length > 0 ?
        <>
          {buyPackSuccess === false ?
            <>
              <div className='container'>
                <div className='row'>
                  <div className='msg_main text-right' style={{width:'100%'}}>
                    <button className='btn btn-danger text-bold' onClick={signoutbuypage}>Sign Out</button>
                    </div>
                  </div>
                <div className='row'>
                  <div className='msg_main'>
                    <div className='card_msg'>
                      <p>You have created an account as {userdetail[0].type}, kindly proceed to payment.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <div className='msg_main'>
                    <div className='card_msg'>
                      <div className='pay_info pt-5'>
                        <div className='one_side'>
                          <p>Account Creation Fee</p>
                        </div>
                        <div className='two_side'>
                          <p>{packlist[0].sales_price}</p>
                        </div>
                      </div>
                      {UserDiscountDetail.length > 0 ?
                        <div className='pay_info'>
                          <div className='one_side'>
                            <p>{userdetail[0].type} Code Discount</p>
                          </div>
                          <div className='two_side'>
                            <p>{Userdiscount}</p>
                          </div>
                        </div>
                        : ""}
                      <div className='pay_info'>
                        <div className='one_side'>
                          <p>Amount to Pay</p>
                        </div>
                        <div className='two_side'>
                          <p>{totalamount}</p>
                        </div>
                      </div>
                      <div className='pay_info pb-5'>
                        <div className='one_side'>

                        </div>
                        <div className='two_side'>
                          <div className='pay_now_kdz'>
                            <a className='ant-btn ant-btn-primary' onClick={buybuttonclick}>Pay Now</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              {/* <div className='container'>
                <div className='row'>
                  <div className='msg_main'>
                    <div className='card_msg'>
                      <div className='sccess_pay'>
                        <p>You have paid successfully and Your Account is now active.</p>
                        <p>Account Renewel Date Will Be : 21 FEB 2023</p>
                        <div className='btn_contine text-center mt-5'>
                          <a className='ant-btn ant-btn-primary'>Continue to Use Application</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='container'>
                <div className='row'>
                  <div className='msg_main'>
                    <div className='card_msg'>
                      <div className='sccess_pay'>
                        <p>You have paid successfully and Your Account is now active.</p>
                      </div>
                      <div className='pay_info pb-0'>
                        <div className='one_side'>
                          <div className='sccess_pay'>
                            <p>Account Renewel Date Will Be : {subscriptiondate.enddate}</p>
                            {userdetail[0].promoter_code === null ? "" :
                              <p>Your {userdetail[0].type} Code is : {userdetail[0].promoter_code} &nbsp;&nbsp;&nbsp; <span><a className='pay_now_kdz ant-btn ant-btn-primary'>Share Now</a></span></p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className='sccess_pay'>
                        <div className='btn_contine text-center'>
                          <a onClick={LoginHomepageButton} className='ant-btn ant-btn-primary'>Continue to Use Application</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
        : ""}
    </Fragment>

  )
}

export default Demopage