import React, { useEffect, useState } from 'react';
import DashBord from '../../HomeLayout/UserDashboard/index';
import { Link } from 'react-router-dom';
import UserBankService from '../../../Services/UserBankServices/UserBankServices';
import moment from 'moment';
import PaginatedItems from '../../resuable/Pagination/Pagination';
import './SendMonetToBank.scss'

function SendmoneyHistory() {
    const [HistoryList, setHistorylist] = useState([]);
    const [showperPage, setShowperpage] = useState(5)

    const [pagination, setpaginations] = useState({
        end: showperPage, start: 0
    })
    const fetchhistory = async () => {
        const apicall = await UserBankService.withdrawHistory()
        console.log(apicall)
        if (apicall.state === false) {
            if (apicall.response.data.success === true) {
                if (apicall.response.data.result.length > 0) {
                    console.log('success')
                    setHistorylist(apicall.response.data.result.reverse())
                }
                else {

                }
            }
            else {

            }
        }
        else {

        }
    }
    useEffect(() => {
        fetchhistory()
    }, [])
    const refreshbutton = async (e, item) => {
        console.log(item)
        const data = {
            detailid: item.id,
            merchant_reference_number: item.merchant_reference_number
        }
        const apicall = await UserBankService.getMoneyTransferstatus(data)
        console.log(apicall)
        if (apicall.state === false) {
            fetchhistory()
        }
    }
    const onPaginationchange = (start, end) => {
        console.log(start, end)
        setpaginations({ end: end, start: start })
    }
    return (
        <>
            <div className='container-fluid sendmoney_history_container'>
                <div className='row'>
                    <div className='col-md-2 d-sm-none d-md-block d-lg-block'>
                        <DashBord />
                    </div>
                    <div className='col-md-10  mt-2'>
                        <div className='container'>

                            <div className='row'>
                                <div className='col-12 d-flex' style={{ justifyContent: 'space-between' }}>
                                    <h2 className='text-white'>Send Money to Bank Request</h2>
                                    <Link to='/withdrawpage'>
                                        <h2 className='text-primary'>Send Money to Bank</h2>
                                    </Link>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='msg_main m-0'>
                                                <div className='card_msg mt-5'>
                                                    <div className='kidz_table'>
                                                        <div className='head_tbl br_white'>
                                                            <div className='s_no_one'>
                                                                <p>Date & Time</p>
                                                            </div>
                                                            <div className='s_no_one'>
                                                                <p>Amount</p>
                                                            </div>
                                                            <div className='s_no_one'>
                                                                <p>Account No.</p>
                                                            </div>
                                                            <div className='s_no_one'>
                                                                <p>Bank Name</p>
                                                            </div>
                                                            <div className='s_no_one'>
                                                                <p>Balance</p>
                                                            </div>
                                                            <div className='s_no_one'>
                                                                <p>Transaction Status</p>
                                                            </div>
                                                        </div>
                                                        {HistoryList.length > 0 ?
                                                            <>
                                                                {HistoryList.slice(pagination.start, pagination.end).map((item, index) => {
                                                                    return (
                                                                        <div key={index} className='head_tbl'>
                                                                            <div className='s_no_two'>
                                                                                <p>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}
                                                                                </p>
                                                                            </div>
                                                                            <div className='s_no_two'>
                                                                                <p>{item.sent_amount}</p>
                                                                            </div>
                                                                            <div className='s_no_two'>
                                                                                <p>{item.account_no}</p>
                                                                            </div>
                                                                            <div className='s_no_two'>
                                                                                <p>
                                                                                    {item.bank_name}
                                                                                </p>
                                                                            </div>
                                                                            <div className='s_no_two'>
                                                                                <p>{item.finalbalance}
                                                                                </p>
                                                                            </div>
                                                                            <div className='s_no_two d-flex'>
                                                                                <p>{item.status}
                                                                                </p>
                                                                                <div className='refresh_icon'>
                                                                                    <i onClick={(e) => refreshbutton(e, item)} class="fa fa-refresh" aria-hidden="true"></i>
                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                            : <h3 className='text-white text-center p-3'>Empty Data</h3>

                                                        }


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {HistoryList.length <= showperPage ? "" :
                                            <div className='row'>
                                                <PaginatedItems total={HistoryList.length} onPaginationchange={onPaginationchange} showperPage={showperPage} />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SendmoneyHistory