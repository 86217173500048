import { UserWalletConstant } from './constant';

const initialState = {
  userWalletamount:0
};

export const UserWalletRudeser = (state = initialState, { type, payload }) => {
    switch (type) {
        case UserWalletConstant.GET_USER_WALLET:
            console.log(payload)
            return { ...state, userWalletamount: payload}
        default:
            return state;
    }
}


