import api from "../api/index";

const AuthServices = {
    getusertypelist: function (data) {
        //inspect the value
        return api.get('usertypelist', data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    logIn: function (data) {
        //inspect the value
        return api.post('loginuser', data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    loginwithoutpassword: function (data) {
        //inspect the value
        return api.post('loginwithoutpassword', data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    sendotp: function (data) {
        //inspect the value
        return api.post('sendotpemail', data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    findpromocode: function (typeid, code) {
        //inspect the value
        return api.get(`findpromocode/${typeid}/${code}`)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    FindUserDetail: function (id) {
        //inspect the value
        return api.get(`finduserwithsubscription/${id}`)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    FindCommisionDetail: function (id, type_id) {
        //inspect the value
        return api.get(`commisiondetail/${id}/${type_id}`)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    BuyPack: function (body) {
        //inspect the value
        return api.post(`buypack`, body)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    EarningHistory: function () {
        let token = localStorage.getItem("user_token");
        // const headers = {
        //     'Content-Type': 'application/json',
        //     "authorization": `Bearer ${token}`
        // };
        const id=localStorage.getItem('loginid')
        return api.get(`fetchEarningHistory/${id}`)
            .then((response) => {
                if (response.status == 200) {
                    // console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    fetchSubEarningHistory: function (data) {
        let token = localStorage.getItem("user_token");
        // const headers = {
        //     'Content-Type': 'application/json',
        //     "authorization": `Bearer ${token}`
        // };
        const id=localStorage.getItem('loginid')
        return api.post(`fetchsubearninghistory`,data)
            .then((response) => {
                if (response.status == 200) {
                    // console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    FetchUserDetail: function () {
        let token = localStorage.getItem("user_token");
        // const headers = {
        //     'Content-Type': 'application/json',
        //     "authorization": `Bearer ${token}`
        // };
        const id=localStorage.getItem('loginid')
        return api.post(`finduserdetail/${id}`)
            .then((response) => {
                if (response.status == 200) {
                    // console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    FetchUserPromoDetail: function (id) {
        let token = localStorage.getItem("user_token");
        // const headers = {
        //     'Content-Type': 'application/json',
        //     "authorization": `Bearer ${token}`
        // };
        return api.post(`finduserdetail/${id}`)
            .then((response) => {
                if (response.status == 200) {
                    // console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    authcheck: function () {
        let token = localStorage.getItem("user_token");
        const headers = {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${token}`
        };
        // const id=localStorage.getItem('loginid')
        return api.get(`authcheck`,{headers})
            .then((response) => {
                if (response.status == 200) {
                    // console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }
            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    renewPack: function (body) {
        //inspect the value
        return api.post(`renew_buy_pack`, body)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    packvalidation: function () {
        //inspect the value
        const id=localStorage.getItem('loginid')
        const body={
            user_id:id
        }
        return api.post(`checkuser`, body)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    }
}
export default AuthServices;
