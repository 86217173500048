export const LOCAL_SERVER = process.env.NODE_ENV === 'production' ? `http://localhost:8080` : `http://localhost:8080`; 
export const PANCHANG_SERVER = 'api';

// for Admin Image Path
export const  ADMIN_PATH='http://apicinema.testersgroup.com/'

// http://localhost:8080
// https://api.kidzjoy.org/
// use for video call app
export const MovieVideoType={
    latestRelase:'Recently Added',
    Trending:"Trending",
    Upcoming:"Upcoming"
}

export const WebShowType={
    Upcoming:"Upcoming",
    Trending:"Trending"
}