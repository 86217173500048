import React, {useState,useRef} from 'react';
import Overlay from 'react-bootstrap/Overlay' ;


import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button';
import sharelink from '../../../../components/assets/menu/sharelink.png';
import {
    FacebookIcon,TwitterIcon,WhatsappIcon,
    FacebookShareButton,
   
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";



const SharePopover=()=>{
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
  
    const handleClick = (event) => {
      setShow(!show);
      setTarget(event.target);
    };
  
  
    return (
<span className='ml-3' ref={ref}>
      <Button onClick={handleClick} style={{background:'none'}}>
      <img src={sharelink} width='20px' height='20px' />
      <span className='ml-2' style={{fontSize: '15px'}}>Share</span>
      </Button>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref.current}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          {/* <Popover.Title as="h3">Popover bottom</Popover.Title> */}
          <Popover.Content>
              <FacebookShareButton url={`http://localhost:3000/watch-series`}  style={{marginRight:'10px'}}>
              <FacebookIcon round={true} size={30} />
              </FacebookShareButton>
              <TwitterShareButton style={{marginRight:'10px'}}>
              <TwitterIcon round={true} size={30} />
              </TwitterShareButton>
              <WhatsappShareButton url={`http://localhost:3000/watch-series`} style={{marginRight:'10px'}}>
              <WhatsappIcon round={true}  size={30} />
              </WhatsappShareButton>



          </Popover.Content>
        </Popover>
      </Overlay>
    </span  >

    )
}
export default SharePopover;

