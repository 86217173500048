import {GET_STUDENTCORNER_MOVIE_LIST,GET_STUDENTCORNER_MOVIE_DETAIL_LIST} from '../constatnt';

const initState={
   getStudentcornermovieListSubCategory:'',
   getStudentcornermovieListSubCategoryLoder:false,
   getStudentcornerMovieDetailList:false,
   getStudentcornerMovieDetailListLoder:false
}



const StudentCornerMovieTabReducer=(state=initState,action)=>{
    
    switch(action.type){
       
        case GET_STUDENTCORNER_MOVIE_LIST:
            console.log(action.data)
            console.log(' student corner tab CategoryUploadvideoList Reducer')
            return{
                ...state,
                getStudentcornermovieListSubCategory:action.data,
                getStudentcornermovieListSubCategoryLoder:true,
            }
            case GET_STUDENTCORNER_MOVIE_DETAIL_LIST:
                console.log(action.data)
                console.log('Student corner movie list Reducer')
                return{
                    ...state,
                    getStudentcornerMovieDetailList:action.data,
                    getStudentcornerMovieDetailListLoder:true,
                }
              
        default:
        return state;
    }
}

 export default StudentCornerMovieTabReducer;