import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import { LOCAL_SERVER, PANCHANG_SERVER } from "../../store/config";
import { Button, notification, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import AuthServices from '../../Services/authServices';


export default function SignUpValidation(SignUpUserHandler) {
  const usertype = useSelector((state) => state.AuthRudeser.user_type);
  const usertypeid = useSelector((state) => state.AuthRudeser.user_type_id);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [otpUi, setOtpUi] = useState(false);
  const [email, setEmail] = useState();
  const [promocodeuseid, setPromocodeuserid] = useState('');
  const [PromoSuccess, setPromoSuccess] = useState(false)
  // const [UserCaptcha,setuserCaptcha]=usestate('')

  const [userState, setUserState] = useState({
    name: "",
    countrycode: "",
    mobileno: "",
    email: "",
    password: "",
    promocode: "",
    city: "",
    state: "",
    Pincode: "",
    desigination_school_person: ""
  });
  const [otpstate, setOtpstate] = useState({
    otp: "",
  });
  const [otpverfiyui, setOtpverfiyui] = useState(false);
  const verifyotphandlechange = (e) => {
    const { name, value } = e.target;
    setOtpstate({
      ...otpstate,
      [name]: value,
    });
  };
  const otphandleChange = (otp) => setOtpstate({ otp: otp });

  const onSubmit = async (value) => {
    // e.preventDefault();
    let user_captcha = document.getElementById('user_captcha_input').value;
    if (PromoSuccess === true) {
      if (validateCaptcha(user_captcha) == true) {
        // alert('Captcha Matched');
        notification['success']({
          message: 'Captcha Matched',
          // description:
          //   'Something Error! Please Try Again',
        })
        loadCaptchaEnginge(6);
        document.getElementById('user_captcha_input').value = "";
        console.log(value);
        console.log(usertype, usertypeid);
        setUserState({
          name: value.username,
          mobileno: value.contact.mobileno,
          countrycode: value.prefix,
          email: value.email,
          password: value.passowrd,
          promocode: value.promocode ? value.promocode : null,
          city: value.city ? value.city : null,
          state: value.state ? value.state : null,
          Pincode: value.pincode ? value.pincode : null,
          desigination_school_person: value.desigination_school_person ? value.desigination_school_person : null
        });
        const otpdata = {
          email: value.email,
          mobileno: `${'91'}${value.contact.mobileno}`,
          usertype: usertype,
          usertypepropertyid: usertypeid,
          promocode: value.promocode ? value.promocode : null
        }
        console.log(otpdata)
        const callapi = await AuthServices.sendotp(otpdata)
        console.log(callapi)
        if (callapi.state === false) {
          if (callapi.response.data.success === true) {
            notification['success']({
              message: 'OTP Send Your Email Id & Mobile No!'
            })
            setOtpverfiyui(true);
          }
          else {
            notification['error']({
              message: 'Something Error!',
              description:
                'Something Error! Please Try Again',
            })
          }
        }
        else {
          // if(callapi.response.data.msg==='')
          notification['error']({
            message: callapi.response.data.msg,
            // description:
            //   'Something Error! Please Try Again',
          })
        }
      }
      else {
        notification['error']({
          message: 'Captcha Does Not Match',
          // description:
          //   'Something Error! Please Try Again',
        })
        document.getElementById('user_captcha_input').value = "";
      }
    }
    else {
      notification['error']({
        message: 'Enter valid Promocode',
        // description:
        //   'Something Error! Please Try Again',
      })
    }


    // axios
    //   .post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/sendotpemail`, otpdata)
    //   .then((res) => {
    //     console.log(res.data.mobile_otp);
    //     if(res.data.mobile_otp==='otp send success' || res.data.email_msg==='sent msg'){
    //          notification['success']({
    //         message: 'OTP Send Your Email Id & Mobile No!'
    //       });
    //       console.log(res);
    //       setOtpverfiyui(true);
    //     }
    //     else if(res.data.msg==='Email already matched!'){
    //       notification['error']({
    //         message: 'Email already exist!',
    //         description:
    //           'Try another Email Id',
    //       });
    //     }
    //     else if(res.data.msg==='Email and Mobile already matched!'){
    //       notification['error']({
    //         message: 'Email and Mobile No already exist!',
    //         description:
    //           'Try another Email Id and Mobile No',
    //       });
    //     }
    //     else if(res.data.msg==='Mobile already matched!'){
    //       notification['error']({
    //         message: 'Mobile No already exist!',
    //         description:
    //           'Try another Mobile No',
    //       });
    //     }
    //     else if(res.data.msg==='PromoCode Invalid'){
    //       notification['error']({
    //         message: 'PromoCode Invalid!',
    //         description:
    //           'Please Try Another Code',
    //       });
    //     }
    //     else{
    //         notification['error']({
    //         message: 'OTP Send Faild!',
    //         description:
    //           'Something Error! Please Try Again',
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     notification['error']({
    //       message: 'OTP Send Faild!',
    //       description:
    //         'Something Error! Please Try Again',
    //     });
    //   });
  };
  const verifyotpbutton = (e) => {
    e.preventDefault();
    if (!otpstate.otp) {
      console.log(otpstate.otp)
    }
    else {
      console.log(otpstate);
      const userdata = {
        email: userState.email,
        otp: otpstate.otp,
      };
      const data = {
        name: userState.name,
        mobileno: `${`91`}${userState.mobileno}`,
        email: userState.email,
        password: userState.password,
        typeproperty_id: usertypeid,
        type: usertype,
        referpromocode: userState.promocode === '' ? null : userState.promocode,
        city: userState.city,
        state: userState.state,
        pincode: userState.Pincode,
        desigination_school_person: userState.desigination_school_person,
        promoter_code_user_id: promocodeuseid
      };
      console.log(data)
      axios
        .post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findotp`, userdata)
        .then((res) => {
          console.log(res)
          if (res.data.msg === "otp  find") {
            console.log(res);
            axios
              .post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/createuser`, data)
              .then((result) => {
                if (result.data.msg === "User Account Create") {
                  console.log(result);
                  localStorage.setItem("id", result.data.result.id);
                  // localStorage.setItem("userdata",result.data.result);
                  // localStorage.setItem("user_token", result.data.token);
                  window.location = "/planform";
                } else {
                  console.log(result);
                }
              });
          }
        });
    }
  };

  return {
    onSubmit,
    register,
    handleSubmit,
    errors,
    otpUi,
    otpstate,
    verifyotpbutton,
    verifyotphandlechange,
    otpverfiyui,
    otphandleChange,
    usertype, usertypeid, setPromocodeuserid, setPromoSuccess

  };
}
