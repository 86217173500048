import React, { useState } from "react";
import ReactHtmlParser from 'react-html-parser';
const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text" style={{fontSize:'15px'}}>
        {isReadMore ? text.slice(0, 150) : text}
        {text.length>145 ?
        <span onClick={toggleReadMore} className="read-or-hide" style={{color:'blue',cursor:'pointer'}}>
          {isReadMore ? "...read more" : " show less"}
        </span>
        :""}
      </p>
    );
  };
  const ReadMorecomponent = (props) => {
    return (
      <div className="container p-0">
        <h2 className="text-white">
          <ReadMore>
              {ReactHtmlParser(props.text)}
          </ReadMore>
        </h2>
      </div>
    );
  }
  export default ReadMorecomponent;
