import api from "../../api/index";

const UserBankService = {
    AddBankAccount: function (data) {
        //inspect the value
        return api.post('addbankdetail', data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    getbankaccountlist: function () {
        //inspect the value
        const id=localStorage.getItem('loginid')
        return api.get(`getuserbanklist/${id}`)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    deleteBank: function (id) {
        //inspect the value
        return api.get(`deletebank/${id}`)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    editbankdetail: function (body) {
        //inspect the value
        return api.post(`editbank_detail`,body)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    withdrawamount: function (body) {
        //inspect the value
        return api.post(`fundtransefer`,body)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    getMoneyTransferstatus: function (body) {
        //inspect the value
        return api.post(`findpaymentstatus`,body)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
    
    withdrawHistory: function () {
        //inspect the value
        const id=localStorage.getItem('loginid')
        return api.get(`getwithdrawhistory/${id}`)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response)
                    return { "response": response, "state": false }
                } else {
                    return { "response": response, "state": true }
                    //this.setState({open: true});
                }

            })
            .catch(error => {
                if (error.response) {
                    return { "response": error.response, "state": true }

                } else if (error.request) {

                    console.log(error.request);
                    return { "response": null, "state": true }
                } else {

                    console.log('Error', error.message);
                    return { "response": null, "state": true }
                }

                //this.setState({open: true});
            })
    },
   

}
export default UserBankService;
