import api from "../../api/index";


var VideoLikeUnLikeServices={
    Videolikenumber: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("videolikenumber", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    VideolikeStatus: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("findvideoLikeUnlikeStatus", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    addvideoLikeNumber:function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("adduserlike", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    addvideodisLikeNumber:function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("adduserdislike", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    removevideoLikeNumber:function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("removeuserlike", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    removevideodisLikeNumber:function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("removeuserdislike", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    }

}
export default VideoLikeUnLikeServices;
