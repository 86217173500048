import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';
import TalentHuntTabCrouselCard from '../TalentHuntTabCrouselCard';
import TalentHuntTabCrouselDetail from '../TalentHuntTabCrouselDetail';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {LOCAL_SERVER,PANCHANG_SERVER} from '../../../../store/config';
import './TalentHuntSubCategoryListPage.scss'
const { Title } = Typography;

function TalentHuntSubCategoryListPage() {
    const params = useParams()
    const [VideoList, setVideoList] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getTalentHunt_subcategory_VideoList/${params.subcategory_id}`)
            .then(res => {
                if (res.data.msg === "List Find Success") {
                    setVideoList(res.data.result)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    return (
        <Fragment>
            <div className='container-fluid crousel_all_movie_list_container'>
                <div className='row'>
                    <div className='col-12 crousel_all_movie_list_col'>
                        <Title level={2}></Title>
                        <div class="main list_main_talenthunt">
                            <ul class="cards">
                                {VideoList && VideoList.map((item, index) => {
                                    return (
                                        <li className='card_list my-2'>
                                            <Link
                                                to={{
                                                    pathname: `/talenthuntdetail/${item.title.replace(/ /g,"-").toLowerCase()}/${item.id}`

                                                }} style={{ textDecoration: 'none' }}
                                            >
                                                <TalentHuntTabCrouselCard item={item} />
                                                <TalentHuntTabCrouselDetail item={item} />
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TalentHuntSubCategoryListPage;
