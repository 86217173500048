import React, { Fragment } from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import {Link} from 'react-router-dom';
import '../../Pages/MovieTab/MovieTabCrouselCard.scss';

const CrouselCard = (props) => {
    console.log(props.item)
    return (
        <Fragment>
             <div className='itemimagetag'>
                      <Link
                         to={{
                            pathname: '/video',
                            state: {
                                item: props.item,
                            }
                        }}
                       >
                       <img className='itemimagetag' style={{borderRadius:'3px',boxShadow:"0 2px 5px 0 rgb(34 34 34 / 40%)"}} 
                        src={`${LOCAL_SERVER}/${PANCHANG_SERVER}/fetchUplaodImage/${props.item.image}`} type='file/png'
                        height='350px' width='100%'>
                        </img>
                       </Link>
                      </div>
        </Fragment>


    )
}

export default CrouselCard;
