import React,{Fragment} from 'react';


const TermConditionPage =()=>{
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 '>
                    Before uoloading your video please go through the following terms and conditions. You can't upload your video until or unless you accept all .
                    </div>
                    <div className='col-12 '>
                    1. You declare that the video being uploaded by you is the original one recorded by you and no other person is having any copyright on the said video.                        
                    </div>
                    <div className='col-12'>
                        2. In case it is found later on that the video uploaded by you is the property of someone else or you don't have any copyright on that video, you will have to bear the consequences in terms of loss suffered by our OTT. 
                    </div>
                    <div className='col-12'>
                    3. The moment you upload your video on our OTT platform, it becomes the property of our OTT Channel and we are free to use, forward, show or share the said video as per our requirement.
                    </div>
                    <div className='col-12'>
                    4. Our OTT Channel has every right to alter, amend or edit the said video as per our requirement.
                    </div>
                    <div className='col-12'>
                    5. In case any minor is uploading any video after accepting these terms and conditions, it will be assumed that he/she is uploading the said video with the consent of his/her parents. 
                    </div>
                    <div className='col-12'>
                        6. In case any minor is uploading any video without the consent of his/her parents, our OTT can't be held responsible for any kind of breach of Law.
                    </div>
                    <div className='col-12'>
                    7. You understand that our OTT Channel has every right to accept or reject the video keeping the norms of Channel in view. No objection can be raised by anyone in case any video is rejected.
                    </div>
                    <div className='col-12'>
                    8. You understand that in case your video is uploaded on our OTT Channel Platform, no amount will be paid to you in lieu of that at any time. 
                    </div>
                    <div className='col-12'>
                    I have gone through the above mentioned terms and conditions and I agree to all.
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default TermConditionPage;