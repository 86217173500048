import React, { useEffect, useState, Fragment } from 'react';
import CrouselCard from '../HomeLayout/Crousel/CrouselCard';
import Carousel, { consts } from 'react-elastic-carousel';
import ItemsCarousel from 'react-items-carousel';


import MoviDetailPage from './MoviDetailPage';
import movie1 from '../assets/upcoming/03.jpg';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import axios from 'axios';
import { connect } from 'react-redux';
import { GetmovieListByUserId, GetmovieContentType } from '../../store/action/TalentHuntVideoList';
import '../HomeLayout/Crousel/Crousel.css'

const MultiItemListCrousel = (props) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8]);

    const subcategory = props.subcategoryname
    const [videoList, SetVideoList] = useState({

    })
    const [status, setStatus] = useState(false)

    const moviedetailstyle = {
        top: '-200%'
    }
    const breakPoints = [

        { width: 2, itemsToShow: 2 },
        { width: 550, itemsToShow: 2 },
        { width: 850, itemsToShow: 4 },
        { width: 1150, itemsToShow: 4 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
    ]
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ?
            <button className='btn rec-arrow-left' onClick={onClick} disabled={isEdge}>
                <i class="fa fa-chevron-left "></i></button>
            :
            <button className='btn rec-arrow-right' onClick={onClick} disabled={isEdge}>
                <i class="fa fa-chevron-right"></i></button>

        return (
            pointer
        )
    }

    useEffect(() => {
        const data = {

            category_id: props.category_name
        }
        props.GetmovieListByUserIdHandler(data)
        // props.GetmovieContentTypeHandler(data)
        //  if (props.getmovielistbysubvategoryloder === true) {
        //     console.log(props.GetMoviListofuserbysubcategory)
        // }
    }, [])


    return (
        <Fragment>

            {props.movieListLoder === true ?
                <>
                    {/* {props.MovieList.filter((el) => { return el.subcategory_id === subcategory }).map((item, index) => { */}
                    {/* return ( */}
                    <div className='crouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={5}
                            gutter={20}
                            leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                            rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                            // outsideChevron
                            chevronWidth={chevronWidth}
                        >
                            {props.MovieList.filter((el) => { return el.subcategory_id === subcategory }).map((item, index) => {
                                return (

                                    <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                        {/* <img className='crouselimg' src={movie1} width='100%'></img> */}
                                        <CrouselCard item={item} />
                                        <MoviDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                    </div>
                                )
                            })}
                        </ItemsCarousel>
                    </div>
                    <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={2}
                            gutter={20}
                            leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                            rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                            // outsideChevron
                            chevronWidth={chevronWidth}
                        >
                            {props.MovieList.filter((el) => { return el.subcategory_id === subcategory }).map((item, index) => {
                                return (
                                    // newcrouseldiv
                                    <div className='' style={{borderRadius:'3px', height: "", background: '' }}>
                                        {/* <img className='crouselimg' src={movie1} width='100%'></img> */}
                                        <CrouselCard item={item} />
                                        <MoviDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                    </div>
                                )
                            })}
                        </ItemsCarousel>
                    </div>
                    {/* ) */}
                    {/* })} */}

                </>
                // <div style={{ display: 'flex' }}>
                //     <Carousel   renderArrow={myArrow}  showEmptySlots className='itemcrouselcard' pagination={false} breakPoints={breakPoints}>

                //         {props.MovieList.filter((el)=>{return el.subcategory_id===subcategory}).map((item, index) => {
                //             return (

                //                  <div className='latestcrouselimagediv' style={{width:'100%', height: '199px' }}>

                //                     <CrouselCard  item={item} /> 
                //                      <MoviDetailPage moviedetailstyle={moviedetailstyle} item={item}  />
                //                  </div>
                //             )
                //         })}
                //     </Carousel>

                // </div>
                : ""}
        </Fragment>
    )
}
const mapStateToProps = state => ({
    // GetMoviListofuserbysubcategory: state.UploadVideoListByCategoryReducer.GetMoviListofuserbysubcategory,
    // getmovielistbysubvategoryloder: state.UploadVideoListByCategoryReducer.getmovielistbysubvategoryloder,

    // getmovieContentType: state.UploadVideoListByCategoryReducer.getmovieContentType,
    // getmovieContentTypeLoder: state.UploadVideoListByCategoryReducer.getmovieContentTypeLoder,
})

const mapDispatchToProps = dispatch => ({
    GetmovieListByUserIdHandler: data => dispatch(GetmovieListByUserId(data)),

    GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data))

})

export default connect(mapStateToProps, mapDispatchToProps)(MultiItemListCrousel);
