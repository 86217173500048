import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
// import {GetmovieListByUserId} from '../../../../store/action/TalentHuntVideoList';
import MultiItemListCrousel from './multiItemListCrousel';
import { GetmovieListByUserId, GetmovieContentType } from '../../store/action/TalentHuntVideoList';
import ItemsCarousel from 'react-items-carousel';
import movie1 from '../assets/upcoming/03.jpg';
import { Modal, Button } from 'antd';
import PlyrComponent from 'plyr-react';
import talenthuntvideo from '../assets/video/talenthuntvideo.mp4';
import { PlayCircleOutlined } from '@ant-design/icons';

import './SubCategoryListCrousel.scss';


const SubCategoryListCrousel = (props) => {
    const ref = useRef()

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const videoList = props.subcategoryList;
    console.log(videoList)
    const [modal2Visible, setmodal2Visible] = useState(false)
    // console.log(videoList)
    // console.log(props.category_name)

    useEffect(() => {

    }, [])
    const videomodelCancel = () => {
        console.log(ref.current.plyr)
        ref.current.plyr.pause();
        setModal2Visible(false)
    }
    const setModal2Visible = (modal2Visible) => {
        // this.setState({ modal2Visible });
        setmodal2Visible(modal2Visible)
    }

    return (
        <Fragment>

            {videoList === 'Error' || videoList === 'Empty List' ? "Data Empty" : videoList.map((item, index) => {
                return (
                    <>
                        <div className='container-fluid  mt-2 mb-3 crouselheader' style={{ paddingLeft: '40px', paddingRight: '' }}>
                            <Button type="primary" onClick={() => setModal2Visible(true)} className='m-0 crouselheaderheader' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px', display: 'flex' }}>{item.name} <PlayCircleOutlined /></Button>
                        </div>
                        <MultiItemListCrousel
                            subcategoryname={item.subcategory_id} category_name={props.category_id}
                            MovieList={props.GetMoviListofuserbysubcategory}
                            movieListLoder={props.getmovielistbysubvategoryloder} />
                    </>
                )
            })}
            <Modal
                centered
                visible={modal2Visible}
                onOk={() => setModal2Visible(false)}
                onCancel={videomodelCancel}

            >
                <PlyrComponent ref={ref}
                    source={{
                        type: "video",
                        sources: [
                            {
                                src: `${talenthuntvideo}`
                            }
                        ],
                        quality: [
                            { default: 576, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240, "default"] }
                        ]
                    }}
                />
            </Modal>
        </Fragment>
    )
}
const mapStateToProps = state => ({
    GetMoviListofuserbysubcategory: state.UploadVideoListByCategoryReducer.GetMoviListofuserbysubcategory,
    getmovielistbysubvategoryloder: state.UploadVideoListByCategoryReducer.getmovielistbysubvategoryloder,

})

const mapDispatchToProps = dispatch => ({
    // GetmovieListByUserIdHandler: data => dispatch(GetmovieListByUserId(data)),
    //  GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data))

})
export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryListCrousel);
