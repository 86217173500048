import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { GetShowDetailByShowid } from '../../../../store/action/SeriesTabAction';
import axios from 'axios';
import ItemsCarousel from 'react-items-carousel';
import { Link } from 'react-router-dom';
import EpisodeCrouselCard from './EpisodeCrouselCard';
import EpisodeCrouselDetailPage from './EpisodeCrouselDetailPage';
import watchlIstimage from '../../../../components/assets/menu/watchlIstimage.png'
import SharePopover from './shareButton';
import Loder from '../../../resuable/Loder';
import { Skeleton, notification } from 'antd';
import EpisodeList from './EpisodeList';
import {LOCAL_SERVER,PANCHANG_SERVER} from '../../../../store/config';
import ReadMorecomponent from '../../../resuable/ReadMorecomponent';
import { useParams } from 'react-router';
import './EpisodeDetailPage.css';

const EpisodeDetailPage = (props) => {
    const params = useParams()
    console.log(params)
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 0;

    // const [episodeList, setEpisodeList] = useState();
    // const showid = props.history.location.state.show_id;
    const showid=params.seriesid
    console.log(showid)
    // const seasonLength = props.history.location.state.seasonLength;
    // const data = props.getseriesdetailbyshowid;
    const [seasonButton, setSeasonButton] = useState()
    const [episodeList, setepisodeList] = useState([]);
    const [FirstseasonFirstEpiData, setFirstseasonFirstEpiData] = useState("");
    const [status, setStatus] = useState(false);
    const [seriesdata, setSeriesdata] = useState();
    const [mylistStatus, setMyliststatus] = useState('0');
    const [seasonLength,setSeasonLength]=useState()


    const fetchwishListStatus = (id, cat_id) => {
        const data = {
            "user_id": localStorage.getItem('loginid'),
            "video_id": id,
            "category_id": cat_id
        }
        console.log(data)
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findwishlist_video_status`, data)
            .then(res => {
                if (res.data.msg === 'Find  Success') {
                    if (res.data.result.status === '0') {
                        setMyliststatus('0')
                    }
                    else {
                        setMyliststatus('1')
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })

    }
    useEffect(() => {
        setStatus(false)
        setepisodeList([])
        setFirstseasonFirstEpiData('')
        // props.GetShowDetailByShowidHandler(showid)
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findshowdetailbyshowid/${showid}`)
            .then(res => {
                console.log(res)
                if (res.data.msg === 'list find success') {
                    console.log(res)
                    // window.location.reload();
                    setSeriesdata(res.data.result)
                    setSeasonLength(res.data.result.seasonlist.length)
                    fetchwishListStatus(res.data.result.showdetail[0].id, res.data.result.showdetail[0].category_id)
                    const list = res.data.result.seasonlist.filter(el => {
                        return el.season_number ==="Season 1"
                    })
                    console.log(list)
                    if( res.data.result.seasonlist.length>0){
                    const id = list[0].id
                    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findEpisodeListBySeasonid/${id}`)
                        .then(res => {
                            if (res.data.msg === 'list find success') {
                                //  setSeasonButton('red')
                                console.log(res)
                                setepisodeList(res.data.result)
                                setFirstseasonFirstEpiData(res.data.result[0])
                                setStatus(true)
                                //   return ( <h5>{episodeList.length===0 ? "empty":`${episodeList.length}`}</h5>)

                            }
                            else if (res.data.msg === 'List Empty') {
                                //   setEpisodeList(res.data.msg)

                            }
                            else if (res.data.msg === 'Error') {
                                //   setEpisodeList(res.data.msg)

                            }
                        })
                        .catch(err => {
                            //   setEpisodeList('Error')
                        })
                    }
                    else{
                        console.log('empty season')
                    }
                }
                if (res.data.msg === "something Error") {

                }

            })

    }, [])
    const removewatchList = () => {
        const data = {
            "user_id": localStorage.getItem('loginid'),
            "video_id": seriesdata.showdetail[0].id,
            "category_id": seriesdata.showdetail[0].category_id,
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/delete_wishlist`, data)
            .then(res => {
                if (res.data.msg == "Delete  Success") {
                    setMyliststatus('0')
                    notification['success']({
                        message: 'Remove Video From MYLIST!'
                    });
                }
                else {
                    notification['error']({
                        message: 'Something Error Try Again!'
                    });
                }
            })
            .catch(err => {
                console.log(err)
                notification['error']({
                    message: 'Something Error Try Again!'
                });
            })


    }
    const addwatchList = () => {
        const data = {
            "user_id": localStorage.getItem('loginid'),
            "video_id": seriesdata.showdetail[0].id,
            "category_id": seriesdata.showdetail[0].category_id,
            "subcategory_id": seriesdata.showdetail[0].subcategory_id,
            "status": "1"
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/add_wishlist`, data)
            .then(res => {
                if (res.data.msg === 'Crate watch List') {
                    setMyliststatus('1')
                    notification['success']({
                        message: 'ADD VIDEO IN MYLIST!'
                    });
                }
                else {
                    notification['error']({
                        message: 'Something Error Try Again!'
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    console.log(props.getseriesdetailbyshowid)
    return (
        <Fragment>
            {status === true ?
                <div className='container episodedetailpage mt-3'>
                    <div className='row mt-3 flex-column-reverse flex-lg-row'>
                        <div className='col-sm-12 col-md-pull-6 col-md-6 text-white'>
                            <nav aria-label=" bg-none">
                                <ol class="d-flex p-0 space_lines">
                                    <li class=""><Link to="/">Home</Link>
                                        <i class="fas fa-angle-double-right"></i>
                                    </li>
                                    <li class=""><Link to="/web-series">Series</Link>
                                        <i class="fas fa-angle-double-right"></i>
                                    </li>
                                    <li class="" aria-current="page">{seriesdata.showdetail[0].title}</li>
                                </ol>
                            </nav>
                            <h4 className='episodename'>
                                {seriesdata.showdetail[0].title}
                            </h4>
                            <div className='episodedetaildiv'>

                                <span>{seasonLength} Season</span>
                            </div>
                            <div className='mt-4 episodedetaildiv'>
                                {!FirstseasonFirstEpiData ? "" :
                                    <Link
                                        to={{
                                            pathname: '/adminVideoPlayer',
                                            state: {
                                                item: FirstseasonFirstEpiData,
                                            }
                                        }}
                                        className='ant-btn btn-success'>Play S1 E1</Link>
                                }
                                <span className='ml-3'>
                                
                                    {mylistStatus === '0' ?
                                        <span onClick={addwatchList} className='ml-2 add_watch text-success'><i class="fas fa-heart text-success"></i> Add Watch list</span>
                                        :
                                        <span onClick={removewatchList} className='ml-2 remove_watch text-danger'><i class="fas fa-trash-alt text-danger"></i> Remove from watch list</span>
                                    }

                                </span>
                                <SharePopover />
                                {/* <span className='ml-3'>
                                    <img src={sharelink} width='20px' height='20px' />
                                    <span className='ml-2'>Share</span>
                                </span> */}
                            </div>
                            <div className='mt-5'>
                                Language:<span className='languagespan' style={{ color: '#007bff' }}>
                                    {seriesdata.language.map((item, inde) => {
                                        return " " + item
                                    })}
                                </span>
                            </div>
                            <div className='episodedetaildivdesc mt-3'>
                                Genre:<span className='languagespan' style={{ color: '#007bff' }}>
                                    {seriesdata.contenttype.map((item, inde) => {
                                        return " " + item
                                    })}
                                </span>
                            </div>
                            {/* <div className='episodedetaildivdesc'>
                            <span>Year: 2021</span>
                        </div> */}
                            <div className='episodedetaildivdesc mt-3'>
                            <ReadMorecomponent text={seriesdata.showdetail[0].description.replace(/<\/?p[^>]*>/g, "")} />

                                {/* <div class="read-more mb-12 mb-5" >
                                    <p class="read-more__text mb-2">
                                    {seriesdata.showdetail[0].description.replace(/<\/?p[^>]*>/g, "")}
                                        {seriesdata.showdetail[0].description}
                                    </p>
                                    <label for="read-more-checkbox" class="read-more__label" style={{ color: '#007bff', boxShadow: 'none' }} data-read-more="Read more" data-read-less="See less" aria-hidden="true"></label>
                                </div> */}
                                {/* <p>
                                {data.showdetail[0].description}
                            </p> */}
                            </div>

                        </div>
                        <div className='col-sm-12 col-md-push-6 col-md-6 mt-2 movie_image_col'>
                            <div className='movie_pic'>
                                <img className='movie_image' src={`http://admin.kidzjoy.org${seriesdata.showdetail[0].banner_path}`}
                                    width='100%' height='100%'
                                />
                            </div>
                        </div>
                    </div>

                    <EpisodeList seasonlist={seriesdata.seasonlist} setFirstseasonFirstEpiData={setFirstseasonFirstEpiData}
                        episodeList={episodeList} setepisodeList={setepisodeList}
                    />

                </div>
                :
                <div className='container episodedetailpage mt-3'>
                    <div className='row mt-2'>
                        <div className='col-sm-12 col-md-pull-6 col-md-6 text-white'>
                            <Skeleton active />
                        </div></div></div>
            }
        </Fragment>
    )
}
const mapStateToProps = state => ({
    getseriesdetailbyshowid: state.SeriesTabReducer.getseriesdetailbyshowid,
    getseriesdetailbyshowidLoder: state.SeriesTabReducer.getseriesdetailbyshowidLoder,
})

const mapDispatchToProps = dispatch => ({
    GetShowDetailByShowidHandler: data => dispatch(GetShowDetailByShowid(data)),

})
export default connect(mapStateToProps, mapDispatchToProps)(EpisodeDetailPage);


