import React, { useState, useEffect, Fragment } from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../../../store/config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SeriesTabCrouselCard from '../../../../Pages/SeriesPage/SeriesTabCrouselCard';
import SeriesTabDetailPage from '../../../../Pages/SeriesPage/SeriesTabDetailPage';
import ItemsCarousel from 'react-items-carousel';


function ShowHomeCrousel() {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [crouselList, setCrouselList] = useState([]);
    const chevronWidth = 15;
    const moviedetailstyle = {
        top: '-60%'
    }
    useEffect(() => {
        VideoLstapi()
        console.log('latest')
    }, [])
    const VideoLstapi = async () => {

        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getshowList`)
            .then(res => {
                if (res.data.msg === 'show List Find') {
                    console.log(res.data.result)
                    setCrouselList(res.data.result)
                }
                else {
                    console.log(res.data)
                }
            })
    }
    return (
        <Fragment>
            <div className='container-fluid   mb-3 crouselheader d-flex' style={{ marginTop: '60px', paddingLeft: '15px', paddingRight: '15px', justifyContent: 'space-between' }}>
                <h4 className='m-0 crouselheaderheader text-uppercase' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px' }}>Web Shows</h4>
                <Link to='/web-series' className='btn btn-primary'>View All</Link>
            </div>
            <div className='crouselcontainer MovieCrousel' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={6}
                    gutter={20}
                    leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                    rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                    chevronWidth={chevronWidth}
                >
                    {crouselList && crouselList.map((item, index) => {
                        return (
                            <>
                                <Link
                                    to={{
                                        pathname: `/series/${item.title.replace(/ /g,"-")}/${item.id}` ,
                                        state: {
                                            show_id: item.id,
                                            seasonLength: !item.seasonid ? "Comming Soon" : item.seasonid.length
                                        }
                                    }}
                                >
                                    <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                        <SeriesTabCrouselCard item={item} />
                                        <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                    </div>
                                </Link>
                            </>

                        )
                    })}
                </ItemsCarousel>
            </div>
            <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={3}
                    gutter={10}
                    leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                    rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                    chevronWidth={chevronWidth}
                >
                    {crouselList.map((item, index) => {
                        return (

                            <>
                                <Link
                                    to={{
                                        pathname: `/series/${item.title.replace(/ /g,"-")}/${item.id}` ,
                                        state: {
                                            show_id: item.id,
                                            seasonLength: !item.seasonid ? "comming Soon" : item.seasonid.length
                                        }
                                    }}
                                >
                                    <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                        <SeriesTabCrouselCard item={item} />
                                        <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                    </div>
                                </Link>
                            </>

                        )
                    })}
                </ItemsCarousel>
            </div>

        </Fragment>
    )
}

export default ShowHomeCrousel;
