import React,{useState} from 'react';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';


export default function UpdateFormValidation() {
    const [uploadsuccessLoder, setUploadsuccessLoder] = useState(false)
    const [category_id, setcategory_id] = useState('')
    const [categoryList, setCategoryList] = useState(null)
    const [contentType, setContentType] = useState(null);
    const [successuploadmsg, setsuccessuploadmsg] = useState('');
    const [updateVideoFormUi, setUpdateVideoFormUi] = useState(false)
    const [statusupload, setstatusupload] = useState(false);
    const [subcatList, setsubcatList] = useState(null)
    const [state, setState] = useState({
        title: "", desc: "", videofile: "", subcategory: '', category: '', imagefilename: '',
        video_path: '', image_path: "", videofilename: "", filenamepath: "", contenttype: [],
        selectoption: null, video_id: '', videofilepath: '', videoimagepath: ""
    })
    const [contentTypelist, setContentTypelist] = useState(
        [
            // { value: 'sadMusic', label: 'Sad Music' },
            // { value: 'math', label: 'Math' },
            // { value: 'englishsong', label: 'English Song' }
        ]
    )

    const findcategory = () => {
        console.log('category')
        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/uploadvideocategory`)
            .then(res => {
                console.log(res)
                setCategoryList(res.data.result)
                setstatusupload(true)
            })
            .catch(err=>{
                console.log(err)
            })
    }
   
    const handleChangeinput = (e) => {
        console.log(e.target.value)
        setState({
            ...state,
            // ,filenamepath:[e.target.value],videofilename:e.target.value,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'category') {
            setcategory_id(e.target.value)
            const data = {
                category_id: e.target.value
            }
            axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getsubCategoryListbbycatid/${data.category_id}`)
                .then(res => {
                     console.log(res)
                    if (res.data.result.length > 0) {
                        setsubcatList(res.data.result)
                    }
                })
        }
        if (e.target.name === 'subcategory') {
            const subcatdata = {
               // category_id: category_names,
                subcategory_id: e.target.value
            }
            axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/videosubcategorycontenttype`, subcatdata)
                .then(res => {
                    console.log(res)
                    if (res.data.result.length > 0) {
                        setContentTypelist(
                            res.data.result.map((item, index) => {
                                return (
                                    { value: item.conttype_id, label: item.name }
                                    // { value: 'math', label: 'Math' },
                                    // { value: 'englishsong', label: 'English Song' }
                                )
                            })

                        )
                    }

                })
        }

    }
    const contenttypeHandlechange = (selectoption) => {
        console.log(selectoption)
        setContentType(selectoption)
    }
    return {findcategory,categoryList,statusupload,state,contenttypeHandlechange,handleChangeinput,setState,subcatList,contentTypelist,
        setContentTypelist,setsubcatList,contentType, setContentType,setstatusupload}
}
