import React, { Fragment, useState, useEffect } from 'react';
import UploadVideoPage from '../../Pages/UploadVideoPage/UploadVideoPage';
import UploadVideoList from '../../Pages/UploadVideoList/UploadVideoList';
import DashoardContent from './DashoardContent';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import { Link } from 'react-router-dom';
import './DashBoard.css';

export default function DashBord(props) {
    const pathname = window.location.pathname
    // const status=props.location.state.status
    // console.log(props.location.state.status)
    // const [movieList,setMoviList]=useState()
    const [studenetCornerList, setStudentCornerList] = useState()
    const [talentHuntMovieList, settalentHuntMovieList] = useState();
    const user_type = localStorage.getItem('user_type')
    // const getmovielist=()=>{
    //     const user_id=localStorage.getItem('loginid')
    //     axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/videolist/${user_id}`)
    //     .then(res=>{
    //         if(res.data.msg==='find success'){
    //         console.log(res.data.result)
    //         setMoviList(res.data.result)
    //         }
    //         // setupdateloder(false)
    //     })
    // }
    const videoloistclick = () => {
        // getmovielist()
    }
    const dashboardbutton = () => {
        const data = {
            user_id: localStorage.getItem('loginid'),
            category_id: 9
        }
        const studentcornerdata = {
            user_id: localStorage.getItem('loginid'),
            category_id: 10

        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getuploadmovieListbycategoryid`, data)
            .then(res => {
                if (res.data.msg === 'list find success') {
                    settalentHuntMovieList(res.data.result)
                }
                else {
                    settalentHuntMovieList(0)
                }
            })

        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getuploadmovieListbycategoryid`, studentcornerdata)
            .then(res => {
                if (res.data.msg === 'list find success') {
                    setStudentCornerList(res.data.result)
                }
                else {
                    setStudentCornerList(0)
                }
            })




    }
    useEffect(() => {
        dashboardbutton()
    }, [])
    const uploadbutton = () => {

    }
    return (
        <Fragment>
            <div className='container-fluid mt-3 p-0 desktopviewdashboard' style={{ minHeight: '400px', fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', fontSize: '15px' }}>
                <div class="nav flex-column nav-pills shadow-sm dashboardsidenavbar" style={{ width: '' }} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <Link to='/dashboardContent' className={pathname === '/dashboardContent' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={dashboardbutton} id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i class="las la-home iq-arrow-left"></i>&nbsp; &nbsp;Dashboard</Link>
                    <Link to='/uploadPage' className={pathname === '/uploadPage' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={dashboardbutton} id="v-pills-profile-tab " data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <i class="fas fa-upload"></i>&nbsp; &nbsp;Upload Your Video</Link>
                    <Link to='/uplodmovieList' className={pathname === '/uplodmovieList' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={videoloistclick} id="v-pills-uploadvideolist-tab " data-toggle="pill" href="#v-pills-uploadvideolist" role="tab" aria-controls="v-pills-uploadvideolist" aria-selected="false">
                        <i class="las la-eye"></i> Manage Your Video</Link>
                    <Link to='/watchlist' className={pathname === '/watchlist' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={videoloistclick} id="v-pills-uploadvideolist-tab " data-toggle="pill" href="#v-pills-uploadvideolist" role="tab" aria-controls="v-pills-uploadvideolist" aria-selected="false">
                        <i class="fa fa-heart" aria-hidden="true"></i>&nbsp; &nbsp;My Watch List</Link>
                    {user_type === "1" || user_type === "3" ? "" :
                        <>
                            <Link to='/accountdetail' className={pathname === '/accountdetail' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={videoloistclick} id="v-pills-uploadvideolist-tab " data-toggle="pill" href="#v-pills-uploadvideolist" role="tab" aria-controls="v-pills-uploadvideolist" aria-selected="false">
                                <i class="fa fa-solid fa-plus" aria-hidden="true"></i>&nbsp; &nbsp; Manage Bank Details</Link>
                            <Link to='/banklist' className={pathname === '/banklist' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={videoloistclick} id="v-pills-uploadvideolist-tab " data-toggle="pill" href="#v-pills-uploadvideolist" role="tab" aria-controls="v-pills-uploadvideolist" aria-selected="false">
                                <i class="fa fa-list" aria-hidden="true"></i>&nbsp; &nbsp; Bank Account List</Link>
                            <Link to='/earninghistory' className={pathname === '/earninghistory' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={videoloistclick} id="v-pills-uploadvideolist-tab " data-toggle="pill" href="#v-pills-uploadvideolist" role="tab" aria-controls="v-pills-uploadvideolist" aria-selected="false">
                                <i class="fa fa-history" aria-hidden="true"></i>&nbsp; &nbsp;Earning History</Link>
                            <Link to='/withdrawpage' className={pathname === '/withdrawpage' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={videoloistclick} id="v-pills-uploadvideolist-tab " data-toggle="pill" href="#v-pills-uploadvideolist" role="tab" aria-controls="v-pills-uploadvideolist" aria-selected="false">
                                <i class="fa fa-solid fa-plus" aria-hidden="true"></i>&nbsp; &nbsp;Send Money to Bank</Link>
                            <Link to='/withdrawhistory' className={pathname === '/withdrawhistory' ? `nav-link active mt-3 navlinkdashboard pl-0 pr-0` : `nav-link  mt-3 navlinkdashboard pl-0 pr-0`} onClick={videoloistclick} id="v-pills-uploadvideolist-tab " data-toggle="pill" href="#v-pills-uploadvideolist" role="tab" aria-controls="v-pills-uploadvideolist" aria-selected="false">
                                <i class="fa fa-history" aria-hidden="true"></i>&nbsp; &nbsp;Withdrawal History</Link>
                        </>

                    }
                </div>

            </div>
            <div className='container-fluid mobileviewdashboard'>
                <div className='row'>
                    <div className='col-12'>
                        <button className='btn text-white  mt-3 mr-4' style={{ float: 'right' }}>
                            Menu
                        </button>
                    </div>
                </div>
                <div className='row'>
                </div>
            </div>

            {/* <div className='container-fluid mt-3 desktopviewdashboard' style={{minHeight:'400px', fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '600' }}>
                <div class="nav flex-column nav-pills shadow-sm dashboardsidenavbar" style={{ width: '' }} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class={`nav-link active   mt-3 navlinkdashboard`} onClick={dashboardbutton} id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i class="las la-home iq-arrow-left"></i>  Dashboard</a>
                    <a class={`nav-link  navlinkdashboard`} id="v-pills-profile-tab " data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <i class="fas fa-upload"></i>  Upload Your Video</a>
                    <a class="nav-link navlinkdashboard" onClick={videoloistclick} id="v-pills-uploadvideolist-tab " data-toggle="pill" href="#v-pills-uploadvideolist" role="tab" aria-controls="v-pills-uploadvideolist" aria-selected="false">
                        <i class="las la-eye"></i> Manage Your Video</a>
                </div>
                <div class="tab-content dashboardsidecontent" id="v-pills-tabContent" style={{ fontWeight: '400', textAlign: '-webkit-center' }}>
                    <div class="tab-pane active fade show " id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className='container-fluid'>
                            <DashoardContent
                             />
                        </div>
                    </div>
                    <div class={`tab-pane fade `} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <UploadVideoPage />                    </div>
                    <div class="tab-pane fade mt-3 pl-3 pr-3" id="v-pills-uploadvideolist" role="tabpanel" aria-labelledby="v-pills-uploadvideolist-tab" style={{ background: "" }}>
                        <UploadVideoList 
                        />  
                    </div>
                </div>
            </div>
            <div className='container-fluid mobileviewdashboard'>
                <div className='row'>
                    <div className='col-12'>
                        <button className='btn text-white  mt-3 mr-4' style={{ float: 'right' }}>
                            Menu
                        </button>
                    </div>
                </div>
                <div className='row'>
                </div>
            </div> */}
        </Fragment>
    )
}
