import React, { Fragment, useEffect, useState } from 'react';
import DashBord from './index';
import { useSelector, useDispatch } from 'react-redux';
import AuthServices from '../../../Services/authServices';



export default function DashoardContent(props) {
    const walletamount = useSelector((state) => state.UserWalletRudeser.userWalletamount);
    const user_type = useSelector((state) => state.AuthRudeser.user_type);
    const [userType, setUserType] = useState('');
    const [promocode, setPromocode] = useState('');
    const [promousername, setpromousername] = useState();
    const [promouserpromocode, setpromouserpromocode] = useState();
    const user_types = localStorage.getItem('user_type')

    const fetchpromouserDetail = async (id) => {
        const apicall = await AuthServices.FetchUserPromoDetail(id)
        console.log(apicall)
        if (apicall.state === false) {
            if (apicall.response.data.success === true) {
                setpromousername(apicall.response.data.result[0].name)
                setpromouserpromocode(apicall.response.data.result[0].promoter_code)
            }
        }
        else {
            console.log('error')
        }
    }
    const fetchUserDetail = async () => {
        const apicall = await AuthServices.FetchUserDetail()
        console.log(apicall)
        if (apicall.state === false) {
            if (apicall.response.data.success === true) {
                setUserType(apicall.response.data.result[0].type)
                setPromocode(apicall.response.data.result[0].promoter_code)
                fetchpromouserDetail(apicall.response.data.result[0].promoter_code_user_id)
            }
        }
        else {
            console.log('error')
        }
    }
    useEffect(() => {
        fetchUserDetail()
    }, [])
    // console.log(props.studenetCornerList)
    // console.log(props.talentHuntMovieList)
    return (
        <Fragment>
            <div className='container-fluid'  >
                <div className='row'>
                    <div className='col-2'>
                        <DashBord />
                    </div>
                    <div className='col-10 mt-4'>
                        <div class="iq-card w-50  mt-1 iq-card-block iq-card-stretch iq-card-height p-4" style={{ background: "rgb(21, 21, 21)" }} >
                            <div class="iq-card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="iq-cart-text text-capitalize">
                                        <p class="mb-0" style={{ color: '#D1D0CF' }}>
                                            User Stats
                                        </p>
                                    </div>
                                    <div class="icon iq-icon-box-top" style={{ display: 'flex', justifyContent: 'center', width: '', height: '', background: "", color: '#ffff' }}>
                                        {userType}
                                        {/* <i class="las la-download" style={{ fontSize: '24px', margin: 'auto' }}></i>       */}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between mt-3">
                                    <h4 class=" mb-0 text-white">
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {user_types === "1" || user_types === "3" ? "" :
                            <div class="iq-card w-50  mt-1 iq-card-block iq-card-stretch iq-card-height p-4" style={{ background: "rgb(21, 21, 21)" }} >
                                <div class="iq-card-body">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="iq-cart-text text-capitalize">
                                            <p class="mb-0" style={{ color: '#D1D0CF' }}>
                                                Earnings
                                            </p>
                                        </div>
                                        <div class="icon iq-icon-box-top" style={{ display: 'flex', justifyContent: 'center', width: '', height: '', background: "", color: '#ffff' }}>
                                            {walletamount}
                                            {/* <i class="las la-download" style={{ fontSize: '24px', margin: 'auto' }}></i>       */}
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between mt-3">
                                        <h4 class=" mb-0 text-white">
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        }
                        {user_types === "1" || user_types === "3" ? "" :
                            <div class="iq-card w-50 mt-1 iq-card-block iq-card-stretch iq-card-height p-4" style={{ background: "rgb(21, 21, 21)" }} >
                                <div class="iq-card-body">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="iq-cart-text text-capitalize">
                                            <p class="mb-0" style={{ color: '#D1D0CF' }}>
                                                Your Promoter Code
                                            </p>
                                        </div>
                                        <div class="icon iq-icon-box-top" style={{ display: 'flex', justifyContent: 'center', width: '', height: '', background: "", color: '#ffff' }}>
                                            {promocode}
                                            {/* <i class="las la-download" style={{ fontSize: '24px', margin: 'auto' }}></i>       */}
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between mt-3">
                                        <h4 class=" mb-0 text-white">

                                        </h4>
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="iq-card w-50 mt-1 iq-card-block iq-card-stretch iq-card-height p-4" style={{ background: "rgb(21, 21, 21)" }} >
                            <div class="iq-card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="iq-cart-text text-capitalize">
                                        <p class="mb-0" style={{ color: '#D1D0CF' }}>
                                            Joined Through
                                        </p>
                                    </div>
                                    <div class="icon iq-icon-box-top" style={{ display: 'flex', justifyContent: 'center', width: '', height: '', background: "", color: '#ffff' }}>
                                        {promousername} / {promouserpromocode}
                                        {/* <i class="las la-download" style={{ fontSize: '24px', margin: 'auto' }}></i>       */}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between mt-3">
                                    <h4 class=" mb-0 text-white">

                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
