import React, { useEffect, useState } from 'react';
import AuthServices from '../../Services/authServices';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Walletsubtable from './walletsubtable';
import DashBord from '../HomeLayout/UserDashboard/index';
import "./walltetable.scss";
import api from '../../api';
export default function Walltetable() {
   const [userWallet, setUserWallet] = useState(0);
   const [userpromocode, setuserpromocode] = useState();
   const [username, setUserName] = useState('')
   const [EarningHistory, setEarningHistory] = useState([])
   const [tabledata, setTabledata] = useState([]);
   const [showsublist,setshowsublist]=useState()

   const fetchpromotercode = async () => {
      const apicall = await AuthServices.FetchUserDetail()
      if (apicall.state === false) {
         if (apicall.response.data.success === true) {
            setuserpromocode(apicall.response.data.result[0].promoter_code)
         }
         else {

         }
      }
   }
   const fetchEarningHistory = async () => {
      const apicall = await AuthServices.EarningHistory()
      console.log(apicall)
      if (apicall.state === false) {
         if (apicall.response.data.success === true) {
            if (apicall.response.data.userWallet.length > 0) {
               setUserWallet(apicall.response.data.userWallet[0].wallet_amount)
               setUserName(apicall.response.data.userWallet[0].name)
               setuserpromocode(apicall.response.data.userWallet[0].promoter_code)
               setEarningHistory(apicall.response.data.earninghistory)
            }
            else {
               fetchpromotercode()
            }
         }
         else {
            fetchpromotercode()
         }
      }
      else {
         fetchpromotercode()
      }
   }
   const fetchsublistdata = async (e, el, index) => {
      if(index===showsublist){
         setshowsublist()
      }
      else{
         setTabledata([])
         console.log('sublist')
         const apidata = {
            promocodeuser_id: el.promocode_userid,
            schooluser_id: el.user_id
         }
   
         console.log(apidata)
         const callapi = await AuthServices.fetchSubEarningHistory(apidata)
         console.log(callapi)
         if (callapi.state === false) {
            if (callapi.response.data.success === true) {
               if (callapi.response.data.earninghistory.length > 0) {
                  // amount=callapi.response.data.earninghistory[0].school_promoter_commision
                  setTabledata(callapi.response.data.earninghistory)
                  setshowsublist(index)
               }
               else {
                  setTabledata([])
               }
            }
         }

      }
   }
   useEffect(() => {
      fetchEarningHistory()
   }, [])
   return (
      <div className='container-fluid earning_history_container'>
         <div className='row'>
            <div className='col-md-2 d-sm-none d-md-block d-lg-block'>
               <DashBord />
            </div>
            <div className='col-md-10  mt-2'>
               <div className='container'>
                  <div className='row'>
                     <div className='msg_main'>
                        <div className='card_msg'>
                           <div className='logged_kidzjoy'>
                              <div className='logged_one'>
                                 <p>Your Promoter Code: <span style={{ color: '#00dcd4' }}>{userpromocode}</span></p>
                              </div>
                              {EarningHistory.length > 0 ?
                                 <div className='logged_two'>
                                    <Link to='/withdrawpage'><p style={{ color: '#00dcd4' }}>Send Money to Bank </p></Link>
                                 </div>
                                 :
                                 <div className='logged_two'>
                                    <p className='text-right d-block'>Wallet: <span style={{ color: '#00dcd4' }}>{userWallet} INR</span></p>
                                 </div>

                              }
                           </div>
                           {EarningHistory.length > 0 ?
                              <div className='logged_kidzjoy'>

                                 <div className='logged_two text-right w-100'>
                                    <p className='text-right d-block'>Wallet: <span style={{ color: '#00dcd4' }}>{userWallet} INR</span></p>
                                 </div>
                              </div>
                              : ""}
                        </div>
                        <div className='card_msg mt-5'>
                           <div className='kidz_table'>
                              <div className='head_tbl br_white'>
                                 <div className='s_no_one'>
                                    <p>S.No</p>
                                 </div>
                                 <div className='s_no_one'>
                                    <p>User Type</p>
                                 </div>
                                 <div className='s_no_one'>
                                    <p>Name</p>
                                 </div>
                                 <div className='s_no_one'>
                                    <p>Amount</p>
                                 </div>
                                 <div className='s_no_one'>
                                    <p>Joining Date</p>
                                 </div>
                                 <div className='s_no_one'>
                                    <p>Expiry Date</p>
                                 </div>
                              </div>
                              {EarningHistory.length > 0 ?
                                 <>
                                    {EarningHistory.map((el, index) => {
                                       return (
                                          <>
                                             <div key={index} className='head_tbl'>
                                                <div className='s_no_two'>
                                                   <p>{index + 1}</p>
                                                </div>
                                                <div className='s_no_two'>
                                                   <p>{el.usertype_value}</p>
                                                </div>
                                                <div className='s_no_two'>
                                                   <p
                                                      onClick={(e) => fetchsublistdata(e, el, index)}
                                                      data-toggle="collapse" href={`${`#multiCollapseExample1${index}`}`} role="button" aria-expanded="false" aria-controls={`${`multiCollapseExample1${index}`}`}><i class="fas fa-plus"></i> {el.name}
                                                      </p>
                                                </div>
                                                <div className='s_no_two'>
                                                   <p>{el.promocodeusercommision} INR</p>
                                                </div>
                                                <div className='s_no_two'>
                                                   <p>{
                                                      el.subscription_start_date
                                                      // moment(el.created_at).format("dddd, MMMM Do YYYY")
                                                   }
                                                   </p>

                                                </div>
                                                <div className='s_no_two'>
                                                   <p>{
                                                      el.subscription_end_date
                                                      // moment(el.created_at).format("dddd, MMMM Do YYYY")
                                                   }
                                                   </p>
                                                </div>
                                             </div>
                                                   {showsublist ===index ?
                                                   // <div className='text-white'>subList {tabledata.length}</div>
                                                   <>
                                                   {tabledata.map((item, index) => {
                                                      return (
                                                         <div>
                                                            <div className='kidz_table sub_one'>
                                    
                                                               <div className='head_tbl'>
                                                                  <div className='s_no_two'>
                                                                     <p>1</p>
                                                                  </div>
                                                                  <div className='s_no_two'>
                                                                     <p><i class="fas fa-plus"></i> {item.name}</p>
                                                                  </div>
                                                                  <div className='s_no_two'>
                                                                     <p>{item.school_promoter_commision} INR</p>
                                                                  </div>
                                                                  <div className='s_no_two'>
                                                                     <p>
                                                                        {moment(item.created_at).format("dddd, MMMM Do YYYY")}
                                                                     </p>
                                                                  </div>
                                                               </div>
                                                            </div>
                                    
                                                         </div>
                                                      )
                                                   })}
                                    
                                                </>
                                                   :""
                                                }
                                             {/* <Walletsubtable data={el} tabledata={tabledata} /> */}
                                          </>
                                       )
                                    })}

                                 </>

                                 :
                                 <div className='text-center p-2'>
                                    <span className='text-white'>Empty Data</span>
                                 </div>
                              }

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   )
}