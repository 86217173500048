
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetmovieContentType } from '../../store/action/TalentHuntVideoList';
import { Modal, Button } from 'antd';
import './MovieDetail.css'

const MoviDetailPage = (props) => {
     console.log(window.innerWidth)
    useEffect(() => {
        const data = {
            video_id: props.item.id,
            subcategory_name: props.item.subcategory_name
        }
        //  props.GetmovieContentTypeHandler(data)
        // const data = {
        //     video_id: props.item.id,
        //     category_id: props.item.category_id
        // }
        // axios.post('http://api.kidsflixx.com/api/findVideoDetailbycategory&id',data)
        // .then(res=>{
        //     console.log(res.data)
        //     setVideoDetail(res.data)
        //     setTimeout(()=>{
        //         setLoder(true)
        //     },500)
        // })
    }, [])
    return (
        <Fragment>

             <div className='container-fluid '>
                <div className='row'>
                    <div className='col-12 p-0'>
                        
                        <div class="card-details" style={{fontSize:'14px'}}>
                            <h3 class="text-overflow card-header pl-0 " style={{color:'#00dcd4',fontWeight:'400',fontSize:'14px'}}>{props.item.title}</h3>
                            <div class="text-overflow card-subheader text-white" style={{fontSize:'14px'}}>
                               contentype {/* {videoDetail.contenttype.map((item,index)=>{
                                        return " " + item
                                    })},&nbsp;
                                     {videoDetail.detail[0].year} */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='mobilemoviedetailhover  container-fluid' style={{}}>
                <div className='row'>
                    
                    <div className='col-12 pl-4 pt-3'>
                        <h6 className='moviedetailtitle'>{props.item.title}</h6>

                        <div class="hover-buttons">
                            <Link
                                to={{
                                    pathname: '/video',
                                    state: {
                                        item: props.item,
                                    }
                                }}
                            >
                                <span className="btn btn-hover p-1" style={{ background: '#007bff', color: '#fff', padding: '', borderRadius: '0px' }}>
                                    <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                    Play Now
                                   </span>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            <div className='moviedetailhover container-fluid' style={{}}>
                <div className='row'>
                    
                    <div className='col-12 pl-4 pt-3'>
                        <h6 className='moviedetailtitle'>{props.item.title}</h6>

                        <div class="hover-buttons">
                            <Link
                                to={{
                                    pathname: '/video',
                                    state: {
                                        item: props.item,
                                    }
                                }}
                            >
                                <span className="btn btn-hover p-1" style={{ background: '#007bff', color: '#fff', padding: '', borderRadius: '0px' }}>
                                    <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                    Play Now
                                   </span>
                            </Link>
                        </div>
                    </div>

                </div>
            </div> */}
        </Fragment>
    )
}
// const mapStateToProps = state => ({
//     getmovieContentType: state.UploadVideoListByCategoryReducer.getmovieContentType,
//     getmovieContentTypeLoder: state.UploadVideoListByCategoryReducer.getmovieContentTypeLoder,
// })

// const mapDispatchToProps = dispatch => ({
//     GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data)),

// })
export default MoviDetailPage
// connect(mapStateToProps, mapDispatchToProps)
// (MoviDetailPage)
