import api from "../api/index";


var MusicPlayListServices={
    addplaylistItem: function (body) {
        // let token = localStorage.getItem("user_token");
        // const headers = { Authorization: `${token}` };
        return api
            .post("create_user_item_playlist", body,)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    },
    deletPlylistItem: function (body) {
        // let token = localStorage.getItem("user_token");
     const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
     };
        return api
            .post("deleteplaylistItem_by_userid",body)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    return { response: response, state: false };
                } else {
                    return { response: response, state: true };
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    return { response: error.response, state: true };
                } else if (error.request) {
                    console.log(error.request);
                    return { response: null, state: true };
                } else {
                    console.log("Error", error.message);
                    return { response: null, state: true };
                }
            });
    }
}

export default MusicPlayListServices;
