import React, { Fragment, useState, createElement } from 'react'
import { Comment, Tooltip, List, Avatar, Menu, Dropdown, Modal, Button, Form, Input, message, Space } from 'antd';
import moment from 'moment';
import commentServices from '../../../Services/CommentService';
import { DislikeOutlined, DownOutlined, LikeOutlined, DislikeFilled, LikeFilled } from '@ant-design/icons';
import './commentPage.scss';


function CommentList(props) {
    const [form] = Form.useForm();
    const { comments,FetchCommentList } = props
    const [likes, setLikes] = useState(0);
    const [dislikes, setDislikes] = useState(0);
    const [action, setAction] = useState(null);
    const [editCmtModel, setEditCmtModel] = useState(false);
    const [commentDetail,setcommentDetail]=useState();
    const loginId=localStorage.getItem('loginid')
    const EditModalVisible = (e, item, modal2Visible) => {
        console.log(item)
        setcommentDetail(item)
        setEditCmtModel(modal2Visible);
    }
   
    const EditComment = async(text) => {
        const apidata={
            comment_id:commentDetail.id,
            comment_text:text,
            category_id:props.category_id
        }
        console.log(apidata)
       const  data=await commentServices.EditComment(apidata)
        console.log(data)
        if(data.state===false){
            if(data.response.data.msg==='Comment Update Success'){
                FetchCommentList()
                setEditCmtModel(false)
            }
            else{
                console.log('Error')
            }
        }
    }
    const deletetComment = async(e, item) => {
        console.log(item)
        const apidata={
            comment_id:item.id,
            category_id:props.category_id

        }
        console.log(apidata)
       const  data=await commentServices.DeleteComment(apidata)
        console.log(data)
        if(data.state===false){
            if(data.response.data.msg==='Delete Success'){
                FetchCommentList()
            }
            else{
                console.log('Error')
            }
        }
    }
    const like = () => {
        setLikes(1);
        setDislikes(0);
        setAction('liked');
    };
    const dislike = () => {
        setLikes(0);
        setDislikes(1);
        setAction('disliked');
    };
    const actions = [
        <Tooltip key="comment-basic-like" title="Like">
            <span onClick={like}>
                {createElement(action === 'liked' ? LikeFilled : LikeOutlined)}
                <span className="comment-action">{likes}</span>
            </span>
        </Tooltip>,
        <Tooltip key="comment-basic-dislike" title="Dislike">
            <span onClick={dislike}>
                {React.createElement(action === 'disliked' ? DislikeFilled : DislikeOutlined)}
                <span className="comment-action">{dislikes}</span>
            </span>
        </Tooltip>,
        <span key="comment-basic-reply-to">Reply to</span>,
    ];
    const onFinish = (value) => {
        console.log(value)
        EditComment(value.comment_text)
        
        //console.log(commentDetail)
        message.success('Submit success!');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };
    return (
        <Fragment>
            {comments.map((item, index) => {
                return (
                    <Comment
                        actions={
                            [
                                // <Tooltip key="comment-basic-like" title="Like">
                                //     <span onClick={like}>
                                //         {createElement(action === 'liked' ? LikeFilled : LikeOutlined)}
                                //         <span className="comment-action">{item.like}</span>
                                //     </span>
                                // </Tooltip>,
                                // <Tooltip key="comment-basic-dislike" title="Dislike">
                                //     <span onClick={dislike}>
                                //         {React.createElement(action === 'disliked' ? DislikeFilled : DislikeOutlined)}
                                //         <span className="comment-action">{item.unlike}</span>
                                //     </span>
                                // </Tooltip>,
                                // <span key="comment-basic-reply-to">Reply to</span>,
                            ]
                        }
                        author={<a style={{ color: '#ffff' }}>{item.user_name}</a>}
                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />}
                        content={
                            <p style={{ color: '#ffff' }}>
                                {item.comment_text}
                            </p>
                        }
                        datetime={[
                            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                                {/* <span>{moment(item.date).utcOffset(330).fromNow()}</span> */}
                                <span>{moment(item.date).utcOffset("+05:30").fromNow()}</span>

                            </Tooltip>,
                            loginId=== item.user_id ? 
                            <Dropdown overlay={
                                <Menu>
                                    <Menu.Item key="0">
                                        <a onClick={(e) => EditModalVisible(e, item, true)}>Edit</a>
                                    </Menu.Item>
                                    <Menu.Item key="1">
                                        <a onClick={(e) => deletetComment(e, item)}>Delete</a>
                                    </Menu.Item>
                                    <Menu.Divider />
                                </Menu>
                            } trigger={['click']}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    {/* Click me <DownOutlined /> */}
                                    <i class="fas fa-ellipsis-v"></i>
                                </a>
                            </Dropdown>
                            :''
                        
                        ]
                        }
                    />
                )
            })}

            <Modal destroyOnClose
                title="Edit Your Comment"
                centered
                visible={editCmtModel}
                //   onOk={() => this.setModal2Visible(false)}
                onCancel={() => setEditCmtModel(false)}
            >
                <Form preserve={false}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="comment_text"
                        
                        // initialValues={!commentDetail.comment_text ? '':commentDetail.comment_text}
                        rules={[{ required: true }]}
                    >
                        <Input  placeholder="Comment here..." />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>

                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

        </Fragment>
    )
}

export default CommentList;
