import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MovieMultiItemListCrousel from './MovieMultiListCrousel';
import {Link} from 'react-router-dom';
import './MovieSubCategoryListCrousel.scss';



const MovieSubCategoryListCrousel = (props) => {
    console.log(props.subcategoryList)
    // const [activeItemIndex, setActiveItemIndex] = useState(0);
    // const chevronWidth = 40;
    // const videoList = props.subcategoryList;
    // console.log(videoList)
    useEffect(() => {

    }, [])

    return (
        <Fragment>
            {props.subcategoryList.length>0 ?  props.subcategoryList.map((item, index) => {
                return (
                    
                    <>
                    {item.movielist.length>0 ? 
                    <>
                        <div className='container-fluid   mb-3 crouselheader d-flex' style={{marginTop:'60px',justifyContent:'space-between' }}>
                            <h4 className='m-0 crouselheaderheader text-uppercase' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px' }}>{item.name}</h4>
                            <Link to={`Watch-movie/${item.name}/${item.id}`} className='btn btn-primary'>View All</Link>
                        </div>

                        <MovieMultiItemListCrousel
                        moviList={item.movielist}
                     />
                     </>
                     :"Loading..."
                        }
                    </>
                    
                )
            })
        :"Loading...."
        }

        </Fragment>
    )
}
// const mapStateToProps = state => ({
//     getMovieDetailList: state.MovieTabReducer.getMovieDetailList,
//     getMovieDetailListLoder: state.MovieTabReducer.getMovieDetailListLoder,

// })

// const mapDispatchToProps = dispatch => ({
// })
// export default connect(mapStateToProps, mapDispatchToProps)(MovieSubCategoryListCrousel);
export default MovieSubCategoryListCrousel;
