import React, { Fragment, useState } from 'react';
// import MovieTabCrouselCard from './MovieTabCrouselCard';
import ItemsCarousel from 'react-items-carousel';
import TalentHuntTabCrouselCard from './TalentHuntTabCrouselCard';
import TalentHuntTabCrouselDetail from './TalentHuntTabCrouselDetail';
import {Link} from 'react-router-dom';
// import MovieTabDetailPage from './MovieTabDetailPage';

function TalentHuntMultiListCrousel(props) {
    const { moviList } = props
    console.log(props.moviList)
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 15;
    return (
        <Fragment>
            <div className='crouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={6}
                    gutter={10}
                    leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                    rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                    // outsideChevron
                    chevronWidth={chevronWidth}
                >
                    {moviList.map((item, index) => {
                        return (
                            // <Link to={{
                            //     pathname: `/talenthunt/${item.title.replace(/ /g,"-").toLowerCase()}/${item.id}`
                            // }}>
                            <Link to={`/talenthuntdetail/${item.title.replace(/ /g,"-").toLowerCase()}/${item.id}`}
                            
                            >
                                <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                    <TalentHuntTabCrouselCard item={item} />
                                    <TalentHuntTabCrouselDetail item={item} />
                                </div>
                            </Link>

                        )
                    })}
                </ItemsCarousel>
            </div>
            <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={3}
                    gutter={10}
                    leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                    rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                    chevronWidth={chevronWidth}
                >
                    {moviList.map((item, index) => {
                        return (
                            <Link to={`/talenthuntdetail/${item.title.replace(/ /g,"-").toLowerCase()}/${item.id}`}
                            >
                                <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                    <TalentHuntTabCrouselCard item={item} />
                                    <TalentHuntTabCrouselDetail item={item} />
                                </div>
                            </Link>
                        )
                    })}
                </ItemsCarousel>
            </div>
        </Fragment>
    )
}

export default TalentHuntMultiListCrousel
