import React, { Fragment, useState, useEffect } from 'react';
import imagetrendinglabel from '../../assets/trending/trending-label.png';
import Vplayer from '../../resuable/VideoPlayer/Vplayer';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Modal, Button, message, Checkbox, Form, notification, Space, Input } from 'antd';
import {
    PlusOutlined
} from '@ant-design/icons';
import VideoMusicListValidation from './VideoMusicListValidation';
import MusicPlayListServices from '../../../Services/MusicPlayListServices';
import axios from 'axios';
import ReadMorecomponent from '../../resuable/ReadMorecomponent';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import './VideoMusicdetailPage.scss';


function VideoMusicdetailPage(props) {
    const history = useHistory()
    const [UserPlayList,setUserPlayList]=useState([])
    const { fetchAudiMusicList, getplaylistbyuserId, deleteplaylistbyuserId, UserPlayListitem, AudioMusicList } = VideoMusicListValidation(setUserPlayList)
    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState(true);
    const params = useParams();
    const [MusicDetail, setMusicDetail] = useState()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [createplaylistform, setcreateplaylistform] = useState(false)
    const [playlistname, setplaylistname] = useState('');
    const [videoidchange,setVideoidChange]=useState(false)
    const [startPLayer, setStartPlayer] = useState(false);

    const item = MusicDetail
    console.log(item)

    const showModal = async() => {
      await  getplaylistbyuserId(params.id)
        setIsModalVisible(true);
    };
    const addplaylistitem = async (itemdetail) => {
        const apidata = {
            user_id: localStorage.getItem('loginid'),
            playlist_id: itemdetail.id,
            content_id: params.id,
            category_id: item.category_id,
            subcategory_id: item.subcategory_id,
            playlist_name: itemdetail.playlist_name
        }
        const data = await MusicPlayListServices.addplaylistItem(apidata)
        console.log(data)
        if (data.state === false) {
            if (data.response.data.msg === "PlayList Item Create Success") {
                console.log(data.response.data)
                notification['success']({
                    message: 'Add Song In Playlist!'
                  });
                  getplaylistbyuserId(params.id)
            }
            else{
                notification['error']({
                    message: 'Something Error!'
                  });
            }
        }
    }
    const RemovePlayListItem=async(itemdetail)=>{
        const apidata={
            user_id: localStorage.getItem('loginid'),
            playlist_id:itemdetail.playListitem_id
        }
        console.log(apidata)
        const data = await MusicPlayListServices.deletPlylistItem(apidata)
        if (data.state === false) {
            if (data.response.data.msg ==="delete Success") {
                console.log(data.response.data)
                notification['success']({
                    message: 'Remove Song From Playlist!'
                  });
                  getplaylistbyuserId(params.id)
            }
            else{
                notification['error']({
                    message: 'Something Error!'
                  });
            }
        }

    }
    const handleOk = () => {
        setIsModalVisible(false);
        setcreateplaylistform(false)

    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setcreateplaylistform(false)
    };

    const playlistonChange = (e, el) => {
        console.log(`checked = ${e.target.checked}`);
        console.log(el)
        if (e.target.checked === true) {
            setCheckedList(true)
            addplaylistitem(el)
        }
        else {
            // deleteplaylistbyuserId(id)
            setCheckedList(false)
        }

    }
    const watchlateronchange = (e) => {
        console.log(`Watch Later = ${e.target.checked}`);
        if (e.target.checked === true) {
            message.success('Saved To Watch Later');

        }
        else {
            message.info('Remove From Watch Later');

        }

    }
    useEffect(() => {
        const id = '16'
        fetchAudiMusicList(id)
        const data = {
            song_id: params.id
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/video_music_detailby_id`, data)
            .then(res => {
                if (res.data.msg === 'list find success') {
                    console.log(res)
                    setMusicDetail(res.data.result[0])
                    setTimeout(()=>{
                        setVideoidChange(true)
                    },[])
                }
            })
            .catch(err => {
                console.log(err)
            })
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
              }
    }, [])
  
    const createplaylistbutton = () => {
        setcreateplaylistform(true)
    }
    const onFinish = () => {
        console.log('finish')
        message.success('Added PlayList!');
        //  setIsModalVisible(false);
        //  setcreateplaylistform(false)
    };
    const onFinishFailed = () => {
        console.log('failed')

        message.error('Submit failed!');
    };

    const createplaylistSubmitButton = () => {
        console.log('create')
    }
    const onCheck = async () => {
        // console.log(playlistname)
        try {
            const values = await form.validateFields();
            console.log(values)
            const playlistdata = {
                user_id: localStorage.getItem('loginid'),
                playlist_name: values.playlistname

            }
            axios.post('https://api.kidzjoy.org/api/create_user_playlist', playlistdata)
                .then(res => {
                    if (res.data.msg === "PlayList Create Success") {
                        const playlistitem = {
                            user_id: localStorage.getItem('loginid'),
                            playlist_id: res.data.result.id,
                            content_id: item.id,
                            category_id: item.category_id,
                            subcategory_id: item.subcategory_id,
                            playlist_name: values.playlistname
                        }
                        axios.post('https://api.kidzjoy.org/api/create_user_item_playlist', playlistitem)
                            .then(response => {
                                if (response.data.msg === 'PlayList Item Create Success') {
                                    getplaylistbyuserId(params.id)
                                    console.log(res)
                                    console.log('Success:', values);
                                    message.success('Added PlayList!');
                                    setIsModalVisible(false);
                                    setcreateplaylistform(false)
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })

                    }
                })
                .catch(err => {
                    console.log(err)
                })


        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };
    const cardClickButton = (item) => {
        setStartPlayer(false)
        setVideoidChange(false)
        history.push(`/video-play/${item.title.replace(/ /g,"-")}/${item.id}`)
        setMusicDetail(item)
        setTimeout(()=>{
            setVideoidChange(true)
            // setStartPlayer(true)
        },1000)
       


    }
    const onChange=(e,item)=> {
        console.log('checked = ', e.target.value,e.target.checked);
        if(e.target.checked===true){
            addplaylistitem(item)
        }
        else{
            RemovePlayListItem(item)
        }
      }

    return (
        <Fragment>
            {MusicDetail ?
                <div className='container-fluid video_detail_page_container'>
                    <div className='row  music_detail_page_player_div_row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-7 music_detail_page_player_div_col'>
                            {videoidchange===true ?
                            <div className='player_div'>
                            <Vplayer startPLayer={startPLayer} setStartPlayer={setStartPlayer} videoidchange={videoidchange} detail={item} song_path={item.audio_video_path} />

                            </div>
                            :""}
                            <div className='video_detail'>
                                <div className='detail_div'>
                                    <h1 className='text-white'>{item.title}</h1>
                                    <div className="block-social-info " style={{ float: 'right' }}>
                                        <ul className="list-inline p-0 m-0 d-flex justify-content-start align-items-baseline">
                                            <li className=''><h6 className='text-white'>{item.release_date}</h6></li>
                                        </ul>
                                        <ul className="list-inline p-0 m-0 music-play-lists d-flex">

                                            <li className=' '><span onClick={showModal} className='socialsymbel'>
                                                <i className="ri-add-line "></i>
                                            </span>  </li>
                                            <li className=''><span className='socialsymbel'><i className="ri-share-fill "></i></span></li>

                                        </ul>
                                    </div><br></br>
                                    <div className="d-flex align-items-center text-white text-detail movietimingtext">
                                        <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.category_name}</span>
                                        <span className=" mr-2"><i className="fas fa-circle" style={{ background: "#00dcd4", color: '#00dcd4', borderRadius: '50%', border: 'none' }}></i>&nbsp;{item.subcategory_name}</span>
                                    </div><br></br>
                                    {/* <div className="d-flex align-items-center series">
                                        <a href="#"><img src={imagetrendinglabel} className="img-fluid" alt="" /></a>
                                        <span className="text-gold ml-3" style={{ color: '#E6C75F', fontWeight: '600' }}>#2 in Series Today</span>
                                    </div> */}
                                    <div className="mt-2 align-items-center series vd_dscprs">
                                        <p className='text-white mt-3'>
                                        <ReadMorecomponent text={item.description
                                            // .replace(/<\/?p[^>]*>/g, "")
                                            } />

                                            {/* {item.description} */}
                                        </p>
                                        {/* <p className='text-white'><b>Show More</b></p> */}
                                    </div>
                                    {/* <div className="block-social-info ">
                                    <ul className="list-inline p-0 m-0 music-play-lists d-flex">

                                        <li className='mr-2 '><span onClick={showModal} className='socialsymbel'>
                                            <i className="ri-add-line "></i>
                                        </span>  </li>
                                        <li className='mr-2'><span className='socialsymbel'><i className="ri-heart-fill "></i></span></li>
                                        <li className='mr-2'><span className='socialsymbel'><i className="ri-share-fill "></i></span></li>

                                    </ul>
                                </div> */}
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-12 col-lg-5 side_list_music'>
                            <div className='wishlist_all_sec'>
                                    <div className='btn_vmdp'>
                                        <div className='all_vmdp'>
                                            <button>All</button>
                                        </div>
                                        <div className='wishlist_dorp_vmdp'>
                                        <div class="form-group">
                                            <select class="form-control slct_vmdp"
                                            style={{ background: '#1a1a1a', color: 'white' }}>

                                                <option>Wishlist</option>
                                                <option>Music</option>
                                                <option>Audio</option>
                                                <option>Video</option>
                                            
                                            </select> 
                                        </div>
                                        </div>
                                    </div>
                                </div>
                    
                            {AudioMusicList && AudioMusicList.map((item, index) => {
                                return (
                                    //     <Link
                                    //     to={{
                                    //         pathname: `/video-play/${item.id}`,
                                    //         item: item
                                    //     }} style={{ textDecoration: 'none' }}
                                    // >
                                    <div className={` card_div ${params.id === `${item.id}` ? 'd-none' : 'd-flex'}`} onClick={() => cardClickButton(item)}>
                                        <div className='image_side_div '>
                                            <img src={`http://admin.kidzjoy.org${item.image_path}`} width='100%' alt='image' />
                                        </div>
                                        <div className='card_detail_div'>
                                            <h3 className='text-white'>{item.title}</h3>
                                            <h6 className='text-white'>Singer: <span className='text-white'>{item.singer_name}</span></h6>
                                            <p className='text-white mb-0'>Duration: {item.duration}</p>
                                            <p className='text-white mt-2'>
                                            <ReadMorecomponent text={item.description.replace(/<\/?p[^>]*>/g, "")} />
                                            </p>
                                        </div>
                                    </div>
                                    // </Link>
                                )
                            })}

                        </div>

                    </div>

                </div>
                : 
                <div className='loding_div'>
                    
                </div>
                }
            <Modal destroyOnClose className='watch_popup' title={
                <div className='d-flex mt-4' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <h3 className='text-white'>Save Too</h3>

                    </div>
                    {createplaylistform === false ?
                        <div className='text-center'>
                            <Button type="primary" icon={<PlusOutlined />} size={`large`} onClick={createplaylistbutton}>
                                Create PlayList
                            </Button>
                        </div>
                        : ''}

                </div>
            } footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {/* <ul>
                    <Checkbox onChange={watchlateronchange}>Watch Later</Checkbox>

                </ul> */}
                {/* <ul>
                    {UserPlayList.length > 0 ?
                        <>
                            {UserPlayList.map((el, index) => {
                                return (
                                    <li key={index}>
                                        <Checkbox checked={el.addplaylist===true ?checkedList : false} onChange={(e) => playlistonChange(e, el.id)}>{el.playlist_name}</Checkbox>
                                    </li>
                                )
                            })}
                        </>
                        : ""
                    }

                </ul> */}
                <ul>
                    {UserPlayList.length > 0 ?
                        <>
                            <Checkbox.Group style={{ width: '100%' }}  defaultValue={UserPlayList.map(el=>(
                                el.addplaylist===false ? '':el.id
                            ))} >

                                {UserPlayList.map((el, index) => {
                                    return (
                                        <li key={index}>
                                            <Checkbox value={el.id} onChange={(e)=>onChange(e,el)}>{el.playlist_name}</Checkbox>
                                        </li>
                                    )
                                })}
                            </Checkbox.Group>
                        </>
                        : ""
                    }
                </ul>
                {/* {createplaylistform===false ?
                <div className='text-center'>
                    <Button type="primary" icon={<PlusOutlined />} size={`large`} onClick={createplaylistbutton}>
                        Create PlayList
                    </Button>
                </div>
                :''} */}
                {createplaylistform === false ? '' :
                    <div>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onCheck}
                            onFinishFailed={onFinishFailed}

                        //   initialValues={{
                        //     requiredMarkValue: requiredMark,
                        //   }}
                        //   onValuesChange={onRequiredTypeChange}
                        //   requiredMark={requiredMark}
                        >

                            <Form.Item name='playlistname' label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Field is Required'
                                    },
                                    // {
                                    //   type: 'text',
                                    //   required: true,
                                    // },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}

                            >
                                <Input placeholder="Enter Playlist Name..." />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={onCheck}>Submit</Button>
                            </Form.Item>
                        </Form>
                    </div>
                }
            </Modal>
        </Fragment>
    )
}

export default VideoMusicdetailPage;
