import React, { Fragment } from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';

const SeriesTabCrouselCard = (props) => {
    console.log(props.item)
    return (
        <Fragment>
            
          
                        <img className='' 
                        src={`http://admin.kidzjoy.org${props.item.image_path}`} type='file/png'
                        height='100%' width='100%'></img>

        </Fragment>


    )
}
export default SeriesTabCrouselCard;
