import React, { useRef, Fragment, useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import PlyrComponent from 'plyr-react';
import './TalentHuntTabCrouselCard.scss';
function SubcategoryIntroCrousel(props) {
    const moviList = props.subcategoryList
    const ref = useRef()

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 15;
    const videoList = props.subcategoryList;
    console.log(videoList)
    const [modal2Visible, setmodal2Visible] = useState(false)
    const [subcatvideo, stSubcatvideo] = useState();
    const videomodelCancel = () => {
        console.log(ref.current.plyr)
        ref.current.plyr.pause();
        setModal2Visible(false)
    }
    const setModal2Visible = (modal2Visible, path) => {
        stSubcatvideo(path)
        setmodal2Visible(modal2Visible)
    }
    return (<Fragment>
        <div className='container-fluid   mb-3  d-flex talenthunt_crousel_header' style={{ marginTop: '60px', justifyContent: 'space-between' }}>
            <h4 className='m-0 crouselheaderheader text-uppercase' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>Introductions To Talent Hunt</h4>
            <Link to={`/uploadPage`} className='btn btn-primary'>Upload Your Video</Link>
        </div>
        <div className='container-fluid crouselcontainer mt-5' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={6}
                gutter={10}
                leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                // outsideChevron
                chevronWidth={chevronWidth}
            >
                {moviList.map((item, index) => {
                    return (

                        <div onClick={() => setModal2Visible(true, item.file_path)} className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                            <img className='itemimagetag' style={{ borderRadius: '3px', boxShadow: "0 2px 5px 0 rgb(34 34 34 / 40%)" }}
                                // src={`${item.image_url ? :`}
                                src={`http://admin.kidzjoy.org${item.file_paths}`}
                                type='file/png'
                                height='300px' width='100%'>
                            </img>
                            <h3 className='text-white' style={{ fontSize: '15px', fontWeight: '400', padding: '.75rem 0px' }}>{item.name}</h3>

                        </div>

                    )
                })}
            </ItemsCarousel>
        </div>
        <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={3}
                gutter={10}
                leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                chevronWidth={chevronWidth}
            >
                {moviList.map((item, index) => {
                    return (
                        <div onClick={() => setModal2Visible(true, item.file_path)} className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                            <img className='itemimagetag' style={{ borderRadius: '3px', boxShadow: "0 2px 5px 0 rgb(34 34 34 / 40%)" }}
                                src={`http://admin.kidzjoy.org${item.file_paths}`}
                                type='file/png'
                                height='300px' width='100%'>
                            </img>
                            <h3 className='text-white' style={{ fontSize: '15px', fontWeight: '400', padding: '.75rem 0px' }}>{item.name}</h3>
                        </div>
                    )
                })}
            </ItemsCarousel>
        </div>
        <Modal
            centered
            // title="Vertically centered modal dialog"
            visible={modal2Visible}
            onOk={() => setModal2Visible(false)}
            onCancel={videomodelCancel}
            footer={[
                <button className='btn btn-danger mr-2' onClick={videomodelCancel}>
                    Cancel
                </button>,

                <Link
                    key="link"
                    to="/uploadPage"
                    className='btn btn-primary'
                //   loading={loading}
                //   onClick={this.handleOk}
                >
                    Upload Video
                </Link>,
            ]}


        >
            <PlyrComponent ref={ref}
                source={{
                    type: "video",
                    sources: [
                        {
                            src: `http://admin.kidzjoy.org${subcatvideo}`
                        }
                    ],
                    quality: [
                        { default: 576, options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240, "default"] }
                    ]
                }}
            />
        </Modal>
    </Fragment>
    )
}

export default SubcategoryIntroCrousel;
