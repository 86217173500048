import React, { Fragment, useEffect, useState } from 'react';
// import './subCategoryVideoListPage.scss';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';
import MovieTabCrouselCard from '../..//MovieTab/MovieTabCrouselCard';
import MovieTabDetailPage from '../../MovieTab/MovieTabDetailPage';
import { Link } from 'react-router-dom';
import {LOCAL_SERVER,PANCHANG_SERVER} from '../../../../store/config';
import axios from 'axios';
const { Title } = Typography;

function ShortFilmsSubCategoryListPage() {
    const params = useParams()
    const [VideoList, setVideoList] = useState([])
    console.log(params.name)
    const moviedetailstyle = {
        // top: '-70%'
    }
    useEffect(() => {
        const data = {
            subcategory_id: params.id
        }
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/find_short_film_video_detail_bysubcategory_name`, data)
            .then(res => {
                if (res.data.msg === "list find success") {
                    setVideoList(res.data.subcategorylist)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    return (
        <Fragment>
            <div className='container-fluid crousel_all_movie_list_container'>
                <div className='row'>
                    <div className='col-12 crousel_all_movie_list_col'>
                        <Title level={2}></Title>
                        <div class="main">
                            <ul class="cards">
                                {VideoList && VideoList.map((item, index) => {
                                    return (
                                        <li className='card_list my-5'>
                                            <Link
                                                to={{
                                                    pathname: `/movies/${item.title}/${item.id}`

                                                }} style={{ textDecoration: 'none' }}
                                            >
                                                <MovieTabCrouselCard item={item} />
                                                <MovieTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ShortFilmsSubCategoryListPage;
