import { combineReducers } from "redux";
import SignupUserReducerReducer from './signUpReducer';
import LoginUserReducerReducer from './loginSignUpReducer';
import UploadVideoListByCategoryReducer from './TalentHuntVideoListReducer';
import MovieTabReducer from './MovieTabReducer';
import SeriesTabReducer from './SeriesTabReducer';
import ShortMovieTabReducer from './ShortFilmsReducer';
import StudentCornerMovieTabReducer from './studentCornerReducer';
import WatchListReducer from './watchListReducer';
import {AuthRudeser} from '../Auth/AuthReducer';
import {UserWalletRudeser} from '../UserWallet/UserWalletReducer';


import {UserBankAccountReducer} from '../UserBankAccountList/useraccountReducer';

export const rootReducer = combineReducers({
    SignupUserReducerReducer, // signup Reducer,
    LoginUserReducerReducer, // login Reducer
    UploadVideoListByCategoryReducer, // Upload Video List By category
    MovieTabReducer, // Upload movie List Reducer
    SeriesTabReducer, // series tab reducer
    ShortMovieTabReducer, // shortFilms Reducer
    StudentCornerMovieTabReducer, // studentcorner Reducer
    WatchListReducer ,// watclist Reducer

    AuthRudeser, // Auth Reducer,
    UserWalletRudeser,// User Wallet Reducer

    UserBankAccountReducer,// UserBankAccountList
});