import {CREATE_USER_ACCOUNT} from '../constatnt';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../config';


export const SignUpUser =(data)=>async(dispatch,getState)=>{
    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/createsignupuser`,data)
    .then(res=>{
        console.log(res)
        if(res.data.msg==='User Account Create'){
            console.log(res)
            // window.location.reload();
            dispatch( {
                type:CREATE_USER_ACCOUNT,
                data:res.data.result
            })
        }
        if(res.data.msg==='Account not Created'){
            dispatch( {
                type:CREATE_USER_ACCOUNT,
                data:'Account Not Created'
            })
        }
     if(res.data.msg==="password create wrongt"){
            dispatch( {
                type:CREATE_USER_ACCOUNT,
                data:"Password wrong"
            })
        }
        // if(res){
        //     dispatch( {
        //         type:CREATE_USER_ACCOUNT,
        //         data:"Password wrong"
        //     })
        // }
 
     })


} 