import {GET_SHORT_MOVIE_LIST,GET_SHORT_MOVIE_DETAIL_LIST} from '../constatnt';

const initState={
   getShortmovieListSubCategory:'',
   getShortmovieListSubCategoryLoder:false,
   getShortMovieDetailList:false,
   getShortMovieDetailListLoder:false
}



const ShortMovieTabReducer=(state=initState,action)=>{
    
    switch(action.type){
       
        case GET_SHORT_MOVIE_LIST:
            console.log(action.data)
            console.log(' movie tab CategoryUploadvideoList Reducer')
            return{
                ...state,
                getShortmovieListSubCategory:action.data,
                getShortmovieListSubCategoryLoder:true,
            }
            case GET_SHORT_MOVIE_DETAIL_LIST:
                console.log(action.data)
                console.log('movie list Reducer')
                return{
                    ...state,
                    getShortMovieDetailList:action.data,
                    getShortMovieDetailListLoder:true,
                }
              
        default:
        return state;
    }
}

 export default ShortMovieTabReducer;