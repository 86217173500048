import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import HomeCrouselitemList from './HomeCrouselitemList';
import {LOCAL_SERVER,PANCHANG_SERVER} from '../../../../../store/config';
import './LatestVideoCrousel.scss';

function HomeCrouselTypeList() {
    const [videoTypelist, setVideotypelist] = useState([]);
    const [crouselshow, setCrouselshow] = useState();
    const [crouselcatid, setcrouselid] = useState([])
    const FetchcrouselTypeList = () => {
        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/HomePagevideoTypeConfig`)
            .then(res => {
                if (res.data.msg === 'List Find Success') {
                    console.log(res)
                    setVideotypelist(res.data.data)
                }
                else {
                    console.log('Error')
                }
            })
    }
    useEffect(() => {
        FetchcrouselTypeList()
    }, [])
    return (
        <>
            {videoTypelist && videoTypelist.map((item, index) => {
                return (
                    <>
                            <div key={index} className={index===crouselcatid ? `d-none`:''} >
                                <div className='container-fluid   mb-3 crouselheader d-flex' >
                                    <h4 className='m-0 crouselheaderheader text-uppercase'>{item.videotype} {item.category_name}</h4>
                                    <Link to={`list/${item.category_id}/${item.videotype.replace(/ /g,"-")}`} className='btn btn-primary'>View All</Link>
                                </div>
                                <HomeCrouselitemList category_id={item.category_id} index={index} setcrouselid={setcrouselid} crouselcatid={crouselcatid} videotype={item.videotype} setCrouselshow={setCrouselshow} />
                            </div>
                        
                    </>
                )
            })}

        </>
    )
}

export default HomeCrouselTypeList;
