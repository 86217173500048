import { AuthConstant } from './AuthConstants';

const initialState = {
    user_type: "",
    user_type_id:""
};

export const AuthRudeser = (state = initialState, { type, payload }) => {
    switch (type) {
        case AuthConstant.USER_TYPE:
            console.log(payload)
            return { ...state, user_type: payload.type,user_type_id:payload.type_id }
        default:
            return state;
    }
}


