import React,{useState} from 'react';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import axios from 'axios';
import { useForm,Controller } from 'react-hook-form';


export default function MovieListValidation() {
    const [updatedata,setupdatedata]=useState({
        id:"",
        title:"",category:"",subcategory:"",desc:""

    })
    const [show, setShow] = useState(false);
    // const [movieList,setMoviList]=useState()


    const [defaultValues,setdefaultValues]=useState({
        title:"",category:"",subcategory:"",desc:""
    })
    const [updateloder,setupdateloder]=useState(false)
    const { register, handleSubmit ,formState: { errors }} = useForm({
       // defaultValues
        defaultValues:{title:updatedata.title,category:updatedata.category,
            subcategory:updatedata.subcategory,desc:updatedata.desc
        }
})
    

    const [movielist,setmovielist]=useState(null)
   
    const handleClose = () => setShow(false);
    const updatebutton=(data)=>{
        // e.preventDefault();
        // console.log(data)
     }
    const getmovielist=()=>{
        const user_id=localStorage.getItem('loginid')
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/videolist/${user_id}`)
        .then(res=>{
            //console.log(res.data.result)
            setmovielist(res.data.result)
            // setupdateloder(false)
        })
    }
    const viewmoviebutton=(e,item)=>{
        e.preventDefault();
       // console.log(item.video)

    }
    const editmoviedetailButton=(e,item)=>{
        e.preventDefault();
        console.log(item)
        setupdatedata(
            item
        //     {
        //     item
        //     // id:item.id,
        //     // title:item.title,
        //     // category:item.category_id,
        //     // subcategory:item.subcategory_id,
        //     // desc:item.desc
        // }
        )

        setTimeout(()=>{
            
            localStorage.setItem('id',item.id)
            setupdateloder(true)
            // console.log(updatedata)
             setShow(true)
        },2000)
      
    }
    const deletmoviebutton=(e,item)=>{
        e.preventDefault();
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/deletemoviebyId/${item.id}`)
        .then(res=>{
            console.log(res)

            if(res.data.msg==="delete movie"){
                const deletedata={
                    video_id:item.id,
                    user_id:localStorage.getItem('loginid')
                }
                console.log(deletedata)
                axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/deletecontentypebyvideo_id/${item.id}`)
                .then(result=>{
                    console.log(result)
                    if(result.data.msg==='contenttype  deleted'){
                        getmovielist()
                    }
                })
            }
        })
    }


    return {viewmoviebutton,editmoviedetailButton,deletmoviebutton,movielist,
        register, handleSubmit,errors,updatebutton,updateloder,setupdateloder,defaultValues,
        setdefaultValues,updatedata,show,handleClose,setmovielist,getmovielist}
}
