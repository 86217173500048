import React, { Fragment, useEffect, useState } from 'react';
import VideoLikeUnLikeServices from '../../../Services/videoLikeUnlike/videoLikeUnlikeServices';
import './likedilike.scss';

function LikedislikePage(props) {
    console.log(props.detail)
    const [LikeNumber, setLikeNumber] = useState(0);
    const [UnLikeNumber, setUnLikeNumber] = useState(0);
    const [VideoLikeStatus, setVideolIkeStatus] = useState()
    const Fetchvideolikenumber = async () => {
        const apidata = {
            category_id: props.detail.category_id,
            content_id: props.detail.id
        }
        const fetchdata = await VideoLikeUnLikeServices.Videolikenumber(apidata)
        if (fetchdata.state === false) {
            if (fetchdata.response.data.msg === "Success") {
                setLikeNumber(parseInt(fetchdata.response.data.likeNumber))
                setUnLikeNumber(parseInt(fetchdata.response.data.unlikenumber))
            }
        }
    }
    const FetchVideoStatus = async () => {
        const apidata = {
            user_id: localStorage.getItem('loginid'),
            category_id: props.detail.category_id,
            content_id: props.detail.id
        }
        const fetchdata = await VideoLikeUnLikeServices.VideolikeStatus(apidata)
        if (fetchdata.state === false) {
            if (fetchdata.response.data.msg === "Success") {
                console.log(fetchdata)
                setVideolIkeStatus(fetchdata.response.data.result)
            }
            else {
                setVideolIkeStatus()
                console.log('empty status')
            }
        }
        else {
            console.log('Invalid Request')
        }
    }
    const adduserLikeButton = async () => {
        const apidata = {
            user_id: localStorage.getItem('loginid'),
            category_id: props.detail.category_id,
            content_id: props.detail.id,
            subcategory_id: props.detail.subcategory_id
        }
        const fetchdata = await VideoLikeUnLikeServices.addvideoLikeNumber(apidata)
        console.log(fetchdata)
        if (fetchdata.state === false) {
            if (fetchdata.response.data.msg === "Success") {
                FetchVideoStatus()
                Fetchvideolikenumber()
                console.log('add like')
                // console.log(fetchdata)
                // setVideolIkeStatus(fetchdata.response.data.result)
            }
            else {
                console.log('not add like')
            }
        }
        else {
            console.log('Invalid Request')
        }
    }
    const adduserdisLikeButton = async () => {
        const apidata = {
            user_id: localStorage.getItem('loginid'),
            category_id: props.detail.category_id,
            content_id: props.detail.id,
            subcategory_id: props.detail.subcategory_id
        }
        const fetchdata = await VideoLikeUnLikeServices.addvideodisLikeNumber(apidata)
        console.log(fetchdata)
        if (fetchdata.state === false) {
            if (fetchdata.response.data.msg === "Success") {
                FetchVideoStatus()
                Fetchvideolikenumber()
                console.log('add like')
                // console.log(fetchdata)
                // setVideolIkeStatus(fetchdata.response.data.result)
            }
            else {
                console.log('not add like')
            }
        }
        else {
            console.log('Invalid Request')
        }
    }
    const removeuserLikeButton = async () => {
        const apidata = {
            user_id: localStorage.getItem('loginid'),
            category_id: props.detail.category_id,
            content_id: props.detail.id,
            subcategory_id: props.detail.subcategory_id
        }
        const fetchdata = await VideoLikeUnLikeServices.removevideoLikeNumber(apidata)
        console.log(fetchdata)
        if (fetchdata.state === false) {
            if (fetchdata.response.data.msg === "Success") {
                FetchVideoStatus()
                Fetchvideolikenumber()
                console.log('remove like')
                // console.log(fetchdata)
                // setVideolIkeStatus(fetchdata.response.data.result)
            }
            else {
                console.log('not remove like')
            }
        }
        else {
            console.log('Invalid Request')
        }
    }
    const removeuserdisLikeButton = async () => {
        const apidata = {
            user_id: localStorage.getItem('loginid'),
            category_id: props.detail.category_id,
            content_id: props.detail.id,
            subcategory_id: props.detail.subcategory_id
        }
        const fetchdata = await VideoLikeUnLikeServices.removevideodisLikeNumber(apidata)
        console.log(fetchdata)
        if (fetchdata.state === false) {
            if (fetchdata.response.data.msg === "Success") {
                FetchVideoStatus()
                Fetchvideolikenumber()
                console.log('remove like')
                // console.log(fetchdata)
                // setVideolIkeStatus(fetchdata.response.data.result)
            }
            else {
                console.log('not remove like')
            }
        }
        else {
            console.log('Invalid Request')
        }
    }
    useEffect(() => {
        FetchVideoStatus()
        Fetchvideolikenumber()
    }, [])
    return (
        <Fragment>
            {VideoLikeStatus ?
                <ul className='liksdislike_div'>
                    {VideoLikeStatus.userlike === 1 ?
                        <li><i class="fas fa-thumbs-up" style={{ color: 'red' }} onClick={removeuserLikeButton}></i>{LikeNumber}</li>
                        :
                        <li><i class="fas fa-thumbs-up" onClick={adduserLikeButton}></i>{LikeNumber}</li>
                    }
                    {VideoLikeStatus.unlike === 1 ?
                        <li><i class="fas fa-thumbs-down" style={{ color: 'red' }} onClick={removeuserdisLikeButton}></i>{UnLikeNumber}</li>
                        :
                        <li><i class="fas fa-thumbs-down" onClick={adduserdisLikeButton}></i>{UnLikeNumber}</li>
                    }
                </ul>
                : 
                <ul className='liksdislike_div'>
                 <li><i class="fas fa-thumbs-up" onClick={adduserLikeButton}></i>{LikeNumber}</li> 
                 <li><i class="fas fa-thumbs-down" onClick={adduserdisLikeButton}></i>{UnLikeNumber}</li>  
                </ul>
            }

        </Fragment>
    )
}

export default LikedislikePage
