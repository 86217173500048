import React, { Fragment, useEffect, useState } from 'react';
// import './subCategoryVideoListPage.scss';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';
import StudentCornerDetailPage from '../StudentCornerDetailPage';
import StudentCornerTabCrouselCard from '../StudentCornerTabCrouselCard';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {LOCAL_SERVER,PANCHANG_SERVER} from '../../../../store/config';
const { Title } = Typography;

function StudentCornerSubCategoryListPage() {
    const params = useParams()
    const [VideoList, setVideoList] = useState([])
    console.log(params.name)
    const moviedetailstyle = {
        // top: '-70%'
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getstudentcornerlist/${params.subcategory_id}`)
            .then(res => {
                if (res.data.msg === "list find success") {
                    setVideoList(res.data.result)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    return (
        <Fragment>
            <div className='container-fluid crousel_all_movie_list_container'>
                <div className='row'>
                    <div className='col-12 crousel_all_movie_list_col'>
                        <Title level={2}></Title>
                        <div class="main">
                            <ul class="cards">
                                {VideoList && VideoList.map((item, index) => {
                                    return (
                                        <li className='card_list my-5'>
                                            <Link
                                                to={{
                                                    pathname: `/studentcorner/${item.subject.replace(/ /g,"-").toLowerCase()}/${item.id}`

                                                }} style={{ textDecoration: 'none' }}
                                            >
                                                <StudentCornerTabCrouselCard item={item} />
                                                <StudentCornerDetailPage item={item} />
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default StudentCornerSubCategoryListPage;
