import React, { Fragment } from "react";
import SignUpValidation from "../../Validation/SignUpValidation";
import { connect } from "react-redux";
import { SignUpUser } from "../../../store/action/signUpAction";
import OtpInput from "react-otp-input";
import "./signupPage.scss";

const SignUpPage = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    otpUi,
    verifyotpbutton,
    otpstate,
    verifyotphandlechange,
    otperror,
    otpverfiyui,
  } = SignUpValidation(props.SignUpUserHandler);
  return (
    <Fragment>
      {otpverfiyui === false ? (
        <div className='signuppagediv' style={{ padding: '' }}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" style={{ padding: '3rem', background: '#151515' }}>
            <div className="sign_form_heading">
              <h1 className="sign_form_heading_text text-white">Create Account</h1>
            </div>
            <div className="form-floating mb-3 ">
              {/* <label for="floatingInput">Name</label> */}
              <input
                type="email "
                className="form-control "
                id="floatingInput"
                placeholder="Enter Name"
                name="name"
                {...register("name", {
                  required: "Required",
                })}
              />
            </div>
            <div className="form-floating mb-3 ">
              {/* <label for="floatingInput">Email address</label> */}
              <input
                type="email "
                className="form-control logininputdiv"
                id="floatingInput"
                placeholder="name@example.com"
                name="email"
                {...register("email", {
                  required: "Required",
                })}
              />
            </div>

            <div className="form-group mb-3" style={{ display: "flex" }}>
              <select
                className="input-group-btn btn-default mr-2 logininputdiv"
                style={{
                  borderRadius: ".25rem",
                  border: "1px solid #dddddd",
                  color: "#6f7074",
                }}
                name="countrycode"
                {...register("countrycode", {
                  required: "Required",
                })}
              >
                <option value="91">+91</option>
                <option value="1">+1</option>
                <option value="1">+1</option>
              </select>
              <input
                type="text"
                className="form-control shadow-sm logininputdiv"
                name="mobileno"
                placeholder="Mobile No"
                {...register("mobileno", {
                  required: "Required",
                })}
              />
            </div>

            <div class="form-floating  mb-3">
              {/* <label for="floatingPassword">Password</label> */}
              <input
                type="password"
                className="form-control logininputdiv"
                id="floatingPassword"
                name="password"
                placeholder="Password"
                {...register("password", {
                  required: "Required",
                })}
              />
            </div>
            <div className="form-floating  mb-3">
              {/* <label for="floatingPassword">Confirm Password</label> */}
              <input
                type="password"
                className="form-control logininputdiv"
                id="floatingPassword"
                name="cnfpassowrd"
                placeholder="Confirm Password"
                {...register("cnfpassowrd", {
                  required: "Required",
                })}
              />
            </div>
            <div className="form-floating text-center mt-3">
              <button
                className="btn btn-primary  signupsubmitbutton"
                onClick={handleSubmit(onSubmit)}
              >
                SignUp
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div>

          <div style={{ padding: '20rem' }}>
            <form style={{ padding: '', background: '#151515' }}>
              <div className="form-floating mb-3">
                <h4 classNameName="text-center" style={{ color: '#ffff' }}>Create Your Account</h4>
              </div>
              <small className="form-text text-success  mb-2 " style={{}}>
                OTP Send Your Email
              </small>
              <div className="form-floating mb-3 ">
                <input
                  type="email "
                  className="form-control logininputdiv"
                  id="floatingInput"
                  placeholder="Enter OTP"
                  name="otp"
                  value={otpstate.otp}
                  onChange={verifyotphandlechange}
                />
              </div>
              <small className="form-text text-danger  mb-2 " style={{}}>
                {otperror}
              </small>
            </form>
            <div className="form-floating text-center mt-3">
              <button
                className="btn btn-primary pl-4 pr-4 signupsubmitbutton"
                onClick={verifyotpbutton}
              >
                Verify Your OTP
              </button>
            </div>
          </div>
          {/* } */}
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  // loginUserData: state.LoginUserReducerReducer.loginUserData,
  // loginuserLoder: state.LoginUserReducerReducer.loginuserLoder,
  // astrologerLogindata: state.LoginUserReducerReducer.astrologerLogindata,
  // astrologinloder: state.LoginUserReducerReducer.astrologinloder,
});

const mapDispatchToProps = (dispatch) => ({
  SignUpUserHandler: (data) => dispatch(SignUpUser(data)),
  // FindastrologerLoginUserHandler: data => dispatch(FindastrologerLoginUser(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
