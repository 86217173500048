import React, { useEffect, useState } from 'react';
import UserBankService from '../../../Services/UserBankServices/UserBankServices';
import { useForm, Controller } from "react-hook-form";
import { Link } from 'react-router-dom';
import AuthServices from '../../../Services/authServices';
import DashBord from '../../HomeLayout/UserDashboard/index';
import { Result, Button, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { UserWalletAmountAction } from '../../../store/UserWallet/UserWalletAction';
import './SendMonetToBank.scss'

function SendMonetToBank() {
    const disptach = useDispatch()
    const [bankList, setBankList] = useState([]);
    const [successData, setSuccessdata] = useState(false);
    const [userWallet, setUserWallet] = useState(0);

    const {
        register,
        handleSubmit,
        formState: { errors }, reset
    } = useForm();
    const fetchEarningHistory = async () => {
        const apicall = await AuthServices.EarningHistory()
        // console.log(apicall)
        if (apicall.state === false) {
            if (apicall.response.data.success === true) {
                if (apicall.response.data.userWallet.length > 0) {
                    setUserWallet(apicall.response.data.userWallet[0].wallet_amount)
                    disptach(UserWalletAmountAction(apicall.response.data.userWallet[0].wallet_amount))
                    //   setUserName(apicall.response.data.userWallet[0].name)
                    //   setEarningHistory(apicall.response.data.earninghistory)
                }
                else {

                }
            }
        }
    }
    const fetchbanklist = async () => {
        const callapi = await UserBankService.getbankaccountlist()
        if (callapi.state === false) {
            if (callapi.response.data.success === true) {
                if (callapi.response.data.result.length > 0) {
                    setBankList(callapi.response.data.result)
                }
                else {
                    console.log('empty bank list')
                }
            }

        }
    }
    const onsubmit = async (data) => {
        if (parseInt(data.amount) <= userWallet) {
            const bankdata = bankList.filter((el) => {
                return el.id === parseInt(data.bankdetail)
            })
            const apidata =
            {
                "user_id": localStorage.getItem('loginid'),
                "sent_amount": parseInt(data.amount),
                "bank_account_id": parseInt(data.bankdetail),
                "account_name": bankdata[0].account_holder_name,
                "account_number": bankdata[0].account_no,
                "ifsc_code": bankdata[0].ifsc_code,
                "bank_name": bankdata[0].bank_name,
                "bank_branch": bankdata[0].branchname
            }
            const apicall = await UserBankService.withdrawamount(apidata)
            console.log(apicall)
            if (apicall.state === false) {
                console.log(apicall)
                if (apicall.response.data.msg === "money transfer & wallet update success") {
                    setSuccessdata(true)
                    fetchEarningHistory()
                }
                // if (apicall.response.data.success === true) {
                //     setSuccessdata(true)
                //     fetchEarningHistory()
                // }
                else {
                    notification['error']({
                        message: 'Something Error!',
                    })
                }
            }
            else {
                notification['error']({
                    message: apicall.response.data.error,
                })
            }
        }
        else{
            notification['error']({
                message: 'Enter Valid Amount',
            })
        }

    }
    const onChange = (e) => {
        console.log('change', e.target.value);
    };
    useEffect(() => {
        fetchEarningHistory()
        fetchbanklist()
    }, [])

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-2 d-sm-none d-md-block d-lg-block'>
                    <DashBord />
                </div>
                <div className='col-md-10  mt-2'>
                    <div className='container-fluid p-5 senmoneybank_page_container' style={{ background: "#1a1a1a", padding: '15x' }}>
                        <div className='row'>
                            <div className='col-12 d-flex ' style={{ justifyContent: 'space-between', cursor: 'pointer' }}>
                                <h2 className='text-white'>Your Wallet Balance: {userWallet}</h2>
                                <Link to='/accountdetail'>
                                    <h2 className='text-white text-primary'>Add New Bank Account</h2>
                                </Link>
                            </div>
                        </div>
                        {successData === false ?
                            <>
                                {userWallet <= 0 ?
                                    <div className='mt-5'>
                                        <h3 className='text-center' style={{ color: 'red' }}>
                                            Your wallet is Empty
                                        </h3>
                                    </div>
                                    :
                                    <>
                                        {bankList.length > 0 ?
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    <form className=''
                                                        onSubmit={handleSubmit(onsubmit)}
                                                    >
                                                        <div class="form-group ">
                                                            {/* <h4 className='w-50'>Choose your Bank account,Amount will be deposited in selected bank account </h4>
                                           <div className='w-50'> */}
                                                            <label className='text-white text-left d-block'>Enter Withdraw Amount</label>
                                                            <input type="number" class="form-control enteramount_input" min='1' max={userWallet}
                                                                {...register("amount", {
                                                                    required: "Required",
                                                                })}
                                                            />
                                                            <small id="emailHelp" class="form-text text-danger text-left" style={{ fontSize: '20px' }}>{errors.amount && "This Field Is required"}</small>
                                                            {/* </div> */}
                                                        </div>

                                                        <div class="form-group checkbox_group">
                                                            <label className='text-white pl-0'>Choose your Bank account,Amount will be deposited in selected bank account</label><br></br>

                                                            <table class="table table-striped table-dark">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Ac. Holder Name</th>
                                                                        <th scope="col">Ac Number</th>
                                                                        <th scope="col">IFSC CODE</th>
                                                                        <th scope="col">Bank Name</th>
                                                                        <th scope="col">Branch Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {bankList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <input type="radio" onChange={onChange} class="" name={item.id} value={item.id} id="flexRadioDefault1" placeholder='Raju'
                                                                                            {...register("bankdetail", {
                                                                                                required: "Required",
                                                                                            })} />
                                                                                    </th>
                                                                                    <td>{item.account_holder_name}</td>
                                                                                    <td>{item.account_no}</td>
                                                                                    <td>{item.ifsc_code}</td>
                                                                                    <td>{item.bank_name}</td>
                                                                                    <td>{item.branchname}</td>
                                                                                </tr>
                                                                                {/* <div key={index} className='d-flex'>
                                                                    
                                                                    <label className='text-white'>
                                                                        {item.account_no}
                                                                    </label>
                                                                </div> */}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                            <small id="emailHelp" class="form-text text-danger text-left" style={{ fontSize: '20px' }}>{errors.bankdetail && "This Field Is required"}</small>

                                                        </div>
                                                        <div class="form-group submit_button">
                                                            <button type="submit" class="btn btn-primary mt-2">Send Money to Bank</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            : ""}
                                    </>
                                }
                            </>
                            :
                            <div className='row'>
                                <div className='col-12'>
                                    <Result
                                        status="success"
                                        title="Withdrawal Amount Has Been Successfully Sent To Your Selected Bank Account"
                                        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                                        extra={[
                                            <Link to='/HomePage' type="primary" class="ant-btn ant-btn-primary" key="console">
                                                Home
                                            </Link>,
                                            // <Button key="buy">Buy Again</Button>,
                                        ]}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div></div></div>

    )
}

export default SendMonetToBank