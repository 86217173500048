import {LOCAL_WATCHLIST_LENGTH} from '../constatnt';

const initState={
   watchListlength:'',
   watchListlengthLoder:false,

}



const WatchListReducer=(state=initState,action)=>{
    
    switch(action.type){
       
        case LOCAL_WATCHLIST_LENGTH:
            console.log(action.data)
            console.log('watchList user Reducer')
            return{
                ...state,
                watchListlength:action.data,
                watchListlengthLoder:true,
            }
           
        default:
        return state;
    }
}

 export default WatchListReducer;