import React, { Fragment } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/dist/plyr.css';
import videofile from '../../assets/video/sample-video.mp4'


export default function videoplayer(props) {
    console.log(props.location.state)
    return (
        <Fragment>
            <div className='container-fluid mt-5' >
                    <div className='row '>
                        <div className='col-12'>
                            <Plyr
                                source={{
                                    type: "video",
                                    sources: [
                                        {
                                            src:`http://admin.kidzjoy.org${props.location.state}`
                                            
                                        }
                                    ]
                                }}
                            />

                        </div>
                    </div>
                    </div>

        </Fragment>

    )
}
