
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import StudentCornerMultiListCrousel from './StudentCornerMultiListCrousel';
import { Link } from 'react-router-dom';



const StudentCornerMovieSubCategoryListCrousel = (props) => {
    console.log(props.subcategoryList)

    const videoList = props.subcategoryList;
    console.log(videoList)
    useEffect(() => {
    }, [])
    return (
        <Fragment>
            {props.subcategoryList.length > 0 ? props.subcategoryList.map((item, index) => {
                return (

                    <>
                        {item.movielist.length > 0 ?
                            <>
                                <div className='container-fluid   mb-3 crouselheader d-flex' style={{ marginTop: '60px', justifyContent: 'space-between' }}>
                                    <h4 className='m-0 crouselheaderheader text-uppercase' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px' }}>{item.name}</h4>
                                    <Link to={`/studentCorner/list/${item.name}/${item.id}`} className='btn btn-primary'>View All</Link>
                                </div>

                                <StudentCornerMultiListCrousel
                                    moviList={item.movielist}
                                />
                            </>
                            : ""
                        }
                    </>

                )
            })
                : "Loading...."
            }

            {/* {videoList === 'Error' || !videoList || videoList === 'Empty List' || videoList.length === 0 ? "Data Empty" : videoList.map((item, index) => {
                return (
                    <>
                        <div className='container-fluid  mt-2 mb-3 crouselheader' style={{ paddingLeft: '40px', paddingRight: '' }}>
                            <h4 className='m-0 crouselheaderheader' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px' }}>{item.name}</h4>
                        </div>

                        <StudentCornerMultiListCrousel
                            subcategory_id={item.subcategory_id} category_name={props.category_id}
                            MovieList={props.getStudentcornerMovieDetailList}
                            movieListLoder={props.getStudentcornerMovieDetailListLoder
                            } />
                    </>

                )
            })} */}

        </Fragment>
    )
}
const mapStateToProps = state => ({
    getStudentcornerMovieDetailList: state.StudentCornerMovieTabReducer.getStudentcornerMovieDetailList,
    getStudentcornerMovieDetailListLoder: state.StudentCornerMovieTabReducer.getStudentcornerMovieDetailListLoder,

})

const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(StudentCornerMovieSubCategoryListCrousel);
