import React, { Fragment } from 'react';
import DashBord from '../../HomeLayout/UserDashboard/index';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MovieWishList from './wishListTabContentPage/MovieWishList.js'
import ShortFilmsWishList from './wishListTabContentPage/ShortFilmwishList';
import StudentCornerWishList from './wishListTabContentPage/StudentCornerWishList';
import TalentHuntWishList from './wishListTabContentPage/TalentHuntWishList';
import SeriesWishList from './wishListTabContentPage/SeriesWishListPage';
import './WishListPage.scss';

function WishListPage() {
    return (
        <Fragment>
            <div className='container-fluid wishlist_page_container'>
                <div className='row'>
                    <div className='col-md-2 d-sm-none d-md-block d-lg-block'>
                        <DashBord />
                    </div>
                    <div className='col-md-10  mt-2 wishlist_page_tabs_col'>
                        <div className='mob_scroll_tab'>
                        <Tabs
                            defaultActiveKey="movies"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3" sty
                        >
                            <Tab eventKey="movies" title="Movies">
                               <MovieWishList />
                            </Tab>
                            <Tab eventKey="Series" title="Series">
                                <SeriesWishList />
                            </Tab>
                            <Tab eventKey="shortfilms" title="Short Films">
                                <ShortFilmsWishList />
                            </Tab>
                            <Tab eventKey="talenthunt" title="TalentHunt">
                                <TalentHuntWishList />
                            </Tab>
                            <Tab eventKey="studentcorner" title="Student Corner">
                               <StudentCornerWishList />
                            </Tab>
                        </Tabs>

                        </div>  
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default WishListPage
