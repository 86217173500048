import React, { Fragment, useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import { LOCAL_SERVER, PANCHANG_SERVER } from "../../../store/config";
import MovieListValidation from '../../Validation/movieListValidation';
import UpdateForm from './updateForm';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DashBord from '../../../components/HomeLayout/UserDashboard/index';



export default function UploadVideoList(props) {
    // console.log(props.movieList)
    const { viewmoviebutton, editmoviedetailButton, deletmoviebutton, movielist,
        register, handleSubmit, errors, updatebutton, updateloder, updatedata, setupdateloder,
        setdefaultValues, defaultValues, show, handleClose, setmovielist, getmovielist } = MovieListValidation(props.setMoviList)
    const [catname, setCatname] = useState('')
    const [subcatname, setSubcatname] = useState('')

    useEffect(() => {
        getmovielist()
    }, [updateloder, setupdateloder])
    const videocategoryname = (cat) => {
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findcategorynamebyid/${cat}`,)
            .then(res => {
                if (res.data.msg === 'find success') {
                    console.log(res.data.result)
                    setCatname(res.data.result[0].name)

                }
            })
        return catname
    }
    const videosubcategoryname = (subcat) => {
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findsubcategorynamebyid/${subcat}`,)
            .then(res => {
                if (res.data.msg === 'find success') {
                    console.log(res.data.result)
                    setSubcatname(res.data.result[0].name)

                }
                return res.data.result[0].name
            })

    }

    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-2 d-sm-none d-md-block d-lg-block'>
                        <DashBord />
                    </div>
                    <div className='col-md-10  mt-2'>
                        {updateloder === false ?
                            <>
                                {movielist === null || movielist === undefined ?
                                    <h6 className='text-white'>Empty List</h6>
                                    :
                                    <table className="table table-dark table-striped" style={{ background: "" }}>
                                        <thead>
                                            <tr role='row' >
                                                <th scope="col" style={{ maxWidth: '150px' }}>Name of the Artist</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">SubCategory</th>
                                                <th scope="col">Review Status</th>
                                                <th scope="col">View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {movielist.map((item, index) => {
                                                return (
                                                    <tr className='mt-3' style={{ background: 'black' }}>
                                                        <td style={{ maxWidth: '150px', overflow: "auto", overflowWrap: "break-word", verticalAlign: 'middle' }} >{item.title}</td>
                                                        <td style={{ maxWidth: '150px', overflow: "auto", overflowWrap: "break-word", verticalAlign: 'middle' }} >{item.category_name}</td>
                                                        <td style={{ maxWidth: '150px', overflow: "auto", overflowWrap: "break-word", verticalAlign: 'middle' }}>{item.subcategory_name}</td>
                                                        <td style={{ maxWidth: '150px', overflow: "auto", overflowWrap: "break-word", verticalAlign: 'middle' }}>{item.status === 0 ? "Pending" : "Publish"}</td>
                                                        <td style={{ maxWidth: '150px', overflow: "auto", overflowWrap: "break-word", verticalAlign: 'middle' }}>
                                                            <div className="flex align-items-center list-user-action">
                                                                <Link
                                                                    to={{
                                                                        pathname: '/video',
                                                                        state: {
                                                                            item: item,
                                                                            // contenttype: props.getmovieContentType
                                                                        }
                                                                    }}
                                                                    className="iq-bg-warning  mr-1" >
                                                                    <button className='iq-bg-warning' style={{ height: '28.67px', background: '#f68a04', color: "yellow", borderRadius: '5px', border: 'none' }}><i className="lar la-eye"></i></button>
                                                                </Link>
                                                              <>
                                                              {item.status===0 || item.status===1 ? "":
                                                             <>
                                                              <button className="iq-bg-success " style={{ background: 'green', color: 'greenyellow', borderRadius: '5px', border: 'none' }} data-toggle="tooltip" data-toggle="modal" data-target="#editformmodel" onClick={(e) => editmoviedetailButton(e, item)}><i className="ri-pencil-line"></i></button>
                                                                <button className="iq-bg-primary  ml-1" style={{ background: 'red', borderRadius: '5px', border: 'none' }} data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" onClick={(e) => deletmoviebutton(e, item)}><i className="ri-delete-bin-line"></i></button>
                                                             </>
                                                                }
                                                                </>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}


                                        </tbody>

                                    </table>
                                }
                            </>
                            :
                            <UpdateForm updatedata={updatedata} />
                        }


                    </div>
                </div>
            </div>

        </Fragment>
    )
}

{/* <UpdateForm
        register={register} handleSubmit={handleSubmit}errors={errors} updatebutton={updatebutton}updateloder={updateloder} updatedata={updatedata} setupdateloder={setupdateloder}
        setdefaultValues={setdefaultValues} defaultValues={defaultValues}  show={show} handleClose={handleClose}
        setmovielist={setmovielist}   /> */}
