import React,{useEffect} from 'react';
import Withoutloginheader from '../../HomeLayout/Header/withoutLoginHeader';
import './RefundPolicy.scss';

function RefundPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }, [])
    return (
        <>
        {localStorage.getItem('loginid') ? "" :
                <Withoutloginheader />
            }
            <div className='container-fluid refun_policy_container'>
                <div className='row'>
                    <div className='col-12 mt-2'>
                        <h1 className='text-center text-white'>KIDZJOY-REFUND POLICY</h1>
                    </div>
                    <div className='col-12'>
                        <div className='text-white'>
                            <br></br>
                            THIS POLICY APPLIES TO <span style={{color:"red"}}>KIDSJOY</span> SITE/APP PLATFORM INCLUDING WITHOUT LIMITATION <a style={{color:'blue'}}>WWW.KIDZJOY.ORG</a> AND OTHER RELATED SITE/S OR APP/S, MOBILE APPLICATIONS AND OTHER ONLINE FEATURES EACH A “SITE/S OR APP/S”. WE HAVE PROVIDED EXTENSIVE INFORMATION FOR YOU TO VIEW THE PACKAGES BEFORE CHOOSING TO SUBSCRIBE US. IF YOU HAVE ANY QUESTION OR RESERVATIONS, PLEASE CONTACT US ON <a style={{color:'blue'}}>INFO@KIDSFLIXX.COM</a> PRIOR TO SUBSCRIBING.<br></br><br></br>
                            WE, BEING THE SERVICE PROVIDER FOR CONTENT AVAILABLE THROUGH SITE OR APP, WHERE YOU ARE EXPECTED TO VIEW PACKAGE OF YOUR CHOICE AFTER BEING PAID FOR SUBSCRIPTION; UNFORTUNATELY ALL FEES PAID TO <span style={{color:'red'}}>KIDSFLIXX / KIDZJOY</span> FOR SUCH SERVICES ARE <span style={{color:'red'}}>NON-REFUNDABLE</span>. IN CASE BECAUSE OF ANY GLITCH AT THE TIME OF ONLINE TRANSACTION, THE TRANSACTION DOES NOT OCCUR, THE AMOUNT IN PROCESS OF TRANSFER BY DEFAULT GOES BACK INTO YOUR BANK ACCOUNT, AUTOMATICALLY THROUGH PAYMENT GATEWAY.<br></br><br></br>
                            IF WE RECEIVE A <span style={{color:"red"}}>CHARGEBACK</span> OR <span style={{color:"red"}}>PAYMENT DISPUTE</span> FROM A CREDIT CARD COMPANY OR BANK, YOUR SERVICE AND/OR SUBSCRIPTION WILL BE <span style={{color:'red'}}>SUSPENDED WITHOUT ANY PRIOR NOTICE</span>. APPLICABLE CHARGEBACK FEE WILL BE ISSUED TO RECOVER FEES PASSED ON TO US BY THE CREDIT CARD COMPANY, PLUS ANY OUTSTANDING BALANCE ACCRUED AS A RESULT OF THE CHARGEBACK(S) MUST BE PAID IN FULL BEFORE THE SERVICE IS RESTORED. INSTEAD OF ISSUING A CHARGEBACK, CONTACT US TO ADDRESS ANY BILLING ISSUE.  REQUESTING A CHARGEBACK OR OPENING ANY SORT OF DISPUTE FOR A VALID CHARGE FROM US IS A FRAUD, AND IS NEVER AN APPROPRIATE OR LEGAL MEANS OF OBTAINING A REFUND.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RefundPolicy