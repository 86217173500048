import { UserBankConst } from './constant';

const initialState = {
    bankaccountdata:''
};

export const UserBankAccountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UserBankConst.EDIT_BANK_DATA:
            console.log(payload)
            return { ...state, bankaccountdata: payload }

        default:
            return state;
    }
}


