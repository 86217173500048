import React, { Fragment, useEffect, useState } from 'react';
import WishListServices from '../../../../Services/wishListSectionServices/wishListServices';
import SeriesCardListPage from '../../../resuable/cardListPage/SeriesCardListPage';
import './MovieWishList.scss'

function SeriesWishList() {
    const [MovieList, setMovieList] = useState([])
    const FetchWishLIST = async () => {
        const data = {
            user_id: localStorage.getItem('loginid'),
            category_id: '4'
        }
        const fetchList = await WishListServices.SeriesWishList(data);
        if (fetchList.state === false) {
            if (fetchList.response.data.msg ==="show List Find") {
                setMovieList(fetchList.response.data.result)
            }
            else {
                console.log('err')
            }
        }

    }
    useEffect(() => {
        FetchWishLIST()
    }, [])

    return (
        <Fragment>
            <div className='container-fluid movie_tab_subcategoryList_container'>
                {/* <div className='row  header_info_row'>
                    <div className='col-12 header_info_col'>
                        <h2 >Browse All Movies</h2>
                       
                    </div>
                </div> */}
                <div className='row musiccardlist_row'>
                    {MovieList.length > 0 ?
                        <SeriesCardListPage VideoList={MovieList} />
                        : "Empty"
                    }

                </div>
            </div>        </Fragment>
    )
}

export default SeriesWishList;
