import React from 'react';
import UploadVideoValidationForm from '../../Validation/UploadVideoValidationForm';
import Select from 'react-select';
import './UploadVideoPage.css';
const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#1a1a1a', color: 'white' }),
}
export default function UploadVideoForm(props) {
    const { uploadVideoButton, register, handleSubmit, errors,
        contenttypeHandlechange, contentType, successuploadmsg, findcategory, categoryList, statusupload, handleChange,
        subcatList, contentTypelist } = UploadVideoValidationForm(props.updatedata)
    return (
        <div>
            <form onSubmit={handleSubmit(uploadVideoButton)}>
                <div class="form-group">
                    <input type="text" class="form-control uploadforminput" id="inputAddress2"
                        placeholder="Name of the Artist"
                        {...register("title", {
                            required: "Required",
                        })}
                        onChange={handleChange} />
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-6 p-0'>
                            <div class="form-group " style={{ paddingRight: '5px' }}>

                                <input type="file" class="form-control uploadforminput" id="inputAddress2"
                                    placeholder='Choose Image File'
                                    name="filename"
                                    {...register("filename", {
                                        required: "Required",
                                    })}
                                    onChange={handleChange} />
                            </div>
                            <div class="form-group" style={{ paddingRight: '5px', height: '250px' }}>
                                <input type="text" class="form-control uploadforminput" id="inputAddress2" placeholder="Enter Movie Title" />
                            </div>
                        </div>
                        <div className='col-6 p-0'>
                            <div class="form-group" style={{ paddingLeft: '5px' }}>
                                <input type="file" class="form-control uploadforminput" id="inputAddress2" placeholder="Upload Video" />
                            </div>
                            <div class="form-group" style={{ paddingLeft: '5px', height: '250px' }}>
                                <input type="text" class="form-control uploadforminput" id="inputAddress2" placeholder="Enter Movie Title" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <select class="form-control uploadforminput " name='category'
                            style={{ background: '#1a1a1a', color: 'white' }}
                            {...register("category", {
                                required: "Required",
                            })}
                            onChange={handleChange}

                        >
                            <option value="" >Movie Category</option>
                            {/* {categoryList === undefined ? "" : categoryList.map((item, index) => {
                                return (
                                    <>
                                        <option value={item.category_name} >{item.category_name}</option>
                                    </>
                                )

                            })} */}
                        </select>                    </div>
                    <div class="form-group col-md-3">
                        <select name="cars" class="form-control uploadforminput" style={{ background: '#1a1a1a', color: 'white' }}
                            {...register("subcategory", {
                                required: "Required",
                            })}
                            onChange={handleChange}
                        >
                            <option value="">SubCategory</option>
                            {/* {subcatList === null ? "" :
                                <>
                                    {subcatList.map((item, index) => {
                                        return (
                                            <>
                                                <option value={item.subcategory_name} >{item.subcategory_name}</option>
                                            </>
                                        )
                                    })}
                                </>} */}
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <Select isMulti className='m-0 form-control pro-dropdown basic-multi-select p-0' options={contentTypelist}
                            styles={colourStyles}

                            onChange={contenttypeHandlechange}
                        />                     </div>

                </div>
                <div class="form-group">
                    <textarea class="form-control uploadforminput" id="exampleFormControlTextarea1" placeholder='Descripition' rows="3"
                        name='desc'
                        {...register("desc", {
                            required: "Required",
                        })}
                        onChange={handleChange}

                    />
                </div>



                <div class="form-row">

                    <div class="form-group col-md-6 text-left">
                        <button className='btn ' style={{ background: "#3f9fff", color: "#ffffff" }} onClick={handleSubmit(uploadVideoButton)}>Submit</button>
                        <button className='btn ml-2' style={{ background: "#545E75", color: "#ffffff" }}>Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
