import {GET_SERIES_LIST,GET_SERIES_DETAIL_LIST,GET_SHOW_DETAIL_BY_SHOWID} from '../constatnt';

const initState={
   getSeriesListSubCategory:'',
   getSeriesListSubCategoryLoder:false,
   getSeriesDetailList:false,
   getSeriesDetailListLoder:false,
   getseriesdetailbyshowid:'',
   getseriesdetailbyshowidLoder:false
}



const SeriesTabReducer=(state=initState,action)=>{
    
    switch(action.type){
       
        case GET_SERIES_LIST:
            console.log(action.data)
            console.log(' movie tab CategoryUploadvideoList Reducer')
            return{
                ...state,
                getSeriesListSubCategory:action.data,
                getSeriesListSubCategoryLoder:true,
            }
            case GET_SERIES_DETAIL_LIST:
                console.log(action.data)
                console.log('movie list Reducer')
                return{
                    ...state,
                    getSeriesDetailList:action.data,
                    getSeriesDetailListLoder:true,
                }
                case GET_SHOW_DETAIL_BY_SHOWID:
                    console.log(action.data)
                    console.log('series show detailby id  Reducer')
                    return{
                        ...state,
                        getseriesdetailbyshowid:action.data,
                        getseriesdetailbyshowidLoder:true,
                    }
              
        default:
        return state;
    }
}

 export default SeriesTabReducer;