import React,{Fragment} from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import audiosong from  '../../assets/audiosong/Song.mp3';
import './AudioPlayer.scss'

function AudioPlayerPage(props) {
    // console.log(props.history.location.item)
    const item=props.song_path
    return (
        <Fragment>
            <AudioPlayer
            showDownloadProgress={true}
                autoPlay
                src={`http://admin.kidzjoy.org${item}`}
                onPlay={e => console.log("onPlay")}
            />
        </Fragment>
    )
}

export default AudioPlayerPage
