import React, { Fragment, useState, useEffect } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import ItemsCarousel from 'react-items-carousel';
import HomeMoviDetailPage from '../../../../resuable/HomePageMoviDetail';
import Loder from '../../../../resuable/Loder';
import './LatestVideoCrousel.scss'
import axios from 'axios';
import MovieTabCrouselCard from '../../../../Pages/MovieTab/MovieTabCrouselCard';
import MovieTabDetailPage from '../../../../Pages/MovieTab/MovieTabDetailPage';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../../../store/config';
import { Link } from 'react-router-dom';



export default function MovieHomeCrousel() {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [loder, setLoder] = useState(false);
    const [VideoList, setVideoList] = useState([])
    const chevronWidth = 15;
    const moviedetailstyle = {
        top: '-70%'
    }
    useEffect(() => {
        VideoLstapi()
        console.log('latest')
    }, [])
    const VideoLstapi = async () => {
        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/movieList`)
            .then(res => {
                if (res.data.msg === 'list find success') {
                    console.log(res.data.result)
                    setVideoList(res.data.result)
                }
                else {
                    console.log(res.data)
                }
            })
    }
    return (
        <Fragment>
            <div className='container-fluid   mb-3 crouselheader d-flex' >
                <h4 className='m-0 crouselheaderheader text-uppercase' >Movie</h4>
                <Link to='/Watch-movie' className='btn btn-primary'>View All</Link>
            </div>
            <div className='MovieCrousel crouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={6}
                    gutter={20}
                    leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                    rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                    // outsideChevron
                    chevronWidth={chevronWidth}
                >
                    {VideoList.map((item, index) => {
                        return (
                            <Link to={{
                                pathname: `/movie/${item.title}/${item.id}`
                            }}>                             <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                    <MovieTabCrouselCard item={item} />
                                    <MovieTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                </div>
                            </Link>

                        )
                    })}

                </ItemsCarousel>
            </div>
            <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={3}
                    gutter={10}
                    leftChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-left text-white"></i>}</button>}
                    rightChevron={<button className='crouselitembutton'>{<i class="fas fa-arrow-right text-white"></i>}</button>}
                    chevronWidth={chevronWidth}
                >
                    {VideoList.map((item, index) => {
                        return (
                            <Link to={{
                                pathname: `/movie/${item.title}/${item.id}`
                            }}>                             <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                    <MovieTabCrouselCard item={item} />
                                    <MovieTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                </div>
                            </Link>

                        )
                    })}

                </ItemsCarousel>
            </div>

        </Fragment>
    )
}
