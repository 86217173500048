import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';

function StudentCornerTabCrouselCard(props) {
    console.log(props.item)

    return (
        <Fragment>
              <div className='itemimagetag'>
                       <img className='itemimagetag' style={{borderRadius:'3px',boxShadow:"0 2px 5px 0 rgb(34 34 34 / 40%)"}} 
                        src={`http://admin.kidzjoy.org${props.item.image_path}`} type='file/png'
                        height='300px' width='100%'></img>
                       {/* </Link> */}
                      </div> 
        </Fragment>
    )
}

export default StudentCornerTabCrouselCard
