
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';


const HomeScreenProtected =({component:Cmp,...rest})=>(
    <Route 
    {...rest}
    render={(props)=>

        !localStorage.getItem('user_token') ?(
            <Cmp {...props} />
        ):
        <Redirect to='/HomePage' /> 
    }
    
    
    />

)
export default HomeScreenProtected;