import React, { Fragment, useState, useEffect } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { useParams } from 'react-router';
import MovieTabCrouselCard from '../../../../Pages/MovieTab/MovieTabCrouselCard';
import MovieTabDetailPage from '../../../../Pages/MovieTab/MovieTabDetailPage';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../../../store/config';
import { Link } from 'react-router-dom';
import SeriesTabCrouselCard from '../../../../Pages/SeriesPage/SeriesTabCrouselCard';
import SeriesTabDetailPage from '../../../../Pages/SeriesPage/SeriesTabDetailPage';
import axios from 'axios';
import './LatestVideoCrousel.scss';

function HomeCrouselitemList(props) {
    const params = useParams()
    const {crouselcatid,index}=props
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [loder, setLoder] = useState(false);
    const [VideoList, setVideoList] = useState([]);
    const [Videocat, setvideoCat] = useState()
    const chevronWidth = 15;
    const moviedetailstyle = {
        top: '-70%'
    }
    useEffect(() => {
        VideoLstapi()
        console.log('latest')
    }, [])
    const VideoLstapi = async () => {
        const data = {
            category_id: props.category_id,
            limit: 10,
            videotype: props.videotype,
        }
        console.log(data)
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/fetchhomeCrouselvideotypeList`, data)
            .then(res => {
                if (res.data.msg === 'list find success') {
                    if(res.data.result.length>0){
                        props.setCrouselshow('')
                        console.log(res.data.type)
                         console.log(res.data.result)
                        setvideoCat(res.data.type)
                        setVideoList(res.data.result)
                    }
                    else{
                        console.log(index)
                        props.setCrouselshow()
                        props.setcrouselid([...crouselcatid,index]
                            // {
                            //     crouselcatid: [
                            //         ...crouselcatid,
                            //         index
                            //     ]
                            // }
                        )
                        console.log(crouselcatid)
                    }
                }
                else {
                    console.log(res.data)
                }
            })
    }
    return (
        <Fragment>
            {VideoList.length > 0 ?
                <>
                    {Videocat === 'movies' ?
                        <>
                            <div className='MovieCrousel crouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                                <ItemsCarousel
                                    requestToChangeActive={setActiveItemIndex}
                                    activeItemIndex={activeItemIndex}
                                    numberOfCards={6}
                                    gutter={20}
                                    leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                                    rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                                    // outsideChevron
                                    chevronWidth={chevronWidth}
                                >
                                    {VideoList.map((item, index) => {
                                        return (
                                            <Link to={{
                                                pathname: `/movies/${item.title.replace(/ /g, "-").toLowerCase()}/${item.id}`
                                            }}>                             <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                                    <MovieTabCrouselCard item={item} />
                                                    <MovieTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                                </div>
                                            </Link>

                                        )
                                    })}

                                </ItemsCarousel>
                            </div>
                            <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                                <ItemsCarousel
                                    requestToChangeActive={setActiveItemIndex}
                                    activeItemIndex={activeItemIndex}
                                    numberOfCards={3}
                                    gutter={10}
                                    leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                                    rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                                    chevronWidth={chevronWidth}
                                >
                                    {VideoList.map((item, index) => {
                                        return (
                                            <Link to={{
                                                pathname: `/movies/${item.title.replace(/ /g, "-").toLowerCase()}/${item.id}`
                                            }}>                             <div className='' style={{ borderRadius: '3px', height: "", background: '' }}>
                                                    <MovieTabCrouselCard item={item} />
                                                    <MovieTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                                </div>
                                            </Link>

                                        )
                                    })}

                                </ItemsCarousel>
                            </div>
                        </>
                        :
                        <>
                            {/* <div className='container-fluid   mb-3 crouselheader d-flex' style={{ marginTop: '60px', paddingLeft: '15px', paddingRight: '15px', justifyContent: 'space-between' }}>
                                <h4 className='m-0 crouselheaderheader text-uppercase' style={{ fontWeight: '500', lineHeight: '1.2', color: '#fff', marginBottom: '20px' }}>Trending Web Shows</h4>
                                <Link to={`webshow-list/${'Trending'}`} className='btn btn-primary'>View All</Link>
                            </div> */}
                            <div className='crouselcontainer MovieCrousel' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>
                                <ItemsCarousel
                                    requestToChangeActive={setActiveItemIndex}
                                    activeItemIndex={activeItemIndex}
                                    numberOfCards={6}
                                    gutter={20}
                                    leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                                    rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                                    chevronWidth={chevronWidth}
                                >
                                    {VideoList && VideoList.map((item, index) => {
                                        return (
                                            <>
                                                <Link
                                                    to={{
                                                        pathname: `/series/${item.title.replace(/ /g,"-").toLowerCase()}/${item.id}`,
                                                        state: {
                                                            show_id: item.id,
                                                            seasonLength: !item.seasonid ? "Comming Soon" : item.seasonid.length
                                                        }
                                                    }}
                                                >
                                                    <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                                        <SeriesTabCrouselCard item={item} />
                                                        <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                                    </div>
                                                </Link>
                                            </>

                                        )
                                    })}
                                </ItemsCarousel>
                            </div>
                            <div className='mobilecrouselcontainer' style={{ padding: `0 ${chevronWidth}px`, height: '' }}>

                                <ItemsCarousel
                                    requestToChangeActive={setActiveItemIndex}
                                    activeItemIndex={activeItemIndex}
                                    numberOfCards={3}
                                    gutter={10}
                                    leftChevron={<button className='crouselitembutton slideleft'>{<i class="fas fa-chevron-left"></i>}</button>}
                                    rightChevron={<button className='crouselitembutton slideright'>{<i class="fas fa-chevron-right"></i>}</button>}
                                    chevronWidth={chevronWidth}
                                >
                                    {VideoList.map((item, index) => {
                                        return (

                                            <>
                                                <Link
                                                    to={{
                                                        pathname: `/series/${item.title.replace(/ /g,"-").toLowerCase()}/${item.id}`,
                                                        state: {
                                                            show_id: item.id,
                                                            seasonLength: !item.seasonid ? "comming Soon" : item.seasonid.length
                                                        }
                                                    }}
                                                >
                                                    <div className='newcrouseldiv' style={{ height: "", background: '' }}>
                                                        <SeriesTabCrouselCard item={item} />
                                                        <SeriesTabDetailPage moviedetailstyle={moviedetailstyle} item={item} />
                                                    </div>
                                                </Link>
                                            </>

                                        )
                                    })}
                                </ItemsCarousel>
                            </div>
                        </>

                    }
                </>
                : ""}
        </Fragment>
    )
}

export default HomeCrouselitemList
