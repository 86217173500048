import React, { useEffect, useState, Fragment } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import axios from 'axios';
import UpdateFormValidation from '../../Validation/updateFormValidation'
import UploadVideoList from './UploadVideoList';
import Select from 'react-select';
import DashBord from '../../../components/HomeLayout/UserDashboard/index';
import './UploadVideoList.css'

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#1a1a1a', color: 'white' }),
}


export default function UpdateForm(props) {
    console.log(props.updatedata)
    const {findcategory,categoryList,statusupload,setstatusupload,state,handleChangeinput, contenttypeHandlechange,contentTypelist,subcatList,setState,setsubcatList,setContentTypelist,
        contentType, setContentType}=UpdateFormValidation()
    const updatedata = props.updatedata;
    const [movieDetail, setmovieDetail] = useState()
    const [defaultValues, setdefaultValues] = useState();
    const [videopreviewui, setvideopreviewui] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues

    });
    const [movieList,setMoviList]=useState()

    const [movieid, setmovieid] = useState(localStorage.getItem('id'))
    const [uistatus, setUistatus] = useState(false);
    // const [contentType, setContentType] = useState(null);


    const options = [
        { value: 'music', label: 'Music' },
        { value: 'math', label: 'Math' },
        { value: 'englishsong', label: 'English Song' }
    ]
    const [updatealert, setupdatealert] = useState(false)
    const [movielistui, setmovielistui] = useState(false)

    console.log(props.updatedata)

    const finddetailbutton = () => {
        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/UploadvideoDetailbyid/${movieid}`)
            .then(res => {
                console.log(res.data.data)
                if (res.data.msg === 'Detail found') {
                    const subcategorydata={
                        subcategory_id:res.data.data[0].subcategory_id,
                        category_id:res.data.data[0].category_id,
                        video_id:res.data.data[0].id,
                    }
                    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findvideosubcategoryListbycategoryid`,subcategorydata)
                    .then(response=>{
                        if(response.data.msg==='list find success'){
                            setsubcatList(response.data.subcategoryList)

                            setState({
                                selectoption:response.data.contenttypeList
                            })
                            setContentTypelist(
                                response.data.contenttypeList.map((item, index) => {
                                    return (
                                        { value: item.conttype_id, label: item.name }

                                    )
                                })
                            )
                                


    
                            axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/videocontenttypebyvideoiid/${res.data.data[0].id}`)
                            .then((result) => {
                                if (result.data.msg === "list find success") {
                                
                                    console.log(result.data.result)
                                    setmovieDetail(res.data.data)
                                    setdefaultValues({
                                        title: res.data.data[0].title
                                    })
                                    setState({
                                        title: res.data.data[0].title,
                                        category: res.data.data[0].category_id,
                                        filenamepath: `${res.data.data[0].image}`,
                                        subcategory: res.data.data[0].subcategory_id,
                                        videofilename: res.data.data[0].video,
                                        video_path: res.data.data[0].video_path,
                                        image_path: res.data.data[0].image_path,
                                        desc: res.data.data[0].desc,
                                        contenttype: result.data.result,
                                        selectoption: result.data.result,
                                        video_id: res.data.data[0].id,
                                        imagefilename:res.data.data[0].image
                                    })
                                    
                                    setUistatus(true)
                                }
                            })
                        }
                    })



                }
                else {
                    setmovieDetail('Data Not Found')
                }
             })
    }
    const cancelupdatebutton = () => {
        setmovielistui(true)
        setstatusupload(false)
    }
    const uploadVideoButton = (e) => {
        console.log(state)
        console.log(contentType)
        if (contentType === null) {
            const user_id = localStorage.getItem('loginid')
            const contenttypevalue = []
            
            var formdata = new FormData()
            formdata.append('title', state.title);
            formdata.append('category', state.category);
            formdata.append('subcategory', state.subcategory);
            formdata.append('desc', state.desc);
            formdata.append('video_id', state.video_id);
            formdata.append('user_id', user_id);
            formdata.append('contentType', state.contenttype);
            const config = {
                headers: {
                    'accept': 'application/json',
                    'content-type': 'multipart/form-data'
                }
            }
            axios.put(`${LOCAL_SERVER}/${PANCHANG_SERVER}/updatevideodetail`, formdata)
                .then(res => {
                    console.log(res)
                    if (res.data.msg === "updated contenttyped") {
                        // axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/deletecontentypebyvideo_id/${state.video_id}`)
                        //     .then(result => {
                        //         if (result.data.msg === 'contenttype  deleted') {
                        //             console.log(result)
                        //             axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/createContentTypebyvideoanduserid`, formdata)
                        //                 .then(result1 => {
                        //                     if (result1.data.msg === 'create contenttyped') {
                        //                         console.log(result1)
                        //                         setupdatealert(true)
                        //                     }

                        //                 })
                        //         }
                        //     })

                    }
                })

        }
        else {
            const user_id = localStorage.getItem('loginid')
            const contenttypevalue = []
            if (contentType.length > 0) {
                contentType.forEach((item, index) => {
                    contenttypevalue[index] = `${item.value}`
                })
            }
            console.log(contenttypevalue)
            var formdata = new FormData()
            formdata.append('title', state.title);
            // formdata.append('filename',state.filename);
            formdata.append('category', state.category);
            formdata.append('subcategory', state.subcategory);
            // formdata.append('videofile',state.videofilename);
            formdata.append('desc', state.desc);
            formdata.append('user_id', user_id);
            formdata.append('video_id', state.video_id);
            formdata.append('contentType', contenttypevalue);

          
            const config = {
                headers: {
                    'accept': 'application/json',
                    'content-type': 'multipart/form-data'
                }
            }
            axios.put(`${LOCAL_SERVER}/${PANCHANG_SERVER}/updatevideodetail`, formdata)
                .then(res => {
                    console.log(res)
                    if (res.data.msg === "updated contenttyped") {
                        axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/deletecontentypebyvideo_id/${state.video_id}`)
                            .then(result => {
                                console.log(result)
                                if (result.data.msg === 'contenttype  deleted') {
                                    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/createContentTypebyvideoanduserid`, formdata)
                                        .then(result1 => {
                                            if (result1.data.msg === 'create contenttyped') {
                                                console.log(result1)
                                                setupdatealert(true)
                                            }
                                        })
                                }
                            })
                    }
                })

            console.log(formdata)
        }



    }
    useEffect(() => {
        finddetailbutton()
        findcategory()
        const getmovielist=()=>{
            const user_id=localStorage.getItem('loginid')
            axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/videolist/${user_id}`)
            .then(res=>{
                if(res.data.msg==='find success'){
                console.log(res.data.result)
                setMoviList(res.data.result)
                }
                // setupdateloder(false)
            })
        }

    }, [setUistatus])

    const previewButton = () => {
        setvideopreviewui(true)
    }
    return (

        <Fragment>
            {movielistui === true && statusupload===false ? <UploadVideoList movieList={movieList} setMoviList={setMoviList} /> :
                <div>

                    {updatealert === true ?
                        <div className='container'>
                            <div className='row mt-4'>

                                <div className='col-12'>
                                    <img src="https://img.icons8.com/emoji/48/000000/check-box-with-check-emoji.png" />
                                    <h5 style={{ color: 'greenyellow' }}>Video Detail Updated</h5>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            {uistatus === false ? "" :
                                <div className='container-fluid  uploaddivcontainerfluid'>
                                    <div className='row p-3' style={{ background: "#141414", borderBottom: '1px solid rgb(0 0 0)' }}>
                                        <div className='col-12'>
                                            <h4 className='text-white text-left'>Update Your Video Content</h4>
                                        </div>
                                    </div>
                                    <div className='row  uploaddivrow' style={{ background: "#141414", borderBottom: '1px solid rgb(0 0 0)' }}>
                                        <div className='col-12'>
                                            <form onSubmit={handleSubmit(uploadVideoButton)}>
                                                <div class="form-group">
                                                    <input type="text" class="form-control uploadforminput"
                                                        name='title' placeholder='Title'

                                                        value={state.title} onChange={handleChangeinput}
                                                    />
                                                </div>
                                                <div className='container-fluid'>
                                                    <div className='row'>
                                                        <div className='col-6 p-0'>
                                                            <div class="form-group " style={{ paddingRight: '5px' }}>
                                                            <label class="text-left text-white d-block" for="">Upload Image</label>

                                                                <input type="file" class="form-control uploadforminput pl-0" placeholder='Choose Image File' name="filename"
                                                                    value={state.filename} onChange={handleChangeinput} />
                                                            </div>
                                                            {/* <small class="form-text text-success text-left  mb-3 " style={{ fontSize: '12px' }}>{state.filenamepath} *</small> */}

                                                            <div class="form-group" style={{ marginRight: '5px', height: '250px', background: "" }}>
                                                                <img src={`${LOCAL_SERVER}/${PANCHANG_SERVER}/fetchUplaodImage/${state.imagefilename}`} height='250px' alt='upload image' />
                                                            </div>
                                                        </div>
                                                        <div className='col-6 p-0'>
                                                            <div class="form-group" style={{ paddingLeft: '5px' }}>
                                                            <label class="text-left text-white d-block" for="">Upload Video</label>

                                                                <input type="file" class="form-control uploadforminput pl-0" style={{ height: '' }} placeholder='Choose Image File' name="videofile"

                                                                    value={state.videofile} onChange={handleChangeinput} />
                                                                {/* <small class="form-text text-success text-left  mb-3 " style={{ fontSize: '12px' }}>{state.videofilename} *</small> */}
                                                            </div>
                                                            <div class="form-group" style={{ marginLeft: '5px', height: '250px', background: "" }}>
                                                                {videopreviewui === false ?
                                                                    <button className='btn btn-primary' style={{ marginTop: '25%' }} onClick={previewButton()}>Preview Video</button>
                                                                    :
                                                                    <div className='container'>
                                                                        <div className='row '>
                                                                            <div className='col-12'>
                                                                                <video width="100%" height="" controls style={{ maxHeight: '225px' }}>
                                                                                    <source src={`${LOCAL_SERVER}/${PANCHANG_SERVER}/videostream/${state.videofilename}`} type="video/mp4" width='100%' />
                                                                                    <track label="English" kind="subtitles" srclang="en" src='' default />
                                                                                    Your browser does not support the video tag.
                                                                                </video>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-3">
                                                        <select class="form-control uploadforminput" name='category' style={{ background: '#1a1a1a', color: 'white' }}

                                                            value={state.category} onChange={handleChangeinput}     >
                                                            <option value="" >Category</option>
                                                            {categoryList.map((item,index)=>{
                                                                return <>
                                                            <option value={item.id}>{item.name}</option>

                                                                </>
                                                            })}
                                                        </select>      
                                                        </div>
                                                    <div class="form-group col-md-3">
                                                        <select class="form-control uploadforminput" style={{ background: '#1a1a1a', color: 'white' }}

                                                            name='subcategory'
                                                            value={state.subcategory} onChange={handleChangeinput}  >
                                                            <option value="">Sub Category </option>

                                                            {subcatList === null ? "" :
                                                                    <>
                                                                        {subcatList.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <option value={item.id} >{item.name}</option>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                    }                         
                                                               </select>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <Select isMulti placeholder='Select content type ...' className='m-0 form-control pro-dropdown basic-multi-select p-0' options={contentTypelist}
                                                            styles={colourStyles}
                                                            onChange={contenttypeHandlechange}
                                                        />
                                                        <div className='mt-2' style={{ display: 'flex' }}>

                                                            {state.selectoption.map((item, index) => {
                                                                return (
                                                                    <small class="form-text text-success text-left mt-0  mb-3 mr-3 text-uppercase" style={{ fontSize: '12px' }}><i className='fa fa-circle bg-success' style={{ borderRadius: '50%' }}></i>&nbsp;{item.name}</small>
                                                                )
                                                            })}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <textarea class="form-control uploadforminput" id="exampleFormControlTextarea1" placeholder='Descripition' rows="3"
                                                        name='desc' value={state.desc} onChange={handleChangeinput}></textarea>
                                                </div>



                                                <div class="form-row">

                                                    <div class="form-group col-md-6 text-left">
                                                        <button className='btn ' style={{ background: "#3f9fff", color: "#ffffff" }} onClick={uploadVideoButton}>Update Detail</button>
                                                        <button className='btn ml-2' style={{ background: "#545E75", color: "#ffffff" }} onClick={cancelupdatebutton}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                            {/* <form onSubmit={handleSubmit(uploadVideoButton)}>
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-sm">
                                            <div class="form-group mt-1">
                                                <input type="text" class="form-control uploadforminput"
                                                 name='title'  placeholder='Title'

                                                    value={state.title} onChange={handleChangeinput} />
                                            </div>
                                            <div class="form-group mb-1">
                                                <input type="file" class="form-control uploadforminput pl-0" placeholder='Choose Image File' name="filename"
                                                    value={state.filename} onChange={handleChangeinput} />
                                            </div>
                                            <small class="form-text text-success text-left  mb-3 " style={{fontSize:'12px' }}>{state.filenamepath} *</small>

                                            <div class="form-row">

                                                <div class="form-group col-md-6 col-sm">
                                                    <select class="form-control uploadforminput" name='category' style={{ background: '#1a1a1a', color: 'white' }}

                                                        value={state.category} onChange={handleChangeinput}     >
                                                        <option value="" >Movie Category</option>
                                                        <option value="studentcorner">Student Corner</option>
                                                        <option value="talenthunt">Talent Hunt</option>

                                                    </select>
                                                </div>
                                                <div class="form-group col-md-6 col-sm">
                                                    <select class="form-control uploadforminput" style={{ background: '#1a1a1a', color: 'white' }}

                                                        name='subcategory'
                                                        value={state.subcategory} onChange={handleChangeinput}  >
                                                        <option value="">SubCategory </option>
                                                        <option value="music">Music</option>
                                                        <option value="study">Study</option>
                                                        <option value="shortvideo">Short Video</option>
                                                        <option value="comedy">Comedy</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <Select isMulti placeholder='Select content type . . .' className='m-0 form-control pro-dropdown basic-multi-select p-0' options={options}
                                                    styles={colourStyles}
                                                    onChange={contenttypeHandlechange}
                                                />
                                            </div>
                                            <div style={{display:'flex'}}>

                                                {state.selectoption.map((item, index) => {
                                                    return (
                                                        <small class="form-text text-success text-left mt-0  mb-3 mr-3 text-uppercase" style={{fontSize:'12px'}}><i className='fa fa-circle bg-success' style={{borderRadius:'50%'}}></i>&nbsp;{item}</small>

                                                    )
                                                })}
                                            </div>


                                            <div class="form-group">
                                                <textarea class="form-control uploadforminput" id="exampleFormControlTextarea1" placeholder='Descripition' rows="3"
                                                    name='desc' value={state.desc} onChange={handleChangeinput}></textarea>
                                            </div>
                                        </div>

                                        <div class="col-sm mb-3" style={{ background: '#141414', borderRadius: '5px',border:'1px solid black' }}>
                                            <div class="form-group mb-1 mt-1">
                                                <input type="file" class="form-control uploadforminput pl-0" style={{ height: '' }} placeholder='Choose Image File' name="videofile"

                                                    value={state.videofile} onChange={handleChangeinput} />
                                            </div>
                                            <small class="form-text text-success text-left  mb-3 " style={{fontSize:'12px' }}>{state.videofilename} *</small>

                                            <div class="form-group"  >
                                                {videopreviewui === false ?
                                                    <button className='btn btn-primary' style={{ marginTop: '25%' }} onClick={previewButton}>Preview Video</button>
                                                    :
                                                    <div className='container'>
                                                        <div className='row '>
                                                            <div className='col-12'>
                                                                <video width="100%" height="" controls style={{ maxHeight: '225px' }}>
                                                                    <source src={`http://localhost:8080/api/videostream/${state.videofilename}`} type="video/mp4" width='100%' />
                                                                    <track label="English" kind="subtitles" srclang="en" src='' default />
                                                                               Your browser does not support the video tag.
                                                            </video>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>


                                    <div class="form-row">

                                        <div class="form-group col-md-6 text-left">
                                            <button className='btn ' style={{ background: "#3f9fff", color: "#ffffff" }} onClick={uploadVideoButton}>Update Detail</button>
                                            <button className='btn ml-2' style={{ background: "#545E75", color: "#ffffff" }} onClick={cancelupdatebutton}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form> */}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </Fragment>
    )
}


// export default function UpdateForm(props) {


//     const {
//         register, handleSubmit, errors, setdefaultValues, defaultValues
//         , setupdateloder, updatedata,
//         show, handleClose, updateloder,setmovielist,getmovielist } = props
//     const [status, setStatus] = useState(false)
//     const [state, setstate] = useState({
//         id:updatedata.id,
//         title: updatedata.title,
//         category: updatedata.category,
//         subcategory: updatedata.subcategory,
//         desc: "Good"
//     })
//     const updatebutton = () => {
//         console.log(state)
//         axios.put(`${LOCAL_SERVER}/${PANCHANG_SERVER}/updatevideodetail`,state)
//         .then(res=>{
//             console.log(res)
//             if(res.data.msg==="List updated Success"){
//                 axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/videolist`)
//                 .then(res=>{
//                     console.log(res.data.data)
//                     setmovielist(res.data.data)
//                 })            }
//         })

//     }
//     useEffect(() => {
//         console.log(show)
//         setdefaultValues({
//             id:updatedata.id,
//             title: updatedata.title,
//             category: updatedata.category_id,
//             subcategory: updatedata.subcategory_id,
//             desc: "Good"
//         })

//         console.log(updatedata)

//     }, [updatedata])
//     const handleChange = (e) => {
//         setstate({
//             ...state,
//             [e.target.name]: e.target.value

//         })
//     }
//     const submitupdate = (e) => {
//         e.preventDefault();
//         console.log(state)
//     }
//     return (
//         <div>
//             <Modal show={show} onHide={handleClose} style={{ border: 'none', zIndex: '9999' }}>

//                 <Modal.Body className='modelbodyloginsignupform' style={{ border: 'none' }} >
//                     <div>
//                         <form onSubmit={handleSubmit(updatebutton)} >
//                             <div class="form-floating mb-3">
//                                 <h4 className='text-center'>Update Movie Content</h4>
//                             </div>

//                             <div class="form-floating mb-3 ">
//                                 <input type="text " class="form-control logininputdiv" name="title" placeholder={updatedata.title}

//                                     onChange={handleChange} value={state.title} />
//                             </div>

//                             <div class="form-floating">
//                                 <input type="text" class="form-control logininputdiv" name='category' placeholder="Category"

//                                     onChange={handleChange} value={state.category}
//                                 />
//                             </div>
//                             <div class="form-floating">
//                                 <input type="text" class="form-control logininputdiv" id="floatingPassword" name='subcategory' placeholder="SubCategory"

//                                     onChange={handleChange}
//                                     value={state.subcategory} />
//                             </div>
//                             <small class="form-text text-danger  mb-2 " style={{ marginLeft: '67px' }}>{errors.subcategory && "This Field Is required"}</small>
//                             <div class="form-floating">
//                                 <input type="text" class="form-control logininputdiv" id="floatingPassword" name='desc' placeholder="Description"

//                                     onChange={handleChange}
//                                     value={state.desc} />
//                             </div>

//                             <div class="form-floating text-center mt-3">
//                                 <button type='submit' className='btn btn-primary pl-4 pr-4 loginsubmitbutton' onClick={updatebutton}>Update</button>
//                             </div>
//                         </form>



//                     </div>
//                 </Modal.Body>

//             </Modal>


//         </div>
//     )
// }

