import React, { Fragment, useState, useEffect } from 'react';
import { Tabs, Empty } from 'antd';
import axios from "axios";
import { Link } from 'react-router-dom';
import './EpisodeList.scss';
const { TabPane } = Tabs;


function EpisodeList(props) {
    const { seasonlist, episodeList, setepisodeList,setFirstseasonFirstEpiData } = props
    const [status, setstatus] = useState(false);
    const [FirstSeasonList, setFirstSeasonList] = useState(seasonlist.filter(el=>{
        return el.season==="Season 1"
    }))
    
   useEffect(() => {
       const list=seasonlist.filter(el=>{
        return el.season_number==="Season 1"
    })
       const id=list[0].id
    axios.post(`https://api.kidzjoy.org/api/findEpisodeListBySeasonid/${id}`)
    .then(res => {
        if (res.data.msg === 'list find success') {
            console.log(res)
            setepisodeList(res.data.result)
            setFirstseasonFirstEpiData(res.data.result[0])
            setstatus(true)

        }
        else if (res.data.msg === 'List Empty') {

        }
        else if (res.data.msg === 'Error') {

        }
    })
    .catch(err => {
    })

   }, [])
    const callback = (key) => {
        //  console.log(key);

    }
    const listrender = (value) => {
        return <h6>{value}</h6>
    }
    const episodelistfunction = (item, index) => {
        if (index === 0) {
            console.log(item)
            axios.post(`https://api.kidzjoy.org/api/findEpisodeListBySeasonid/${item.id}`)
                .then(res => {
                    if (res.data.msg === 'list find success') {
                        //  setSeasonButton('red')
                        console.log(res.data.result[0])
                        setepisodeList(res.data.result[0])
                        
                        setstatus(true)
                        //   return ( <h5>{episodeList.length===0 ? "empty":`${episodeList.length}`}</h5>)

                    }
                  
                })
                .catch(err => {
                    //   setEpisodeList('Error')
                })
        }
        // return listrender('item')

    }
    const episodelistButton = (season_id, index) => {
        console.log(index)
        console.log(season_id)
        axios.post(`https://api.kidzjoy.org/api/findEpisodeListBySeasonid/${season_id}`)
            .then(res => {
                if (res.data.msg === 'list find success') {
                    //  setSeasonButton('red')
                    console.log(res)
                    setepisodeList(res.data.result)
                }
                else if(res.data.msg==='List Empty'){
                    setepisodeList([])
                }

            })
            .catch(err => {
                // setepisodeList('Error')
            })
    }
    return (
        <Fragment>
            <div className='container-fluid   episode_list_container'>
                <div className='row'>
                    <div className='col-12'>
                        <Tabs defaultActiveKey="0" onChange={callback}>
                            
                                    {seasonlist.map((item, index) => {
                                        return (
                                            <TabPane tab={<button onClick={() => episodelistButton(item.id, index)}>{item.season}</button>}  key={index} >

                                                {episodeList.length>0 ?
                                                <div class="main">
                                                    <ul class="cards">
                                                        {episodeList.map((item, index) => {
                                                            return (
                                                                <li class="cards_item">
                                                                    <Link
                                                                    to={{
                                                                        pathname: '/adminVideoPlayer',
                                                                        state: {
                                                                            item: item,
                                                                        }
                                                                    }}
                                                                    >
                                                                        <div class="carddiv">
                                                                            <div class="card_image"><img src={`http://admin.kidzjoy.org/${item.image_path}`} /></div>
                                                                            <div class="card_content">
                                                                                <h2 class="card_title">{item.episodes_name}</h2>
                                                                                <p class="card_text">{item.episodes}</p>
                                                                                <button class="btn card_btn">Play {item.episodes}</button>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                :
                                               
                                                <Empty
                                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                    imageStyle={{
                                                        height: 90,
                                                    }}
                                                  
                                                >
                                                </Empty>
                                            }
                                                    
                                                
                                            </TabPane>
                                        )
                                    })}
                                    <div>
                                    </div>

                        </Tabs>
                        {/* <h5>
  {episodeList.length>0 ? "item":"loading..."}
  </h5> */}

                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default EpisodeList
