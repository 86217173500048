import {FIND_LOGIN_USER} from '../constatnt';

const initState={
   loginUserData:'',
   loginuserLoder:true,

}



const LoginUserReducerReducer=(state=initState,action)=>{
    
    switch(action.type){
       
        case FIND_LOGIN_USER:
            console.log(action.data)
            console.log('login user Reducer')
            return{
                ...state,
                loginUserData:action.data,
                loginuserLoder:true,
            }
           
        default:
        return state;
    }
}

 export default LoginUserReducerReducer;