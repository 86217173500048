import {CREATE_USER_ACCOUNT} from '../constatnt';

const initState={
   signupuserData:'',
   signupuserLoder:true,

}



const SignupUserReducerReducer=(state=initState,action)=>{
    
    switch(action.type){
       
        case CREATE_USER_ACCOUNT:
            console.log(action.data)
            console.log('signup user Reducer')
            return{
                ...state,
                signupuserData:action.data,
                signupuserLoder:true,
            }
           
        default:
        return state;
    }
}

 export default SignupUserReducerReducer;