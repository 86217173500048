
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';


const Protected =({component:Cmp,...rest})=>(
    <Route 
    {...rest}
    render={(props)=>

        localStorage.getItem('user_token') ?(
            <Cmp {...props} />
        ):
        localStorage.getItem('id') ?
        <Redirect to='/demopage' /> :<Redirect to='/' />
    }
    
    
    />

)
export default Protected;