import {GET_SHORT_MOVIE_LIST,GET_SHORT_MOVIE_DETAIL_LIST} from '../constatnt';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../config';


export const GetShortMovieListSubCategory =(data)=>async(dispatch,getState)=>{
    axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/shortfilmsListwithDetail`)
    .then(res=>{
        console.log(res)
        if(res.data.msg==='list find success'){
            console.log(res)
            dispatch( {
                type:GET_SHORT_MOVIE_LIST,
                data:res.data.subcategorylist
            })
        }
        if(res.data.msg==="list empty"){
            dispatch( {
                type:GET_SHORT_MOVIE_LIST,
                data:'Empty List'
            })
        }
     if(res.data.msg==='Error' || !res.data.msg){
            dispatch( {
                type:GET_SHORT_MOVIE_LIST,
                data:"Error"
            })
        }
       
 
     })
} 


export const GetShortmovieListByCategory=(data)=>async(dispatch,getState)=>{
    axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/shortfilmsListwithDetail`)
    .then(res=>{
        console.log(res)
        if(res.data.msg==='list find success'){
            console.log(res)
            // window.location.reload();
            dispatch( {
                type:GET_SHORT_MOVIE_DETAIL_LIST,
                data:res.data.result
            })

        }
        if(res.data.msg==="list empty"){
            dispatch( {
                type:GET_SHORT_MOVIE_DETAIL_LIST,
                data:'Empty List'
            })
        }
     if(res.data.msg==='something error' || !res.data.msg){
            dispatch( {
                type:GET_SHORT_MOVIE_DETAIL_LIST,
                data:"Error"
            })
        }
       
 
     })
} 

