import React, { Fragment,useState } from "react";
import LoginFormValidation from "../../Validation/LoginFormValidation";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Space, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import FOrgetPassword from './ForgetPassword'

import "./Login.scss";
const { Title } = Typography;

export default function Login(props) {
  const { onSubmit, register, handleSubmit, errors, formui, loginerror } =
    LoginFormValidation(props.FindLoginUserHandler, props.loginUserData);
    const [forgetPasswordUI,setForgetPasswordUI]=useState(false)
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  const forgetPasswordButton=()=>{
    setForgetPasswordUI(true)
  }
  return (
    <Fragment>
      {forgetPasswordUI===false ?
      <Form labelCol={{ span: 24 }} span={24} labelWrap={{span:24}}
        initialValues={{
          email: `${props.UserEmail}`,
        }}
        className='signup_form_div' layout={`vertical`} name="complex-form" onFinish={onSubmit} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item >
          <div className="form-floating mb-3">
            <Title className='text-white' level={3}>Sign In</Title>
          </div>
        </Form.Item>
        <Form.Item span={24} label="Email or Mobile Number">
            <Form.Item
              name="email"
              noStyle
              setFieldsValue={props.UserEmail}
              rules={[{ required: true, message: 'Email or Mobile Number is required' }]}
            >
              <Input placeholder="Email or Mobile Number" defaultValue={props.UserEmail.toString()} />
            </Form.Item>
          {/* </Space> */}
        </Form.Item>
        <Form.Item label="Password">
          <Space>
            <Form.Item
              name="password"
              noStyle
              rules={[{ required: true, message: 'Password is required' }]}
            >
              <Input.Password  placeholder="Enter your password" />
            </Form.Item>

          </Space>
        </Form.Item>
        <Form.Item label=" " colon={false} style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit">
            Sign In
          </Button><br></br>
          <div className='forget_password'>
          <a className="login-form-forgot" onClick={forgetPasswordButton}>
            Forgotten Password?
          </a>
          </div>

        </Form.Item>
      </Form>
      :
      <FOrgetPassword setForgetPasswordUI={setForgetPasswordUI} />
      }
    </Fragment>
  );
}
