import {GET_MOVIE_LIST,GET_MOVIE_DETAIL_LIST} from '../constatnt';

const initState={
   getmovieListSubCategory:'',
   getmovieListSubCategoryLoder:false,
   getMovieDetailList:false,
   getMovieDetailListLoder:false


}



const MovieTabReducer=(state=initState,action)=>{
    
    switch(action.type){
       
        case GET_MOVIE_LIST:
            console.log(action.data)
            console.log(' movie tab CategoryUploadvideoList Reducer')
            return{
                ...state,
                getmovieListSubCategory:action.data,
                getmovieListSubCategoryLoder:true,
            }
            case GET_MOVIE_DETAIL_LIST:
                // console.log(action.data)
                // console.log('movie list Reducer')
                return{
                    ...state,
                    getMovieDetailList:action.data,
                    getMovieDetailListLoder:true,
                }
              
        default:
        return state;
    }
}

 export default MovieTabReducer;