import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetmovieContentType } from '../../../store/action/TalentHuntVideoList';

const MovieDetailCompoenent = (props) => {
    console.log(props)
    useEffect(() => {
        // const data = {
        //     video_id: props.item.id,
        //     subcategory_name: props.item.subcategory_name
        // }
        //  props.GetmovieContentTypeHandler(data)detaildiv
    }, [])
    return (
        <Fragment>
            
            <div className='moviedetailhover container-fluid' style={{}}>
                <div className='row'>
                    
                    <div className='col-6 pl-4 pt-3'>
                        <h6 style={{ fontWeight: '500', lineHeight: 1.2, color: '#fff', fontSize: '1.5em' }}>Movie</h6>

                        <div class="hover-buttons">
                            <Link
                                to={{
                                    pathname: '/adminVideoPlayer',
                                    state:props.data
                                  }}
                            >
                                <span className="btn btn-hover p-1" style={{ background: '#007bff', color: '#fff', padding: '', borderRadius: '0px' }}>
                                    <i className="fa fa-play mr-1" aria-hidden="true"></i>
                                    Play Now
                                   </span>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
                {/* <div className='detaildiv  container-fluid movidetailcrousel' style={props.moviedetailstyle}>
                    <div className='row'>
                        <div className='col-6 pl-4'>
                            <h6 style={{ fontWeight: '500', lineHeight: 1.2, color: '#fff', fontSize: '1.5em' }}>Movie</h6>
                           
                            <div class="hover-buttons">
                                <Link
                                    to={{
                                        pathname: '/videoplayer',
                                       
                                    }}
                                >
                                    <span class="btn btn-hover" style={{ background: '#007bff', color: '#fff', padding: '2px 10px', borderRadius: '0px' }}>
                                        <i class="fa fa-play mr-1" aria-hidden="true"></i>
                                        Play Now
                                   </span>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div> */}
                {/* :""} */}
        </Fragment>
    )
}
const mapStateToProps = state => ({
    getmovieContentType: state.UploadVideoListByCategoryReducer.getmovieContentType,
    getmovieContentTypeLoder: state.UploadVideoListByCategoryReducer.getmovieContentTypeLoder,
})

const mapDispatchToProps = dispatch => ({
    GetmovieContentTypeHandler: data => dispatch(GetmovieContentType(data)),

})
export default connect(mapStateToProps, mapDispatchToProps)(MovieDetailCompoenent)
