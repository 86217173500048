import React, { Fragment, useEffect, useState } from 'react';
import AdvertiseServices from '../../../Services/AdvertisementService/Adservices';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../../store/config';
import Fluidplayers from '../fluidplayer';
import "fluid-player/src/css/fluidplayer.css";
import '../adminVideoPlayer.scss';

function Vplayer(props) {
    const { song_path, detail } = props
    console.log(props.detail)
    const [self, setSelf] = useState(React.createRef())
    const [startPLayer, setStartPlayer] = useState(false);
    const [add, setadd] = useState(false);

    const trigger = async () => {
        const apidata = {
            video_id: detail.id,
            category_id: '6',
            sub_category_id: detail.subcategory_id
        }
        const fetchdata = await AdvertiseServices.getVideoadlist(apidata)
        console.log(fetchdata)
        if (fetchdata.state === false) {
            // fetchCategoryvideolist()
            //  console.log(fetchdata)
            if (fetchdata.response.data.success === true) {
                console.log(fetchdata)
                const relt = fetchdata.response.data.result
                // setAtbegan({id:relt.at_begin.type ? relt.at_begin.id:'', type: relt.at_begin.type, path: relt.at_begin.file_path })
                // setAtpause({id:relt.at_pause.type ? relt.at_pause.id:'', type: relt.at_pause.type ? relt.at_pause.type : "", path: relt.at_pause.file_path ? relt.at_pause.file_path : "" })
                // setattime({id:relt.at_particlar.type ? relt.at_particlar.id:'', type: relt.at_particlar.type ? relt.at_particlar.type : "", path: relt.at_particlar.file_path ? relt.at_particlar.file_path : "" })
                // console.log(relt)
                // console.log(relt.at_begin.file_path)
                var ad = []
                relt.at_begin.map((item, index) => {
                    if (item) {
                        ad.push(
                            {
                                adid: item.id,
                                filepath: `http://admin.kidzjoy.org${item.file_path}`,
                                roll: 'preRoll',
                                // vastTag:`${xmlfile}`,
                                vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                // adText: 'Advertising supports us directly'
                            }
                        )
                    }
                    else {

                    }

                })

                relt.at_particlar.slice(0).reverse().map((item, index) => {
                    if (item) {
                        ad.push(
                            {
                                adid: item.id,
                                filepath: `http://admin.kidzjoy.org${item.file_path}`,
                                roll: 'midRoll',
                                //  vastTag:`${xmlfile}`,
                                vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                timer: item.at_particular_time
                            }
                        )
                    }

                })


                relt.at_pause.map((item, index) => {
                    if (item) {
                        ad.push(

                            {
                                adid: item.id,
                                filepath: `http://admin.kidzjoy.org${item.file_path}`,
                                "roll": "onPauseRoll",
                                //  vastTag:`${addfile}`,
                                vastTag: `${LOCAL_SERVER}/${PANCHANG_SERVER}/getxmlfile/${item.id}`,
                                "adText": "",
                            }
                        )
                    }

                })

                console.log(ad)
                ad.push({
                    roll: "preRoll",
                    vastTag: "http://localhost:8080/api/getxmlfile/thsyu"
                })
                setadd(ad)
                setStartPlayer(true)
            }
        }
    }
    useEffect(() => {
        trigger()
    }, [])
    useEffect(() => {
        trigger()
    }, [props.videoidchange])
    return (
        <Fragment>
            {startPLayer === true ?
                <Fluidplayers detail={detail} name='admin' image={detail.image_path} add={add} item={song_path} />
                // <video id='my-videos' ref={self} width='100%' height='100%' controls>
                //     <source src={`http://admin.kidzjoy.org${item}`}

                //         type='video/mp4' />
                // </video>
                : ""}
        </Fragment>
    )
}

export default Vplayer
