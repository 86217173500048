import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../../store/config';
import {ProgressBar} from 'react-bootstrap';



export default function UploadVideoValidationForm(updatedata) {
    const { register, handleSubmit, formState: { errors } } = useForm({
        // defaultValues:{title:updatedata.title,category:updatedata.category,
        //     subcategory:updatedata.subcategory,desc:updatedata.desc
        // }
    });
    const [statusupload, setstatusupload] = useState(false)
    const [subcatList, setsubcatList] = useState(null)
    const [contentTypelist, setContentTypelist] = useState(
        [
            // { value: 'sadMusic', label: 'Sad Music' },
            // { value: 'math', label: 'Math' },
            // { value: 'englishsong', label: 'English Song' }
        ]
    )
    const [show, setShow] = useState(true);

    const [uploadsuccessLoder, setUploadsuccessLoder] = useState(false)
    const [category_id, setcategory_id] = useState('')
    const [categoryList, setCategoryList] = useState(null)
    const [contentType, setContentType] = useState(null);
    const [successuploadmsg, setsuccessuploadmsg] = useState('');
    const [updateVideoFormUi, setUpdateVideoFormUi] = useState(false)
    const [uploadmovieImage,setuploadmovieImage] = useState();
    const [uploadmovieVideo,setuploadmovieVideo] = useState();
    const contenttypeHandlechange = (selectoption) => {
        console.log(selectoption)
        setContentType(selectoption)
    }
    const[loadloder,setloadloder]=useState(false)
    const [uploadPercentage,setUploadPercentage]=useState(0)
    const options = {
        onUploadProgress: (progressEvent) => {
          const {loaded, total} = progressEvent;
          let percent = Math.floor( (loaded * 100) / total )
          console.log( `${loaded}kb of ${total}kb | ${percent}%` );
  
          if( percent < 100 ){
            setUploadPercentage(percent)
          }
        }
      }
    const uploadVideoButton = async(data, e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
       setloadloder(true)
        console.log(data)
        console.log(contentType)
        const user_id = localStorage.getItem('loginid')
        const contenttypevalue = []
        if (contentType.length > 0) {
            contentType.forEach((item, index) => {
                contenttypevalue[index] = `${item.value}`
            })
        }
        console.log(contenttypevalue)
        var formdata = new FormData()
        formdata.append('title', data.title);
        formdata.append('filename', data.filename[0]);
        formdata.append('category', data.category);
        formdata.append('subcategory', data.subcategory);
        formdata.append('videofile', data.videofile[0]);
        formdata.append('desc', data.desc);
        formdata.append('user_id', user_id);
        formdata.append('contentType', contenttypevalue);
        console.log(contenttypevalue)
        // console.log(user_id)
          const   headers= {
                'accept': 'application/json',
                 'content-type': 'multipart/form-data',
          }
        
       await axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/uploadvideofile`,formdata,options)
            .then(res => {
                if (res.data.msg === "Video Upload successfully!") {
                     
                    setTimeout(() => {
                         setUploadPercentage(0)
                         setloadloder(false)
                        setsuccessuploadmsg('Video Uploaded Successfully')

                    }, 1000)

                }
                else{
                    console.log(res)
                    console.log('error')
                }
            })
            .catch(err => {
                console.log(err)
            })

    }
    const findcategory = () => {
        console.log('category')
        axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/uploadvideocategory`)
            .then(res => {
                console.log(res)
                setCategoryList(res.data.result)
                setstatusupload(true)
            })
            .catch(err=>{
                console.log(err)
            })
    }
    const imageHandler=(e)=>{
        const reader = new FileReader()
        reader.onload=()=>{
            if(reader.readyState===2){
                console.log(reader.result)

                setuploadmovieImage(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }
    const videoHandler=(e)=>{
        const reader = new FileReader()
        reader.onload=()=>{
            if(reader.readyState===2){
                console.log(reader.result)
                setuploadmovieVideo(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])    }
    const handleChange = (e) => {
        console.log(e.target.value)
        var form=new FormData()
        // if(e.target.name==='filename'){
        //     form.append('filename', e.target.value);

        // }
        // console.log(form)

        if (e.target.name === 'category') {
            setcategory_id(e.target.value)
            const data = {
                category_id: e.target.value
            }
            axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/getsubCategoryListbbycatid/${data.category_id}`)
                .then(res => {
                     console.log(res)
                    if (res.data.result.length > 0) {
                        setsubcatList(res.data.result)
                    }
                })
        }
        if (e.target.name === 'subcategory') {
            const subcatdata = {
                subcategory_id: e.target.value
            }
            axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/videosubcategorycontenttype`, subcatdata)
                .then(res => {
                    console.log(res)
                    if (res.data.result.length > 0) {
                        setContentTypelist(
                            res.data.result.map((item, index) => {
                                return (
                                    { value: item.conttype_id, label: item.name }
                                    // { value: 'math', label: 'Math' },
                                    // { value: 'englishsong', label: 'English Song' }
                                )
                            })

                        )
                    }

                })
        }

    }
    return {
        uploadVideoButton, register, handleSubmit, errors, contenttypeHandlechange, contentType,
        successuploadmsg, findcategory, categoryList, statusupload, handleChange, subcatList, contentTypelist,
        uploadsuccessLoder,loadloder,uploadPercentage,show,imageHandler,uploadmovieImage,uploadmovieVideo,videoHandler
    }
}
