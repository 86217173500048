import {GET_SERIES_LIST,GET_SERIES_DETAIL_LIST,GET_SHOW_DETAIL_BY_SHOWID} from '../constatnt';
import axios from 'axios';
import { LOCAL_SERVER, PANCHANG_SERVER } from '../config';


export const GetSeriesListSubCategory =(data)=>async(dispatch,getState)=>{
    axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/showList`)
    .then(res=>{
        console.log(res)
        if(res.data.msg==='list find success'){
            console.log(res)
            // window.location.reload();
            dispatch( {
                type:GET_SERIES_LIST,
                data:res.data.result
            })
        }
        if(res.data.msg==="list empty"){
            dispatch( {
                type:GET_SERIES_LIST,
                data:'Empty List'
            })
        }
     if(res.data.msg==='Error' || !res.data.msg){
            dispatch( {
                type:GET_SERIES_LIST,
                data:"Error"
            })
        }
       
 
     })
} 


export const GetSeriesListByCategory=(data)=>async(dispatch,getState)=>{
    axios.get(`${LOCAL_SERVER}/${PANCHANG_SERVER}/showListwithDetail`)
    .then(res=>{
        console.log(res)
        if(res.data.msg==='show List Find' ||  res.data.msg==='show List Find & episode list empty' || res.data.msg==='show List Find and seasonList empty'){
            console.log(res)
            // window.location.reload();
            dispatch( {
                type:GET_SERIES_DETAIL_LIST,
                data:res.data.result
            })
        }
        if(res.data.msg==="list empty"){
            dispatch( {
                type:GET_SERIES_DETAIL_LIST,
                data:'Empty List'
            })
        }
     if(res.data.msg==='something error' || !res.data.msg){
            dispatch( {
                type:GET_SERIES_DETAIL_LIST,
                data:"Error"
            })
        }
     })
} 

// get show detail by show id
export const GetShowDetailByShowid=(data)=>async(dispatch,getState)=>{
    axios.post(`${LOCAL_SERVER}/${PANCHANG_SERVER}/findshowdetailbyshowid/${data}`)
    .then(res=>{
        console.log(res)
        if(res.data.msg==='list find success'){
            console.log(res)
            // window.location.reload();
            dispatch( {
                type:GET_SHOW_DETAIL_BY_SHOWID,
                data:res.data.result
            })
        }
        if(res.data.msg==="something Error"){
            dispatch( {
                type:GET_SHOW_DETAIL_BY_SHOWID,
                data:'Error'
            })
        }
    
     })
} 

